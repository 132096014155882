<template>
  <div>
    <app-bar/>
    <request-toolbar />
    <v-container fluid>
      <v-row v-show="requestExists">
        <v-col cols="12">
          <request :request="request"/>
        </v-col>
      </v-row>
      <div v-show="loadingFlag" class="text-center themeText2--text">
        <v-progress-circular size="100" width="10" indeterminate color="themeText2"/>
        <div class="text-h6">Loading request...</div>
      </div>
      <div v-show="!errorMessage && !loadingFlag && !requestExists" class="text-center themeText2--text">
        <v-icon size="128" color="themeText2">mdi-emoticon-confused-outline</v-icon>
        <div class="text-h6">Whoops ! We couldn't find anything</div>
        <div>Double check your change request id or try something else.</div>
      </div>
      <div v-show="errorMessage" class="text-center themeText2--text">
        <v-icon size="128" color="themeText2">mdi-emoticon-dead-outline</v-icon>
        <div class="text-h6">Whoops ! We have a problem...</div>
        <div>{{errorMessage}}</div>
      </div>
      <div class="pt-4 pb-8">
        <app-footer />
      </div>
    </v-container>
  </div>
</template>

<script>
import AppBar from '@/components/app/AppBar.vue'
import AppFooter from '@/components/app/AppFooter.vue'
import RequestToolbar from '@/components/request/RequestToolbar.vue'
import Request from '@/components/request/Request.vue'
import {mapActions, mapGetters} from "vuex"
import {ENV_NS} from "@/store/modules/env/constants"
import {MERCHANT_NS} from "@/store/modules/merchant/constants"
import {REQUEST_NS} from "@/store/modules/request/constants"

export default {
  name: 'RequestPage',
  components: {
    AppBar,
    AppFooter,
    Request,
    RequestToolbar
  },
  created() {
    const env = this.$route.params.env
    const merchantId = this.$route.params.merchantId
    const requestId = this.$route.params.requestId
    let params = {}
    params.env = env

    if ( !this.env && this.env != env ) {
      this.selectEnv(env)
    }

    if ( !this.merchant.id && this.merchant.id != merchantId ) {
      this.fetchMerchants({params})
      this.selectMerchantById(merchantId)
    }

    if ( !this.request.id && this.request.id != requestId ) {
      this.fetchRequestById({merchantId, requestId})
    }
  },
  computed: {
    ...mapGetters(ENV_NS, [
      'env'
    ]),
    ...mapGetters(MERCHANT_NS, [
      'merchant'
    ]),
    ...mapGetters(REQUEST_NS, [
      'request',
      'errorMessage',
      'loadingFlag',
    ]),
    requestExists() {
      let result = false
      if ( this.request && this.request.id ) {
        result = true
      }
      return result
    }
  },
  methods: {
    ...mapActions(ENV_NS, ['selectEnv']),
    ...mapActions(MERCHANT_NS, ['fetchMerchants','selectMerchantById']),
    ...mapActions(REQUEST_NS, [
      'fetchRequestById'
    ]),
  }
}
</script>
