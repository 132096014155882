<template>
  <div>
    <app-bar/>
    <orders-toolbar />
    <v-container fluid>
      <v-row class="text-center">
        <v-col cols="12">
          <orders-list />
        </v-col>
      </v-row>
      <div class="pt-2 pb-8">
        <app-footer />
      </div>
    </v-container>
  </div>
</template>

<script>
import AppBar from '@/components/app/AppBar.vue'
import AppFooter from '@/components/app/AppFooter.vue'
import OrdersToolbar from '@/components/orders/OrdersToolbar.vue'
import OrdersList from '@/components/orders/OrdersList.vue'

import {mapGetters,mapActions} from "vuex"
import {MERCHANT_NS} from "@/store/modules/merchant/constants"
import {ORDER_NS} from "@/store/modules/order/constants"

export default {
  name: 'OrdersPage',
  components: {
    AppBar,
    AppFooter,
    OrdersToolbar,
    OrdersList
  },
  created() {
    const merchantId = this.$route.params.merchantId
    let query = this.$route.query
    let params = {}
    params.env = this.$route.params.env

    if ( !this.merchant.id && this.merchant.id != merchantId ) {
      this.fetchMerchants({params})
      this.selectMerchantById(merchantId)
    }

    params = {}
    for (var queryParam in query) {
      if (query.hasOwnProperty(queryParam)) {
        params[queryParam] = query[queryParam]
      }
    }

    this.updateSearchQueryParams(params)
    this.updateOrdersURL({'vue':this})
    this.fetchOrders({merchantId, 'params':this.searchQueryParams})
  },
  computed: {
    ...mapGetters(MERCHANT_NS, [
      'merchant'
    ]),
    ...mapGetters(ORDER_NS, [
      'searchQueryParams'
    ]),
  },
  methods: {
    ...mapActions(MERCHANT_NS, ['fetchMerchants','selectMerchantById']),
    ...mapActions(ORDER_NS, ['fetchOrders','updateSearchQueryParams','updateOrdersURL']),
  }
}
</script>
