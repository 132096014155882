<template>
  <div v-show="hasDocuments">
    <div :class="documentsHeaderStyle">
      <v-icon color="themeText3">mdi-file-document-outline</v-icon>
      <span class="themeText3--text"> Documents</span>
    </div>
    <v-card :color="documentsContentColor" :class="documentsContentStyle">
      <order-document v-for="(doc,index) in documents" :key="index" :document="doc" />
    </v-card>
  </div>
</template>
<script>
import OrderDocument from '@/components/order/OrderDocument.vue'

export default {
  name: "OrderDocuments",
  props: {
    documents: Array,
    level: String,
  },
  components: {
    OrderDocument,
  },
  computed: {
    hasDocuments() {
      return (this.documents && this.documents.length > 0) ? true : false
    },
    isOrderLevel() {
      return (this.level == 'ORDER')
    },
    documentsHeaderStyle() {
      let style = "text-body-2 mb-3 font-weight-bold mt-4"

      if ( this.isOrderLevel ) {
        style = "text-h6 py-3 mt-4"
      }

      return style
    },
    documentsContentColor() {
      return this.isOrderLevel ? 'themeCard' : 'transparent'
    },
    documentsContentStyle() {
      let style = "elevation-0"

      if ( this.isOrderLevel ) {
        style = "pa-4 pb-1"
      }

      return style
    },
  },
}
</script>
<style scoped>
</style>
