import {ENV} from "@/store/modules/env/constants";
import {envs} from "@/helpers/env";
import {switchEnv} from "@/helpers/parameters";

const state = {
  env : localStorage.getItem(ENV),
  // env : '',
  envs : envs
}

const getters = {
  env(state) {
    return state.env
  },
  envs(state) {
    return state.envs
  }
}

const mutations = {
  updateEnv(state, env) {
    state.env = env
    localStorage.setItem(ENV,env)
  }
}

const actions = {
  selectEnv({commit,state}, env) {
    /*
    if (state.env !== env) {
      switchEnv(env)
    }
    */
    switchEnv(env)
    commit('updateEnv', env)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
