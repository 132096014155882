// Module for request view

import state from "./state";
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutation";

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
