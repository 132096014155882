<template>
  <v-chip small label :color="typeColor" text-color="themeText2">
    <v-icon left small color="themeText2">{{typeIcon}}</v-icon>
    <span class="font-weight-bold">{{typeCode}}</span>
  </v-chip>
</template>
<script>
export default {
  name: "RequestRequesterType",
  props: {
    code: String,
  },
  computed: {
    typeCode() {
      let typeCode = this.code
      if (typeCode) {
        typeCode = typeCode.split('_').join(' ')
      }
      return typeCode
    },
    typeColor() {
      let typeCode = this.code
      let typeColor = ""

      switch (typeCode) {
        case "CUSTOMER":
        case "FULFILLER":
        case "MERCHANT":
        case "ORDER_MANAGER":
        typeColor = "themeChip4"
        break
        default:
        typeColor = "themeChip4"
        break
      }

      return typeColor
    },
    typeIcon() {
      let typeCode = this.code
      let typeIcon = ""

      switch (typeCode) {
        case "CUSTOMER":
          typeIcon = "mdi-account"
        break
        
        case "FULFILLER":
          typeIcon = "mdi-arrow-right-bold-outline"
        break
        
        case "MERCHANT":
          typeIcon = "mdi-shopping-outline"
        break
        
        case "ORDER_MANAGER":
          typeIcon = "mdi-cog-outline"
        break
        
        default:
          typeIcon = "mdi-help-circle-outline"
        break
      }

      return typeIcon
    }
  },
}
</script>
<style lang="scss" scoped>
</style>
