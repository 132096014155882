<template>
  <div class="my-2">
    <v-row no-gutters class="caption themeText1--text">
      <v-col cols="8" class="text-truncate">
        <v-icon :color="itemTypeColor" class="mr-2">{{ itemTypeIcon }}</v-icon>
        <copyable :text="itemReference" />
      </v-col>
      <v-col cols="4" align="right">
        <order-line-item-condition :code="itemConditionCode" />
      </v-col>
    </v-row>
    <v-row no-gutters class="themeText1--text">
      <v-col cols="6">
        <div class="text-truncate">{{ itemName }}</div>
      </v-col>
      <v-col cols="3">
        <div class="text-center">{{ itemPrice }} x {{ itemQuantity }}</div>
      </v-col>
      <v-col cols="3">
        <div :class="itemAmountBeforeAdjustmentsClass">{{ itemAmountBeforeAdjustments }} {{ currencyCode }}</div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="9">
        <div class="caption themeText2--text">SKU :
          <copyable :text="itemSku" />
        </div>
        <div class="caption themeText2--text">
          <span v-show="itemSellerTypeCode">{{ itemSellerTypeCode }} </span>
          <span>Seller :
            <copyable :text="itemSellerReference" />
          </span>
          <span> (Ref.
            <copyable :text="itemSellerItemReference" />)
          </span>
        </div>
        <div v-show="itemCustomizationReference">
          <span class="caption themeText2--text">Customization :
            <copyable :text="itemCustomizationReference" />
          </span>
        </div>
      </v-col>
      <v-col cols="3" class="themeText1--text">
        <div v-show="hasAdjustments" class="text-right">{{ itemAmount }} {{ currencyCode }}</div>
      </v-col>
    </v-row>

    <order-adjustments :adjustments="item.adjustments" :currencyCode="currencyCode" />

    <v-sheet v-show="cancellation" color="themePanelHeader" rounded class="pa-2">
      <div class="caption themeText2--text text-truncate">
        <span>Delivery </span>
        <span>(
          <copyable :text="item.delivery_reference" /> )
        </span>
      </div>
      <div class="caption themeText2--text text-truncate">
        <span>Reason : </span>
        <span class="themeText1--text">{{ item.reason_code }}</span>
      </div>
      <div class="caption themeText2--text text-truncate">
        <span>Message : </span>
        <span class="themeText1--text">{{ item.message }}</span>
      </div>
      <div v-show="item.compensation_type_code" class="caption themeText2--text text-truncate">
        <span>Compensation type : </span>
        <span class="themeText1--text">{{ item.compensation_type_code }}</span>
      </div>
      <div v-show="item.linked_order_reference" class="caption themeText2--text text-truncate">
        <span>Linked order : </span>
        <copyable :text="item.linked_order_reference" />
      </div>
    </v-sheet>
  </div>
</template>

<script>
import { getCurrencyDecimal } from "@/helpers/currency"
import Copyable from '@/components/common/Copyable.vue'
import OrderAdjustments from '@/components/order/OrderAdjustments.vue'
import OrderLineItemCondition from '@/components/order/OrderLineItemCondition.vue'

export default {
  name: "OrderLineItem",
  props: {
    item: Object,
    cancellation: Boolean,
    currencyCode: String,
  },
  components: {
    Copyable,
    OrderAdjustments,
    OrderLineItemCondition,
  },
  computed: {
    itemTypeIcon() {
      let itemTypeCode = this.item.type_code
      let icon = ""

      switch (itemTypeCode) {
        case "PRODUCT":
          icon = "mdi-package-variant-closed"
          break
        case "SHIPPING_FEES":
          icon = "mdi-truck-fast"
          break
        default:
          icon = "mdi-help-circle-outline"
          break
      }

      return icon
    },
    itemTypeColor() {
      return (this.cancellation) ? 'themeText2' : 'primary'
    },
    itemReference() {
      return this.item ? this.item.reference : ""
    },
    itemQuantity() {
      return this.item ? this.item.quantity : 0
    },
    itemName() {
      return this.item ? this.item.name : ""
    },
    itemSku() {
      return this.item ? this.item.sku : ""
    },
    itemCustomizationReference() {
      return this.item ? this.item.customization_reference : ""
    },
    itemPrice() {
      const currencyCode = this.currencyCode
      const decimal = getCurrencyDecimal(currencyCode)
      return this.item && this.item.price >= 0 ? this.item.price.toFixed(decimal) : (0).toFixed(decimal)
    },
    itemAmount() {
      const currencyCode = this.currencyCode
      const decimal = getCurrencyDecimal(currencyCode)
      return this.item && this.item.amount >= 0 ? this.item.amount.toFixed(decimal) : (0).toFixed(decimal)
    },
    itemTotalAdjustedAmount() {
      const currencyCode = this.currencyCode
      const decimal = getCurrencyDecimal(currencyCode)
      return (this.item && this.item.total_adjusted_amount >= 0) ? this.item.total_adjusted_amount.toFixed(decimal) : (0).toFixed(decimal)
    },
    hasAdjustments() {
      return (this.item && this.item.adjustments && this.item.adjustments.length > 0)
    },
    itemAmountBeforeAdjustments() {
      const currencyCode = this.currencyCode
      const decimal = getCurrencyDecimal(currencyCode)
      let amount = Number(this.itemAmount) + Number(this.itemTotalAdjustedAmount)
      return (amount >= 0 ? amount.toFixed(decimal) : (0).toFixed(decimal))
    },
    itemAmountBeforeAdjustmentsClass() {
      let amountClass = 'text-right'
      if (this.hasAdjustments > 0) {
        amountClass += ' text-decoration-line-through themeText2--text'
      }
      return amountClass
    },
    itemSellerTypeCode() {
      return this.item && this.item.seller ? this.item.seller.type_code : ""
    },
    itemSellerReference() {
      return this.item && this.item.seller ? this.item.seller.reference : ""
    },
    itemSellerItemReference() {
      return this.item && this.item.seller ? this.item.seller.item_reference : ""
    },
    itemConditionCode() {
      return this.item && this.item.condition_code ? this.item.condition_code : ""
    },
  }
}
</script>

<style lang="scss" scoped></style>
