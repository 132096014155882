
const mutations = {
  updateOrders(state, orders) {
    state.orders = orders
  },
  addOrders(state, orders) {
    state.orders.push(...orders)
  },
  updateTotalNumber(state, totalNumber) {
    state.totalNumber = totalNumber
  },
  updateShowingNumber(state, showingNumber) {
    state.showingNumber = showingNumber
  },
  increaseShowingNumber(state, number) {
    state.showingNumber += number
  },
  updateOrder(state, order) {
    state.order = order
  },
  updateSearchQueryParams(state, queryParams) {
    let searchQueryParams = {}

    if ( queryParams ) {
      if ( queryParams['id'] ) {
        searchQueryParams['id'] = queryParams['id']
      }

      if ( queryParams['reference'] ) {
        searchQueryParams['reference'] = queryParams['reference']
      }

      if ( queryParams['status_code'] ) {
        searchQueryParams['status_code'] = queryParams['status_code']
      }

      if ( queryParams['creation_date_time_start'] ) {
        searchQueryParams['creation_date_time_start'] = queryParams['creation_date_time_start']
      }

      if ( queryParams['creation_date_time_end'] ) {
        searchQueryParams['creation_date_time_end'] = queryParams['creation_date_time_end']
      }

      if ( queryParams['update_date_time_start'] ) {
        searchQueryParams['update_date_time_start'] = queryParams['update_date_time_start']
      }

      if ( queryParams['update_date_time_end'] ) {
        searchQueryParams['update_date_time_end'] = queryParams['update_date_time_end']
      }

      if ( queryParams['customer_reference'] ) {
        searchQueryParams['customer_reference'] = queryParams['customer_reference']
      }
    }

    state.searchQueryParams = searchQueryParams
  },
  updateSearchQueryParamsUrl(state) {
    state.searchQueryParamsURL = Object.keys(state.searchQueryParams).map(key => {
      return (
        encodeURIComponent(key) + '=' + encodeURIComponent(state.searchQueryParams[key])
      )
    }).join('&')
  },
  addSearchQueryParam(state, {key,value}) {
    if ( key && value ) {
      switch (key) {
        case 'id':
        case 'reference':
        case 'status_code':
        case 'creation_date_time_start':
        case 'creation_date_time_end':
        case 'update_date_time_start':
        case 'update_date_time_end':
        case 'customer_reference':
        state.searchQueryParams[key] = value
        break;
        default:
        break;
      }
    }
  },
  removeSearchQueryParam(state, key){
    if ( key && state.searchQueryParams.hasOwnProperty(key)) {
      delete state.searchQueryParams[key]
    }
  },
  updateErrorMessage(state,message) {
    state.errorMessage = message
  },
  toggleLoadingFlag(state) {
    state.loadingFlag = !state.loadingFlag
  },
};

export default mutations;
