<template>
  <v-chip small label :color="statusColor" text-color="white">
    <v-icon left small>{{ statusIcon }}</v-icon>
    <span class="font-weight-bold">{{ statusCode }}</span>
  </v-chip>
</template>
<script>
export default {
  name: "OrderDeliveryStatus",
  props: {
    code: String,
  },
  computed: {
    statusCode() {
      let statusCode = this.code;
      if (statusCode) {
        statusCode = statusCode.split("_").join(" ");
      }
      return statusCode;
    },
    statusColor() {
      let statusCode = this.code;
      let statusColor = "";

      switch (statusCode) {
        case "CREATED":
        case "VALIDATED":
        case "CANCELLED":
        case "IN_PREPARATION":
        case "IN_TRANSIT":
        case "IN_DELIVERY":
        case "TO_COLLECT":
        case "FULFILLED":
        case "SPLIT":
          statusColor = "themeStatusDelivery";
          break;
        default:
          statusColor = "";
          break;
      }

      return statusColor;
    },
    statusIcon() {
      let statusCode = this.code;
      let statusIcon = "";

      switch (statusCode) {
        case "CREATED":
          statusIcon = "mdi-checkbox-blank-circle-outline";
          break;
        case "VALIDATED":
          statusIcon = "mdi-check-circle-outline";
          break;
        case "CANCELLED":
          statusIcon = "mdi-cancel";
          break;
        case "IN_PREPARATION":
          statusIcon = "mdi-circle-slice-3";
          break;
        case "IN_TRANSIT":
        case "IN_DELIVERY":
          statusIcon = "mdi-arrow-right-circle";
          break;
        case "TO_COLLECT":
          statusIcon = "mdi-arrow-down-circle";
          break;
        case "FULFILLED":
          statusIcon = "mdi-check-circle";
          break;
        case "SPLIT":
          statusIcon = "mdi-call-split";
          break;
        default:
          statusIcon = "mdi-help-circle-outline";
          break;
      }

      return statusIcon;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
