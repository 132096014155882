import { getCurrencyDecimal } from "@/helpers/currency"

let transactionsToCSV = (orders) => {
    let content = ""

    content = content + "Merchant Id,"
    content = content + "Order Id,"
    content = content + "Order Reference,"
    content = content + "Order Creation Date Time,"
    content = content + "Order Update Date Time,"
    content = content + "Order Status,"
    content = content + "Currency,"
    content = content + "Payment Reference,"
    content = content + "Payment Method Reference,"
    content = content + "Payment Capture Type,"
    content = content + "Transaction Reference,"
    content = content + "Transaction Status,"
    content = content + "Transaction Amount,"
    content = content + "Transaction Creation Date Time,"
    content = content + "Transaction Update Date Time\n"

    for (const order of orders) {
        const currencyCode = order.currency_code
        const decimal = getCurrencyDecimal(currencyCode)
        const merchantId = order.source ? order.source.merchant_id : ""
        const orderId = order.id
        const orderReference = order.reference
        const orderCreationDateTime = order.creation_date_time
        const orderUpdateDateTime = order.update_date_time
        const orderStatus = order.status_code

        for (const payment of order.payments) {
            const paymentReference = payment.reference
            const paymentMethodReference = payment.method_reference
            const paymentCaptureType = payment.capture ? payment.capture.type_code : ""

            for (const transaction of payment.transactions) {
                const transactionReference = transaction.reference
                const transactionStatus = transaction.status_code
                const transactionAmount = transaction.amount
                const transactionCreationDateTime = transaction.creation_date_time
                const transactionUpdateDateTime = transaction.update_date_time

                content = content + merchantId + ","
                content = content + orderId + ","
                content = content + orderReference + ","
                content = content + orderCreationDateTime + ","
                content = content + orderUpdateDateTime + ","
                content = content + orderStatus + ","
                content = content + currencyCode + ","
                content = content + paymentReference + ","
                content = content + paymentMethodReference + ","
                content = content + paymentCaptureType + ","
                content = content + transactionReference + ","
                content = content + transactionStatus + ","
                content = content + transactionAmount.toFixed(decimal) + ","
                content = content + transactionCreationDateTime + ","
                content = content + transactionUpdateDateTime + "\n"
            }

            for (const cancellation of payment.cancellations) {
                const transactionReference = cancellation.transaction.reference
                const transactionStatus = cancellation.transaction.status_code
                const transactionAmount = cancellation.transaction.amount
                const transactionCreationDateTime = cancellation.transaction.creation_date_time
                const transactionUpdateDateTime = cancellation.transaction.update_date_time

                content = content + merchantId + ","
                content = content + orderId + ","
                content = content + orderReference + ","
                content = content + orderCreationDateTime + ","
                content = content + orderUpdateDateTime + ","
                content = content + orderStatus + ","
                content = content + currencyCode + ","
                content = content + paymentReference + ","
                content = content + paymentMethodReference + ","
                content = content + paymentCaptureType + ","
                content = content + transactionReference + ","
                content = content + transactionStatus + ","
                content = content + transactionAmount.toFixed(decimal) + ","
                content = content + transactionCreationDateTime + ","
                content = content + transactionUpdateDateTime + "\n"
            }

            for (const refund of payment.refunds) {
                const transactionReference = refund.transaction.reference
                const transactionStatus = refund.transaction.status_code
                const transactionAmount = refund.transaction.amount
                const transactionCreationDateTime = refund.transaction.creation_date_time
                const transactionUpdateDateTime = refund.transaction.update_date_time

                content = content + merchantId + ","
                content = content + orderId + ","
                content = content + orderReference + ","
                content = content + orderCreationDateTime + ","
                content = content + orderUpdateDateTime + ","
                content = content + orderStatus + ","
                content = content + currencyCode + ","
                content = content + paymentReference + ","
                content = content + paymentMethodReference + ","
                content = content + paymentCaptureType + ","
                content = content + transactionReference + ","
                content = content + transactionStatus + ","
                content = content + transactionAmount.toFixed(decimal) + ","
                content = content + transactionCreationDateTime + ","
                content = content + transactionUpdateDateTime + "\n"
            }
        }
    }

    return content
}

export {
    transactionsToCSV,
}