<template>
  <v-timeline-item
  :icon="iconFromEventTypeCode" fill-dot icon-color="white"
  :color="eventColor">
  <v-card flat class="themeCard">
    <v-card-text>
      <div class="themeText3--text font-weight-bold">{{eventTypeCode}}</div>
      <div class="caption themeText2--text">{{event.date_time}}</div>
      <div v-show="isRequestStatusUpdatedEvent" class="caption">
        <request-status :code="event.payload ? event.payload.change_request_status_code : ''" />
      </div>
      <div v-show="isCancellationStatusUpdatedEvent" class="caption">
        <request-return-status :code="event.payload ? event.payload.cancellation_status_code : ''" />
        <div class="caption text-truncate">
          <span class="themeText2--text">Fulfillment : </span>
          <copyable :text="event.payload ? event.payload.fulfillment_reference : ''"/>
        </div>
        <div class="caption text-truncate">
          <span class="themeText2--text">Delivery : </span>
          <copyable :text="event.payload ? event.payload.delivery_reference : ''"/>
        </div>
        <div class="caption text-truncate">
          <span class="themeText2--text">Item : </span>
          <copyable :text="event.payload ? event.payload.reference : ''"/>
        </div>
      </div>
      <div v-show="isReturnStatusUpdatedEvent" class="caption">
        <request-return-status :code="event.payload ? event.payload.return_status_code : ''" />
        <div class="caption text-truncate">
          <span class="themeText2--text">Fulfillment : </span>
          <copyable :text="event.payload ? event.payload.fulfillment_reference : ''"/>
        </div>
        <div class="caption text-truncate">
          <span class="themeText2--text">Delivery : </span>
          <copyable :text="event.payload ? event.payload.delivery_reference : ''"/>
        </div>
        <div class="caption text-truncate">
          <span class="themeText2--text">Item : </span>
          <copyable :text="event.payload ? event.payload.reference : ''"/>
        </div>
      </div>
      <div v-show="isRefundStatusUpdatedEvent" class="caption">
        <request-refund-status :code="event.payload ? event.payload.refund_status_code : ''" />
      </div>
    </v-card-text>
  </v-card>
  <div class="caption themeText2--text text-right">
    <span>Revision #<strong>{{event.revision}}</strong></span>
  </div>
</v-timeline-item>
</template>
<script>
import Copyable from '@/components/common/Copyable.vue'
import RequestStatus from '@/components/request/RequestStatus.vue'
import RequestReturnStatus from '@/components/request/RequestReturnStatus.vue'
import RequestRefundStatus from '@/components/request/RequestRefundStatus.vue'

export default {
  name: "RequestTimelineEvent",
  props: {
    event: Object,
  },
  components: {
    Copyable,
    RequestStatus,
    RequestReturnStatus,
    RequestRefundStatus,
  },
  computed: {
    eventTypeCode() {
      let eventTypeCode = (this.event) ? this.event.type_code : ''
      if (eventTypeCode) {
        eventTypeCode = eventTypeCode.split('_').join(' ')
      }
      return eventTypeCode
    },
    isRequestStatusUpdatedEvent() {
      return (this.event.type_code=="CHANGE_REQUEST_STATUS_UPDATED") ? true : false
    },
    isRefundStatusUpdatedEvent() {
      return (this.event.type_code=="REFUND_STATUS_UPDATED" || this.event=="MANUAL_REFUND_STATUS_UPDATED") ? true : false
    },
    isReturnStatusUpdatedEvent() {
      return (this.event.type_code=="RETURN_STATUS_UPDATED") ? true : false
    },
    isCancellationStatusUpdatedEvent() {
      return (this.event.type_code=="CANCELLATION_STATUS_UPDATED") ? true : false
    },
    iconFromEventTypeCode() {
      let icon = "mdi-calendar-clock"
      let code = (this.event) ? this.event.type_code : ''

      switch (code) {
        case "CHANGE_REQUEST_STATUS_UPDATED":
        icon = "mdi-square-edit-outline"
        break;
        case "CANCELLATION_STATUS_UPDATED":
        icon = "mdi-cancel"
        break;
        case "RETURN_STATUS_UPDATED":
        icon = "mdi-arrow-left-bold-outline"
        break;
        case "REFUND_STATUS_UPDATED":
        case "MANUAL_REFUND_STATUS_UPDATED":
        icon = "mdi-credit-card-outline"
        break;
        default:
        break;
      }

      return icon
    },
    eventColor() {
      let eventColor = ""
      let code = (this.event) ? this.event.type_code : ''

      switch (code) {
        case "CHANGE_REQUEST_STATUS_UPDATED":
        eventColor = "themeStatusRequest"
        break;
        case "CANCELLATION_STATUS_UPDATED":
        case "RETURN_STATUS_UPDATED":
        eventColor = "themeStatusDelivery"
        break;
        case "REFUND_STATUS_UPDATED":
        case "MANUAL_REFUND_STATUS_UPDATED":
        eventColor = "themeStatusTransaction"
        break;
        default:
        break;
      }

      return eventColor
    },
  }
}
</script>
<style lang="scss" scoped>
</style>
