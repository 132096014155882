<template>
  <v-toolbar dense flat color="transparent">
    <v-btn color="themeText2" class="text-none" small text @click="goBackToTest()"><v-icon small class="pr-2">mdi-arrow-left</v-icon>Test</v-btn>
    <v-spacer></v-spacer>
    <v-btn color="themeText2" class="text-none" small text @click="copyUrlToClipboard()"><v-icon small class="pr-2">{{icon}}</v-icon>Url</v-btn>
    <edit-dialog-json title="Edit order" @change="onInputChanged" />
    <order-dialog-json />
  </v-toolbar>
</template>
<script>
import OrderDialogJson from '@/components/order/OrderDialogJson.vue'
import EditDialogJson from '@/components/common/EditDialogJson.vue'
import {mapActions} from "vuex"
import {ORDER_NS} from "@/store/modules/order/constants"

export default {
  name: "OrderToolbarTest",
  components: {
    OrderDialogJson,
    EditDialogJson,
  },
  data: () => ({
    icon: "mdi-clipboard-text-outline",
    defaultIcon: "mdi-clipboard-text-outline",
    copiedIcon: "mdi-clipboard-check-outline",
  }),
  methods: {
    ...mapActions(ORDER_NS, ['selectOrderToDisplay']),
    goBackToTest() {
      this.$router.push({'name':'Test'})
    },
    copyUrlToClipboard() {
      this.icon = this.copiedIcon
      navigator.clipboard.writeText(window.location.href)
      setTimeout(() => {  this.icon = this.defaultIcon }, 250)
    },
    onInputChanged(input) {
      try {
        let order = JSON.parse(input)
        if ( typeof order === 'object' && !Array.isArray(order) && order !== null ) {
          this.selectOrderToDisplay(order)
        } else {
          this.selectOrderToDisplay({})
        }
      } catch {
        this.selectOrderToDisplay({})
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
