<template>
  <v-expansion-panels v-model="panels">
    <v-expansion-panel class="mb-4">
      <v-expansion-panel-header class="grey lighten-5">
        <div class="grey--text body-2">{{label}}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="text-body-2 pt-4">
        <v-textarea solo flat
         autofocus
         clearable
         no-resize
         hide-details
         v-model="input"
         background-color="grey lighten-4"
         class="code"
         v-on:change="onInputChanged()" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
export default {
  name: "DataInput",
  data: () => ({
    panels: [0],
    input: ""
  }),
  props: {
    label: String,
    value: String
  },
  created() {
    this.value ? this.input = this.value : this.input = ""
  },
  methods: {
    onInputChanged() {
      try {
        let obj = JSON.parse(this.input)
        this.input = JSON.stringify(obj, null, 2)
      } catch {}

      this.$emit("change",this.input)
    }
  }
}
</script>
<style scoped>
.code {
  font-family: monospace;
}
</style>
