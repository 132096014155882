<template>
  <v-toolbar dense flat color="transparent">
    <v-btn color="themeText2" class="text-none" small text @click="goBackToOrders()"><v-icon small class="pr-2">mdi-arrow-left</v-icon>Orders</v-btn>
    <v-spacer></v-spacer>
    <v-btn color="themeText2" class="text-none" small text @click="copyUrlToClipboard()"><v-icon small class="pr-2">{{icon}}</v-icon>Url</v-btn>
    <order-dialog-json />
    <order-toolbar-menu />
  </v-toolbar>
</template>
<script>
import OrderDialogJson from '@/components/order/OrderDialogJson.vue'
import OrderToolbarMenu from '@/components/order/OrderToolbarMenu.vue'
import {mapGetters} from "vuex"
import {ORDER_NS} from "@/store/modules/order/constants"

export default {
  name: "OrderToolbar",
  components: {
    OrderDialogJson,
    OrderToolbarMenu,
  },
  data: () => ({
    icon: "mdi-clipboard-text-outline",
    defaultIcon: "mdi-clipboard-text-outline",
    copiedIcon: "mdi-clipboard-check-outline",
  }),
  computed: {
    ...mapGetters(ORDER_NS, [
      'searchQueryParams'
    ]),
  },
  methods: {
    goBackToOrders() {
      this.$router.push({'name':'Orders','query':this.searchQueryParams})
    },
    copyUrlToClipboard() {
      this.icon = this.copiedIcon
      navigator.clipboard.writeText(window.location.href)
      setTimeout(() => {  this.icon = this.defaultIcon }, 250)
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
