<template>
  <div>
    <app-bar />
    <order-toolbar />
    <v-container fluid>
      <v-row v-show="orderExists">
        <v-col cols="12">
          <order :order="order" />
        </v-col>
      </v-row>
      <div v-show="loadingFlag" class="text-center themeText2--text">
        <v-progress-circular size="100" width="10" indeterminate color="themeText2" />
        <div class="text-h6">Loading order...</div>
      </div>
      <div v-show="!errorMessage && !loadingFlag && !orderExists" class="text-center themeText2--text">
        <v-icon size="128" color="themeText2">mdi-emoticon-confused-outline</v-icon>
        <div class="text-h6">Whoops ! We couldn't find anything</div>
        <div>Double check your order id or try something else.</div>
      </div>
      <div v-show="errorMessage" class="text-center themeText2--text">
        <v-icon size="128" color="themeText2">mdi-emoticon-dead-outline</v-icon>
        <div class="text-h6">Whoops ! We have a problem...</div>
        <div>{{ errorMessage }}</div>
      </div>
      <div class="pt-4 pb-8">
        <app-footer />
      </div>
    </v-container>
  </div>
</template>

<script>
import AppBar from '@/components/app/AppBar.vue'
import AppFooter from '@/components/app/AppFooter.vue'
import OrderToolbar from '@/components/order/OrderToolbar.vue'
import Order from '@/components/order/Order.vue'
import { mapActions, mapGetters } from "vuex"
import { MERCHANT_NS } from "@/store/modules/merchant/constants"
import { ORDER_NS } from "@/store/modules/order/constants"

export default {
  name: 'OrderPage',
  components: {
    AppBar,
    AppFooter,
    Order,
    OrderToolbar
  },
  created() {
    const merchantId = this.$route.params.merchantId
    const idFromParams = this.$route.params.id
    const regex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/
    const isIdFromParamsUUID = regex.test(idFromParams)

    let params = {}
    params.env = this.$route.params.env

    if (!this.merchant.id && this.merchant.id != merchantId) {
      this.fetchMerchants({ params })
      this.selectMerchantById(merchantId)
    }

    if (isIdFromParamsUUID) {
      if (!this.order.id && this.order.id != idFromParams) {
        this.fetchOrderById({ 'merchantId':merchantId, 'orderId':idFromParams })
      }
    } else {
      if (!this.order.reference && this.order.reference != idFromParams) {
        this.fetchOrderByReference({ 'merchantId':merchantId, 'orderReference':idFromParams })
      }
    }
  },
  computed: {
    ...mapGetters(MERCHANT_NS, [
      'merchant'
    ]),
    ...mapGetters(ORDER_NS, [
      'order',
      'errorMessage',
      'loadingFlag',
    ]),
    orderExists() {
      let result = false
      if (this.order && this.order.id) {
        result = true
      }
      return result
    }
  },
  methods: {
    ...mapActions(MERCHANT_NS, ['fetchMerchants', 'selectMerchantById']),
    ...mapActions(ORDER_NS, [
      'fetchOrderById',
      'fetchOrderByReference',
    ]),
  }
}
</script>
