<template>
  <v-dialog v-model="dialog" :persistent="isExporting" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="themeText2" class="text-none" small text v-bind="attrs" :disabled="!hasDataToExport"
      v-on="on"><v-icon small class="pr-2">mdi-download</v-icon>Export results</v-btn>
    </template>
    <v-card class="themeCard">
      <div v-show="!isExporting" class="pa-2 py-4 themePanelHeader themeText2--text text-center body-2">Select the data you want to export</div>

      <v-card-text v-show="!isExporting" class="themeText1--text">
        <div class="pt-2 body-2 font-weight-bold">Data to export</div>
        <v-container fluid>
          <v-row>
            <v-checkbox :disabled="isExporting" dense hide-details v-model="exportDataSelection" label="Orders" value="orders" color="blue" :dark="themeIsDark"></v-checkbox>
            <v-checkbox :disabled="isExporting" dense hide-details v-model="exportDataSelection" label="Deliveries" value="deliveries" color="blue" :dark="themeIsDark"></v-checkbox>
            <v-checkbox :disabled="isExporting" dense hide-details v-model="exportDataSelection" label="Items" value="items" color="blue" :dark="themeIsDark"></v-checkbox>
            <v-checkbox :disabled="isExporting" dense hide-details v-model="exportDataSelection" label="Transactions" value="transactions" color="blue" :dark="themeIsDark"></v-checkbox>
            <v-checkbox :disabled="isExporting" dense hide-details v-model="exportDataSelection" label="Overview" value="overview" color="blue" :dark="themeIsDark"></v-checkbox>
      </v-row>
    </v-container>
    <div class="ml-1">
      <div v-for="(elem,index) in exportDataSelection" :key="index">
        <span class="caption themeText2--text">{{exportDataSelectionMessage(elem)}}</span>
      </div>
    </div>

    <div class="pt-2 body-2 font-weight-bold">Range to export</div>
    <v-radio-group v-model="exportRangeSelection" :disabled="isExporting" row dense hide-details class="mt-0 px-0 pt-2" :dark="themeIsDark">
      <v-radio label="Only visible results" value="visible" color="primary"></v-radio>
      <v-radio label="All results" value="all" color="primary"></v-radio>
    </v-radio-group>
    <div class="ml-1">
      <span class="caption themeText2--text">{{exportRangeSelectionMessage}}</span>
    </div>
  </v-card-text>

  <div v-show="isExporting" class="pa-4 text-center themeText2--text">
    <div v-show="!exportErrorFlag" class="pb-4">Exporting your data...</div>
    <div v-show="exportErrorFlag" class="pb-4">Oops, we've got an error exporting your data !</div>
    <div>
      <div class="themeText2--text caption">{{progressValue}}%</div>
    </div>
    <v-progress-linear :color="progressColor" :value="progressValue" buffer-value="100" rounded height="6"></v-progress-linear>
    <div v-show="exportErrorFlag">
      <div class="themeText2--text caption">{{exportErrorMessage}}</div>
    </div>
  </div>

  <div v-show="!isExporting" class="themePanelHeader pa-2">
    <v-btn color="primary" :disabled="!hasDataToExport || !hasDataToExportSelected" text block class="text-none" @click="startDataExport()">Export the selection</v-btn>
  </div>
  <div v-show="isExporting" class="themePanelHeader pa-2">
    <v-btn color="secondary" :disabled="!hasDataToExport || !hasDataToExportSelected" text block class="text-none" @click="cancelDataExport()">Cancel</v-btn>
  </div>
</v-card>
</v-dialog>
</template>
<script>

import {mapGetters} from "vuex"
import {getOrders} from "@/helpers/apiV1"
import {ordersToCSV} from "@/helpers/dataExport/orders"
import {deliveriesToCSV} from "@/helpers/dataExport/deliveries"
import {itemsToCSV} from "@/helpers/dataExport/items"
import {transactionsToCSV} from "@/helpers/dataExport/transactions"
import {overviewToCSV} from "@/helpers/dataExport/overview"
import {THEME_NS} from "@/store/modules/theme/constants"
import {ENV_NS} from "@/store/modules/env/constants"
import {MERCHANT_NS} from "@/store/modules/merchant/constants"
import {ORDER_NS} from "@/store/modules/order/constants"

export default {
  name: "OrdersExportDataDialog",
  data: () => ({
    failedRequests: 0,
    requestIndex: 0,
    nbOfRequests: 0,
    dialog: false,
    exportingFlag: false,
    exportErrorFlag: false,
    exportErrorMessage: '',
    exportRangeSelection: 'visible',
    exportDataSelection: ['orders'],
    exportEnv: '',
    exportMerchantId: '',
    exportQueryParams: {},
    exportPage: 0,
    exportOrders: []
  }),
  computed: {
    ...mapGetters(THEME_NS, ['themeIsDark',]),
    ...mapGetters(ENV_NS, ['env',]),
    ...mapGetters(MERCHANT_NS, ['merchant',]),
    ...mapGetters(ORDER_NS, [
      'orders',
      'totalNumber',
      'showingNumber',
      'searchQueryParams',
    ]),
    progressValue() {
      let value = 0
      if ( this.nbOfRequests > 0 ) {
        value = Math.round(100 * this.requestIndex / this.nbOfRequests)
      }
      return value
    },
    progressColor() {
      return this.exportErrorFlag == false ? 'primary' : 'secondary'
    },
    hasDataToExport() {
      return this.totalNumber > 0 ? true : false
    },
    hasDataToExportSelected() {
      return this.exportDataSelection.length > 0 ? true : false
    },
    isExporting() {
      return this.exportingFlag
    },
    exportRangeSelectionMessage() {
      let message = ""
      let number = 0

      if (this.exportRangeSelection == "all") {
        number = this.totalNumber
        message = "All the " + number + " result"
      } else if (this.exportRangeSelection == "visible") {
        number = this.showingNumber
        message = "Only the " + number + " visible result"
      }

      if ( number > 1 ) {
        message = message + "s"
      }

      message = message + " would be exported."

      return message
    }
  },
  methods: {
    exportDataSelectionMessage(selection) {
      let message = ""

      switch (selection) {
        case "orders":
        message = "Orders : 1 file with 1 line per order"
        break;
        case "deliveries":
        message = "Deliveries : 1 file with 1 line per delivery"
        break;
        case "items":
        message = "Items : 1 file with 1 line per item"
        break;
        case "payments":
        message = "Payments : 1 file with 1 line per payment"
        break;
        case "transactions":
        message = "Transactions : 1 file with 1 line per transaction"
        break;
        case "overview":
        message = "Overview : 1 file with overall statistics"
        break;
        default:
        break;
      }

      return message
    },
    startDataExport() {
      this.requestIndex = 0
      this.nbOfRequests = 0
      this.exportingFlag = true
      this.exportErrorFlag = false
      this.exportErrorMessage = ''
      this.exportOrders = []
      this.getDataToExport()
    },
    cancelDataExport() {
      this.exportingFlag = false
    },
    completeDataExport() {
      this.createExportFiles()
      this.dialog = false
      this.cancelDataExport()
    },
    getDataToExport() {
      this.exportMerchantId = this.merchant.id
      this.exportQueryParams = { ...this.searchQueryParams }
      this.exportEnv = this.env
      this.exportPage = 0

      if ( this.exportRangeSelection == "visible" || this.exportRangeSelection == "all" && this.totalNumber <= 20 ) {
        this.nbOfRequests = 1
        for (let order of this.orders) {
          this.exportOrders.push({...order})
        }
        this.requestIndex = 1
        this.completeDataExport()
      } else if ( this.exportRangeSelection == "all" ) {
        this.nbOfRequests = Math.ceil(this.totalNumber / 50)
        this.fetchData()
      }
    },
    fetchData() {
      const MAX_RETRY_PER_FAILED_REQUEST = 7

      if ( this.exportingFlag ) {
        let params = { ...this.exportQueryParams }
        params.env = this.exportEnv
        params.page = this.exportPage
        params.size = 50

        if ( this.requestIndex < this.nbOfRequests ) {
          getOrders(this.exportMerchantId,params)
          .then((response) => {
            this.failedRequests = 0
            this.exportPage = this.exportPage + 1
            this.requestIndex = this.requestIndex + 1
            this.exportOrders.push(...response.data)
            this.fetchData()
          })
          .catch((error) => {
            this.failedRequests = this.failedRequests + 1

            if ( this.failedRequests < MAX_RETRY_PER_FAILED_REQUEST ) {
              console.log("A request failed with error " + JSON.stringify(error) + " Retry N°" + (this.failedRequests + 1) + "/" + MAX_RETRY_PER_FAILED_REQUEST)
              this.fetchData()
            } else {
              console.log(JSON.stringify(error))
              this.exportErrorFlag = true
              this.exportErrorMessage = error
            }

          })
        } else {
          this.completeDataExport()
        }
      }
    },
    createExportFiles() {
      if (this.exportDataSelection.indexOf("orders") >= 0 ) {
        this.createOrdersExportFile()
      }

      if (this.exportDataSelection.indexOf("deliveries") >= 0 ) {
        this.createDeliveriesExportFile()
      }

      if (this.exportDataSelection.indexOf("items") >= 0 ) {
        this.createItemsExportFile()
      }

      if (this.exportDataSelection.indexOf("transactions") >= 0 ) {
        this.createTransactionsExportFile()
      }

      if (this.exportDataSelection.indexOf("overview") >= 0 ) {
        this.createOverviewExportFile()
      }
    },
    createOrdersExportFile(){
      let filename = 'OneOM_'+this.exportEnv+'_'+this.exportMerchantId+'_orders_'+Date.now()+'.csv'
      let content = ordersToCSV(this.exportOrders)
      this.downloadFile(filename,content)
    },
    createDeliveriesExportFile(){
      let filename = 'OneOM_'+this.exportEnv+'_'+this.exportMerchantId+'_deliveries_'+Date.now()+'.csv'
      let content = deliveriesToCSV(this.exportOrders)
      this.downloadFile(filename,content)
    },
    createItemsExportFile(){
      let filename = 'OneOM_'+this.exportEnv+'_'+this.exportMerchantId+'_items_'+Date.now()+'.csv'
      let content = itemsToCSV(this.exportOrders)
      this.downloadFile(filename,content)
    },
    createTransactionsExportFile(){
      let filename = 'OneOM_'+this.exportEnv+'_'+this.exportMerchantId+'_transactions_'+Date.now()+'.csv'
      let content = transactionsToCSV(this.exportOrders)
      this.downloadFile(filename,content)
    },
    createOverviewExportFile(){
      let filename = 'OneOM_'+this.exportEnv+'_'+this.exportMerchantId+'_overview_'+Date.now()+'.csv'
      let content = overviewToCSV(this.exportOrders)
      this.downloadFile(filename,content)
    },
    downloadFile(filename,content) {
      let element = document.createElement('a')
      element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(content))
      element.setAttribute('download', filename)

      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    }
  }
}
</script>
