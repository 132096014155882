<template>
  <!-- <v-img :src="require('@/assets/logo.svg')" contain :height="height"/> -->
  <v-img :src="appLogo" contain :height="height"/>
</template>
<script>
import {mapGetters} from "vuex"
import {THEME_NS} from "@/store/modules/theme/constants"

export default {
  name: "AppLogo",
  props: ['size'],
  computed: {
    ...mapGetters(THEME_NS, [
      'themeLogo'
    ]),
    height() {
      return this.size ? this.size : '128'
    },
    appLogo() {
      return require('@/assets/' + this.themeLogo + '.svg')
    },
  }
}
</script>
<style scoped>
</style>
