const actions = {
  updateData({commit}, {env,data}) {
    commit('updateAccessToken', {'env':env,'accessToken': data.access_token})
    commit('updateTokenType', {'env':env,'tokenType': data.token_type})
    commit('updateExpiresIn', {'env':env,'expiresIn': data.expires_in})

    const payload = JSON.parse(atob(data.access_token.split(".")[1]))
    const name = payload.uid

    commit('updateUserName', {'env':env,'userName':name})
  }
}

export default actions;
