<template>
  <v-list-item @click.alt.exact="displayOrderDetailsInNewTab()" @click.exact="displayOrder()" :key="order.id" class="themeText1--text">
    <v-row no-gutters class="py-2">
      <v-col cols="6">
        <v-row
          no-gutters
          class="subtitle-2 text-no-wrap text-truncate align-center"
        >
          <v-icon small color="primary" class="mr-1">{{ channelIcon }}</v-icon>
          <span>
            <copyable :text="order.reference" />
          </span>
        </v-row>
        <order-type :code="order.type_code" />
        <div class="caption text-no-wrap text-truncate">{{ customerName }}</div>
        <div class="caption text-no-wrap text-truncate">
          <copyable :text="order.id" />
        </div>
        <div class="caption themeText2--text">
          <v-icon small color="themeText2">mdi-dolly</v-icon>
          <span> x {{ numberOfFulfillment }} / </span>
          <v-icon small color="themeText2">mdi-credit-card-outline</v-icon>
          <span> x {{ numberOfPayment }} / </span>
          <v-icon small color="themeText2"
          >mdi-comment-processing-outline</v-icon
          >
          <span> x {{ numberOfNote }}</span>
        </div>
      </v-col>
      <v-col cols="6" class="text-right">
        <order-status :code="order.status_code" />
        <div class="caption">{{ order.creation_date_time }}</div>
        <div class="caption themeText2--text">
          <span class="font-weight-bold">{{ totalOfOrder }} {{ order.currency_code }}</span>
        </div>
        <div class="caption themeText2--text">
          <v-icon small color="themeText2">mdi-package-variant-closed</v-icon>
          <span> x {{ numberOfProduct }} + </span>
          <v-icon small color="themeText2">mdi-truck-fast</v-icon>
          <span> x {{ numberOfShippingFees }}</span>
        </div>
        <div class="caption themeText2--text">
          <v-icon small color="themeText2">mdi-arrow-right-bold-outline</v-icon>
          <span> x {{ numberOfDelivery }} / </span>
          <v-icon small color="themeText2">mdi-cancel</v-icon>
          <span> x {{ numberOfCancellation }} / </span>
          <v-icon small color="themeText2">mdi-arrow-left-bold-outline</v-icon>
          <span> x {{ numberOfReturn }}</span>
        </div>
      </v-col>
    </v-row>
  </v-list-item>
</template>
<script>
import { mapActions } from "vuex";
import { getCurrencyDecimal } from "@/helpers/currency";
import { ORDER_NS } from "@/store/modules/order/constants";
import Copyable from "@/components/common/Copyable.vue";
import OrderStatus from "@/components/order/OrderStatus.vue";
import OrderType from "@/components/order/OrderType.vue";

export default {
  name: "OrderLine",
  props: {
    order: Object,
  },
  components: {
    Copyable,
    OrderStatus,
    OrderType,
  },
  computed: {
    channelIcon() {
      let icon = "";

      switch (this.order.source.channel.type_code) {
        case "ONLINE":
          icon = "mdi-web";
          break;
        case "IN_STORE":
          icon = "mdi-storefront";
          break;
        case "OUT_STORE":
          icon = "mdi-storefront-outline";
          break;
        default:
          icon = "mdi-help";
          break;
      }

      return icon;
    },
    creationDate() {
      return this.order.creation_date_time.replace(/.\d+Z$/g, "+00:00");
    },
    numberOfFulfillment() {
      return this.order.fulfillments.length;
    },
    numberOfPayment() {
      return this.order.payments.length;
    },
    numberOfNote() {
      return this.order.notes.length;
    },
    numberOfProduct() {
      let result = 0;
      let fulfillments = this.order.fulfillments;

      for (var i = 0; i < fulfillments.length; i++) {
        let lineItems = fulfillments[i].line_items;
        for (var j = 0; j < lineItems.length; j++) {
          if (lineItems[j].type_code == "PRODUCT") {
            result = result + lineItems[j].quantity;
          }
        }
      }

      return result;
    },
    numberOfShippingFees() {
      let result = 0;
      let fulfillments = this.order.fulfillments;

      for (var i = 0; i < fulfillments.length; i++) {
        let lineItems = fulfillments[i].line_items;
        for (var j = 0; j < lineItems.length; j++) {
          if (lineItems[j].type_code == "SHIPPING_FEES") {
            result = result + lineItems[j].quantity;
          }
        }
      }

      return result;
    },
    numberOfDelivery() {
      let result = 0;
      let fulfillments = this.order.fulfillments;

      for (var i = 0; i < fulfillments.length; i++) {
        let deliveries = fulfillments[i].deliveries;
        result = result + deliveries.length;
      }

      return result;
    },
    numberOfReturn() {
      let result = 0;
      let fulfillments = this.order.fulfillments;

      for (var i = 0; i < fulfillments.length; i++) {
        let returns = fulfillments[i].returns;
        result = result + returns.length;
      }

      return result;
    },
    numberOfCancellation() {
      let result = 0;
      let fulfillments = this.order.fulfillments;

      for (var i = 0; i < fulfillments.length; i++) {
        let cancellations = fulfillments[i].cancellations;
        result = result + cancellations.length;
      }

      return result;
    },
    numberOfPayments() {
      let result = 0;
      let payments = this.order.payments;

      for (var i = 0; i < payments.length; i++) {
        result = result + 1;
      }
      return result;
    },
    totalOfOrder() {
      const currencyCode = this.order.currency_code;
      const decimal = getCurrencyDecimal(currencyCode);
      let totalOfOrder = 0;
      let fulfillments = this.order.fulfillments;

      for (var i = 0; i < fulfillments.length; i++) {
        let total = fulfillments[i].total_amount;
        totalOfOrder = totalOfOrder + total;
      }

      return totalOfOrder.toFixed(decimal)
    },
    customerName() {
      let firstName = this.order.customer.billing_address.first_name;
      let lastName =
        this.order.customer.billing_address.last_name.toUpperCase();
      return firstName + " " + lastName;
    },
  },
  methods: {
    ...mapActions(ORDER_NS, ["selectOrderToDisplay"]),
    displayOrder() {
      this.selectOrderToDisplay(this.order);
      this.$router.push({ name: "Order", params: { id: this.order.reference } });
    },
    displayOrderDetailsInNewTab() {
      let route = this.$router.resolve({
        'name':'Order',
        'params':{
          'id':this.order.reference
        }
      })
      window.open(route.href, '_blank')
    }
  },
};
</script>
<style lang="scss" scoped>
</style>
