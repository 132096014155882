import moment from "moment"
import {ACCESS_TOKEN,TOKEN_TYPE,EXPIRES_IN,USER_NAME} from "./constants"

const getters = {
  accessToken: (state) => (env) => {
    return localStorage.getItem(env+'.'+ACCESS_TOKEN)
  },
  tokenType: (state) => (env) => {
    return localStorage.getItem(env+'.'+TOKEN_TYPE)
  },
  expiresIn: (state) => (env) => {
    return localStorage.getItem(env+'.'+EXPIRES_IN)
  },
  userName: (state) => (env) => {
    return localStorage.getItem(env+'.'+USER_NAME)
  },
  isTokenValid: (state,getters) => (env) => {
    return getters.accessToken(env) && getters.tokenType(env) && getters.expiresIn(env) &&
    moment().isBefore(moment(getters.expiresIn(env)))
  },
}

export default getters;
