<template>
  <div class="">
    <div class="themeText2--text font-weight-bold">{{type}}</div>
    <div v-show="fullName">
      <span class="themeText2--text">name : </span>
      <span class="themeText1--text">{{fullName}}</span>
    </div>
    <div v-show="addressCompany">
      <span class="themeText2--text">company : </span>
      <span class="themeText1--text">{{addressCompany}}</span>
    </div>
    <div v-for="(addressLine,i) in addressLines" :key="i">
      <span class="themeText2--text">address line{{i+1}} : </span>
      <span class="themeText1--text">{{addressLine}}</span>
    </div>
    <div v-show="addressCity">
      <span class="themeText2--text">city : </span>
      <span class="themeText1--text">{{addressCity}}</span>
    </div>
    <div v-show="addressPostalCode">
      <span class="themeText2--text">postal code : </span>
      <span class="themeText1--text">{{addressPostalCode}}</span>
    </div>
    <div v-show="addressProvinceCode">
      <span class="themeText2--text">province code : </span>
      <span class="themeText1--text">{{addressProvinceCode}}</span>
    </div>
    <div v-show="addressCountryCode">
      <span class="themeText2--text">country code : </span>
      <span class="themeText1--text">{{addressCountryCode}}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "OrderAddress",
  props: {
    type: String,
    address: Object,
  },
  computed: {
    fullName() {
      let name = ""

      if (this.addressFirstName) {
        name += this.addressFirstName
      }

      if (this.addressFirstName && this.addressLastName) {
        name += " "
      }

      if (this.addressLastName) {
        name += this.addressLastName
      }

      return name
    },
    addressFirstName() { return (this.address && this.address.first_name) ? this.address.first_name : "" },
    addressLastName() { return (this.address && this.address.last_name) ? this.address.last_name : "" },
    addressCompany() { return (this.address && this.address.company) ? this.address.company : "" },
    addressLines() { return (this.address && this.address.address_lines) ? this.address.address_lines : [] },
    addressCity() { return (this.address && this.address.city) ? this.address.city : "" },
    addressPostalCode() { return (this.address && this.address.postal_code) ? this.address.postal_code : "" },
    addressProvinceCode() { return (this.address && this.address.province_code) ? this.address.province_code : "" },
    addressCountryCode() { return (this.address && this.address.country_code) ? this.address.country_code : "" },
  }
}
</script>
<style lang="scss" scoped>
</style>
