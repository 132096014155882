<template>
  <v-card outlined class="themePanelHeader mb-3">
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="6">
          <div class="themeText2--text font-weight-bold text-truncate">
            <v-icon color="themeText3" class="mr-1">mdi-receipt</v-icon>
            <copyable :text="transactionReference" />
          </div>
          <div class="caption themeText1--text">{{transactionCreationDateTime}}</div>
          <div class="caption themeText2--text">{{transactionUpdateDateTime}}</div>
        </v-col>
        <v-col cols="6" class="text-right">
          <order-transaction-status :code="transactionStatusCode" />
          <div class="font-weight-bold  themeText1--text">{{transactionAmount}} {{currencyCode}}</div>
        </v-col>
      </v-row>
    </v-card-text>
    <div v-show="hasAdditionalAttributes">
      <v-divider class="themeDivider"></v-divider>
      <v-card-text>
        <order-additional-attributes :attributes="transactionAdditionalAttributes" />
      </v-card-text>
    </div>
  </v-card>
</template>
<script>
import {getCurrencyDecimal} from "@/helpers/currency"
import Copyable from '@/components/common/Copyable.vue'
import OrderTransactionStatus from '@/components/order/OrderTransactionStatus.vue'
import OrderAdditionalAttributes from '@/components/order/OrderAdditionalAttributes.vue'

export default {
  name: "OrderTransaction",
  props: {
    transaction: Object,
    currencyCode: String,
  },
  components: {
    Copyable,
    OrderTransactionStatus,
    OrderAdditionalAttributes,
  },
  computed: {
    transactionReference() {
      return this.transaction ? this.transaction.reference : ""
    },
    transactionCreationDateTime() {
      return this.transaction ? this.transaction.creation_date_time : ""
    },
    transactionUpdateDateTime() {
      return this.transaction ? this.transaction.update_date_time : ""
    },
    transactionStatusCode() {
      return this.transaction ? this.transaction.status_code : ""
    },
    transactionAmount() {
      const currencyCode = this.currencyCode
      const decimal = getCurrencyDecimal(currencyCode)
      return this.transaction && this.transaction.amount >= 0 ? this.transaction.amount.toFixed(decimal) : (0).toFixed(decimal)
    },
    hasAdditionalAttributes() {
      return (this.transaction && this.transaction.additional_attributes && Object.keys(this.transaction.additional_attributes).length > 0) ? true : false
    },
    transactionAdditionalAttributes() {
      return this.transaction ? this.transaction.additional_attributes : {}
    },
  },
}
</script>
<style scoped>
</style>
