<template>
  <div>
    <app-bar/>
    <requests-toolbar />
    <v-container fluid>
      <v-row class="text-center">
        <v-col cols="12">
          <requests-list />
        </v-col>
      </v-row>
      <div class="pt-2 pb-8">
        <app-footer />
      </div>
    </v-container>
  </div>
</template>

<script>
import AppBar from '@/components/app/AppBar.vue'
import AppFooter from '@/components/app/AppFooter.vue'
import RequestsToolbar from '@/components/requests/RequestsToolbar.vue'
import RequestsList from '@/components/requests/RequestsList.vue'

import {mapGetters,mapActions} from "vuex"
import {MERCHANT_NS} from "@/store/modules/merchant/constants"
import {REQUEST_NS} from "@/store/modules/request/constants"

export default {
  name: 'RequestsPage',
  components: {
    AppBar,
    AppFooter,
    RequestsToolbar,
    RequestsList
  },
  created() {
    const merchantId = this.$route.params.merchantId
    let query = this.$route.query
    let params = {}
    params.env = this.$route.params.env

    if ( !this.merchant.id && this.merchant.id != merchantId ) {
      this.fetchMerchants({params})
      this.selectMerchantById(merchantId)
    }

    params = {}
    for (var queryParam in query) {
      if (query.hasOwnProperty(queryParam)) {
        params[queryParam] = query[queryParam]
      }
    }

    this.updateSearchQueryParams(params)
    this.updateRequestsURL({'vue':this})
    this.fetchRequests({merchantId, 'params':this.searchQueryParams})
  },
  computed: {
    ...mapGetters(MERCHANT_NS, [
      'merchant'
    ]),
    ...mapGetters(REQUEST_NS, [
      'searchQueryParams'
    ]),
  },
  methods: {
    ...mapActions(MERCHANT_NS, ['fetchMerchants','selectMerchantById']),
    ...mapActions(REQUEST_NS, ['fetchRequests','updateSearchQueryParams','updateRequestsURL']),
  }
}
</script>
