import { getCurrencyDecimal } from "@/helpers/currency"

let itemsToCSV = (orders) => {
    let content = ""

    content = content + "Merchant Id,"
    content = content + "Order Id,"
    content = content + "Order Reference,"
    content = content + "Order Creation Date Time,"
    content = content + "Order Update Date Time,"
    content = content + "Order Status,"
    content = content + "Currency,"
    content = content + "Fulfillment Reference,"
    content = content + "Delivery Reference,"
    content = content + "Delivery Type,"
    content = content + "Delivery Status,"
    content = content + "Delivery Update Date Time,"
    content = content + "Fulfiller Delivery Reference,"

    content = content + "Item Reference,"
    content = content + "Item Type,"
    content = content + "Item SKU,"
    content = content + "Seller Reference,"
    content = content + "Seller Item Reference,"
    content = content + "Price,"
    content = content + "Total Quantity,"
    content = content + "Total,"
    content = content + "Total Ordered Quantity,"
    content = content + "Total Ordered,"
    content = content + "Total Cancelled Quantity,"
    content = content + "Total Cancelled,"
    content = content + "Total Returned Quantity,"
    content = content + "Total Returned,"
    content = content + "Total Adjusted,"
    content = content + "Total Adjusted (post-checkout)\n"

    for (const order of orders) {
        const merchantId = order.source ? order.source.merchant_id : ""
        const currencyCode = order.currency_code
        const decimal = getCurrencyDecimal(currencyCode)
        const orderId = order.id
        const orderReference = order.reference
        const orderCreationDateTime = order.creation_date_time
        const orderUpdateDateTime = order.update_date_time
        const orderStatus = order.status_code

        for (const fulfillment of order.fulfillments) {
            const fulfillmentReference = fulfillment.reference

            for (const delivery of fulfillment.deliveries) {
                const deliveryReference = delivery.reference
                const deliveryType = delivery.type_code
                const deliveryStatus = delivery.status_code
                const deliveryUpdateDateTime = delivery.update_date_time

                let fulfillerDeliveryReference = delivery.fulfiller_delivery_reference

                if (!fulfillerDeliveryReference && delivery.additional_attributes) {
                    fulfillerDeliveryReference = delivery.additional_attributes.fulfiller_delivery_reference ? delivery.additional_attributes.fulfiller_delivery_reference : ""
                }

                let itemsToExport = new Map()

                for (const item of delivery.line_items) {
                    let itemToExport = {}
                    itemToExport.reference = item.reference
                    itemToExport.type = item.type_code
                    itemToExport.sku = item.sku
                    itemToExport.sellerReference = item.seller ? item.seller.reference : ""
                    itemToExport.sellerItemReference = item.seller ? item.seller.item_reference : ""
                    itemToExport.price = item.price
                    itemToExport.amount = item.amount
                    itemToExport.quantity = item.quantity
                    itemToExport.orderedAmount = item.amount
                    itemToExport.orderedQuantity = item.quantity
                    itemToExport.cancelledAmount = 0
                    itemToExport.cancelledQuantity = 0
                    itemToExport.returnedAmount = 0
                    itemToExport.returnedQuantity = 0
                    itemToExport.totalAdjusted = 0
                    itemToExport.totalAdjustedPostCheckout = 0

                    for (const adjustment of item.adjustments) {
                        itemToExport.totalAdjusted = itemToExport.totalAdjusted + adjustment.amount

                        if (adjustment.change_request_id) {
                            itemToExport.totalAdjustedPostCheckout = itemToExport.totalAdjustedPostCheckout + adjustment.amount
                        }
                    }

                    itemToExport.orderedAmount = itemToExport.orderedAmount + itemToExport.totalAdjustedPostCheckout

                    itemsToExport.set(itemToExport.reference, itemToExport)
                }

                for (const cancellation of delivery.cancellations) {
                    const item = cancellation.line_item
                    let itemToExport = {}

                    itemToExport.reference = item.reference
                    itemToExport.type = item.type_code
                    itemToExport.sku = item.sku
                    itemToExport.sellerReference = item.seller ? item.seller.reference : ""
                    itemToExport.sellerItemReference = item.seller ? item.seller.item_reference : ""
                    itemToExport.price = item.price
                    itemToExport.amount = 0
                    itemToExport.quantity = 0
                    itemToExport.orderedAmount = 0
                    itemToExport.orderedQuantity = 0
                    itemToExport.cancelledAmount = 0
                    itemToExport.cancelledQuantity = 0
                    itemToExport.returnedAmount = 0
                    itemToExport.returnedQuantity = 0
                    itemToExport.totalAdjusted = 0
                    itemToExport.totalAdjustedPostCheckout = 0

                    if (itemsToExport.has(item.reference)) {
                        itemToExport = itemsToExport.get(item.reference)
                    }

                    itemToExport.cancelledQuantity = itemToExport.cancelledQuantity + item.quantity
                    itemToExport.cancelledAmount = itemToExport.cancelledAmount + item.amount

                    for (const adjustment of item.adjustments) {
                        itemToExport.totalAdjusted = itemToExport.totalAdjusted + adjustment.amount

                        if (adjustment.change_request_id) {
                            itemToExport.totalAdjustedPostCheckout = itemToExport.totalAdjustedPostCheckout + adjustment.amount
                        }
                    }

                    itemToExport.orderedAmount = itemToExport.orderedAmount + itemToExport.cancelledAmount + itemToExport.totalAdjustedPostCheckout
                    itemToExport.orderedQuantity = itemToExport.orderedQuantity + itemToExport.cancelledQuantity

                    itemsToExport.set(itemToExport.reference, itemToExport)
                }

                for (const returned of fulfillment.returns) {
                    for (const item of returned.line_items) {
                        if (item.delivery_reference == deliveryReference) {
                            let itemToExport = itemsToExport.get(item.reference)
                            itemToExport.returnedAmount = itemToExport.returnedAmount + item.amount
                            itemToExport.returnedQuantity = itemToExport.returnedQuantity + item.quantity
                        }
                    }
                }


                for (const [itemReference, item] of itemsToExport) {
                    content = content + merchantId + ","
                    content = content + orderId + ","
                    content = content + orderReference + ","
                    content = content + orderCreationDateTime + ","
                    content = content + orderUpdateDateTime + ","
                    content = content + orderStatus + ","
                    content = content + currencyCode + ","
                    content = content + fulfillmentReference + ","
                    content = content + deliveryReference + ","
                    content = content + deliveryType + ","
                    content = content + deliveryStatus + ","
                    content = content + deliveryUpdateDateTime + ","
                    content = content + fulfillerDeliveryReference + ","
                    content = content + item.reference + ","
                    content = content + item.type + ","
                    content = content + item.sku + ","
                    content = content + item.sellerReference + ","
                    content = content + item.sellerItemReference + ","
                    content = content + item.price + ","
                    content = content + item.quantity + ","
                    content = content + item.amount + ","
                    content = content + item.orderedQuantity + ","
                    content = content + item.orderedAmount + ","
                    content = content + item.cancelledQuantity + ","
                    content = content + item.cancelledAmount + ","
                    content = content + item.returnedQuantity + ","
                    content = content + item.returnedAmount + ","
                    content = content + item.totalAdjusted + ","
                    content = content + item.totalAdjustedPostCheckout + "\n"
                }
            }
        }
    }

    return content
}

export {
    itemsToCSV,
}