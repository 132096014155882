import moment from "moment"
import {ACCESS_TOKEN,TOKEN_TYPE,EXPIRES_IN,USER_NAME} from "./constants"

const mutations = {
  updateAccessToken(state, {env,accessToken}) {
    state.accessToken = accessToken
    localStorage.setItem(env+'.'+ACCESS_TOKEN, accessToken)
  },
  updateTokenType(state, {env,tokenType}) {
    state.tokenType = tokenType
    localStorage.setItem(env+'.'+TOKEN_TYPE, tokenType)
  },
  updateExpiresIn(state, {env,expiresIn}) {
    let isoDateTime = moment().add(expiresIn, 'seconds').toISOString()
    state.expiresIn = isoDateTime
    localStorage.setItem(env+'.'+EXPIRES_IN, isoDateTime)
  },
  updateUserName(state, {env,userName}) {
    state.userName = userName
    localStorage.setItem(env+'.'+USER_NAME, userName)
  },
};

export default mutations;
