<template>
  <div class="px-4">
    <request-header :request="request"/>
    <v-row class="mt-0">
      <v-col cols="12" md="8" >
         <request-cancellations v-show="hasCancellations" :request="request"/>
         <request-returns v-show="!hasReturn && hasReturns" :request="request"/>
         <request-return v-show="hasReturn" :return-request="returnRequest"/>
         <request-refund v-show="hasRefund" :request="request"/>
         <request-refund-customer v-show="hasRefundCustomer" :request="request"/>
         <request-refund-manual v-show="hasRefundManual" :request="request"/>
         <div class="mt-4" v-show="hasAdditionalAttributes">
           <request-additional-attributes :attributes="additionalAttributes" />
         </div>
         <request-notes v-show="hasNotes" :request="request"/>
      </v-col>
      <v-col cols="12" md="4" >
        <request-order :order="request.order" />
        <request-timeline :events="request.events" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import RequestHeader from '@/components/request/RequestHeader.vue'
import RequestCancellations from '@/components/request/RequestCancellations.vue'
import RequestReturn from '@/components/request/RequestReturn.vue'
import RequestReturns from '@/components/request/RequestReturns.vue'
import RequestRefund from '@/components/request/RequestRefund.vue'
import RequestRefundCustomer from '@/components/request/RequestRefundCustomer.vue'
import RequestRefundManual from '@/components/request/RequestRefundManual.vue'
import RequestAdditionalAttributes from '@/components/request/RequestAdditionalAttributes.vue'
import RequestNotes from '@/components/request/RequestNotes.vue'
import RequestOrder from '@/components/request/RequestOrder.vue'
import RequestTimeline from '@/components/request/RequestTimeline.vue'

export default {
  name: "Request",
  props: {
    request: Object,
  },
  components: {
    RequestHeader,
    RequestCancellations,
    RequestReturn,
    RequestReturns,
    RequestRefund,
    RequestRefundCustomer,
    RequestRefundManual,
    RequestAdditionalAttributes,
    RequestNotes,
    RequestOrder,
    RequestTimeline,
  },
  computed: {
    hasCancellations() {
      return (this.request && this.request.cancellations && this.request.cancellations.length) ? true : false
    },
    hasReturns() {
      return (this.request && this.request.returns && this.request.returns.length) ? true : false
    },
    hasReturn() {
      return (this.request && this.request.return) ? true : false
    },
    hasRefund() {
      return (this.request && this.request.refund) ? true : false
    },
    hasRefundCustomer() {
      return (this.request && this.request.refund_customer) ? true : false
    },
    hasRefundManual() {
      return (this.request && this.request.refund_manual) ? true : false
    },
    hasNotes() {
      return (this.request && this.request.notes && this.request.notes.length) ? true : false
    },
    hasAdditionalAttributes() {
      return (this.request && this.request.additional_attributes) ? true : false
    },
    additionalAttributes() {
      let attributes = {}

      if ( this.request && this.request.additional_attributes ) {
        attributes = this.request.additional_attributes
      }

      return attributes
    },
    returnRequest() {
      let returnRequest = {}

      if ( this.request && this.request.return ) {
        returnRequest = this.request.return
      }

      return returnRequest
    },
  },
  methods: {
  }
}
</script>
<style scoped>
</style>
