<template>
  <v-menu v-model="menu" :close-on-content-click="false" offset-y bottom right transition="scale-transition" origin="top left">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="themeText2" class="text-none" small text v-bind="attrs" v-on="on">
        <v-icon small class="pr-2" :color="iconColor">{{icon}}</v-icon>{{label}}
      </v-btn>
    </template>
    <v-card class="themeCard">
      <div class="pa-2 py-4 themePanelHeader themeText2--text text-center body-2">Select the date range to focus on</div>
      <div class="pt-2 caption text-center">
        <span class="themeText2--text">Timezone : </span>
        <span class="themeText1--text">{{timezone}}</span>
      </div>
      <v-date-picker v-model="dateTimePicker" @change="applyDateTimeFilters()" first-day-of-week="1" show-adjacent-months no-title show-current color="primary" range :dark="themeIsDark"></v-date-picker>
      <div class="themePanelHeader pa-2">
        <v-btn color="themeText2" text block class="text-none" @click="disableAllDateTimeFilters()">Remove all date time filters</v-btn>
      </div>
    </v-card>
  </v-menu>
</template>
<script>
import moment from 'moment-timezone'

import {mapGetters,mapActions} from "vuex"
import {MERCHANT_NS} from "@/store/modules/merchant/constants"
import {REQUEST_NS} from "@/store/modules/request/constants"
import {THEME_NS} from "@/store/modules/theme/constants"

export default {
  name: "RequestsMenuDateTime",
  data: () => ({
    menu: false,
    dateTimeQuery: "",
    creationDateTimeStart: "",
    creationDateTimeEnd: "",
    dateTimePicker:[],
  }),
  created() {
    if (this.searchQueryParams) {
      if ( this.searchQueryParams['creation_date_time_start'] ) {
        this.creationDateTimeStart = this.searchQueryParams['creation_date_time_start']
        this.dateTimePicker.push(this.creationDateTimeStart.substr(0,10))
      }

      if ( this.searchQueryParams['creation_date_time_end'] ) {
        this.creationDateTimeEnd += this.searchQueryParams['creation_date_time_end']
        this.dateTimePicker.push(this.creationDateTimeEnd.substr(0,10))
      }

      this.updateDateTimeQuery()
    }
  },
  computed: {
    ...mapGetters(THEME_NS, ['themeIsDark']),
    ...mapGetters(MERCHANT_NS, [
      'merchant'
    ]),
    ...mapGetters(REQUEST_NS, [
      'searchQueryParams'
    ]),
    label() {
      let label = "Creation date time"

      if ( this.dateTimePicker.length == 2 ) {
        label = this.dateTimePicker[0] + " → " + this.dateTimePicker[1]
      }

      return label
    },
    icon() {
      return (this.dateTimeQuery == "") ? 'mdi-clock-outline' : 'mdi-clock'
    },
    iconColor() {
      return (this.dateTimeQuery == "") ? 'themeText2' : 'primary'
    },
    timezone() {
      let timezone = "Etc/UTC"

      if ( this.merchant.timezone && moment.tz.zone(this.merchant.timezone) != null ) {
        timezone = this.merchant.timezone
      }

      return timezone
    }
  },
  methods: {
    ...mapActions(REQUEST_NS, ['fetchRequests','addSearchQueryParam','removeSearchQueryParam','updateRequestsURL']),
    applyDateTimeFilters() {
      this.dateTimePicker.sort()
      
      /*
      this.creationDateTimeStart = this.dateTimePicker[0] + "T00:00:00+00:00"
      this.creationDateTimeEnd = this.dateTimePicker[1] + "T23:59:59+00:00"
      */

      let creationDateTimeStart = moment.tz(this.dateTimePicker[0] + " 00:00:00", this.timezone)
      let creationDateTimeEnd = moment.tz(this.dateTimePicker[1] + " 23:59:59", this.timezone)

      this.creationDateTimeStart = creationDateTimeStart.format()
      this.creationDateTimeEnd = creationDateTimeEnd.format()

      this.updateDateTimeQuery()
      this.updateSearchResults()
    },
    disableAllDateTimeFilters() {
      this.dateTimePicker = []
      this.creationDateTimeStart = ""
      this.creationDateTimeEnd = ""
      this.updateDateTimeQuery()
      this.updateSearchResults()
    },
    updateDateTimeQuery() {
      this.dateTimeQuery = ""

      if ( this.creationDateTimeStart ) {
        this.dateTimeQuery += this.creationDateTimeStart
      }

      if ( this.creationDateTimeStart && this.creationDateTimeEnd ) {
        this.dateTimeQuery += '&'
      }

      if ( this.creationDateTimeEnd ) {
        this.dateTimeQuery += this.creationDateTimeEnd
      }
    },
    updateSearchResults() {
      if ( this.creationDateTimeStart ) {
        this.addSearchQueryParam({'key':'creation_date_time_start','value':this.creationDateTimeStart})
      } else {
        this.removeSearchQueryParam('creation_date_time_start')
      }

      if ( this.creationDateTimeEnd ) {
        this.addSearchQueryParam({'key':'creation_date_time_end','value':this.creationDateTimeEnd})
      } else {
        this.removeSearchQueryParam('creation_date_time_end')
      }

      this.updateRequestsURL({'vue':this})
      this.fetchRequests({'merchantId':this.merchant.id, 'params':this.searchQueryParams})
    }
  },
}
</script>
