<template>
  <v-card outlined class="themePanelHeader mb-3">
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="6">
          <div class="themeText2--text font-weight-bold text-truncate">
            <copyable :text="documentReference" />
            <div>
              <order-document-type :type="documentTypeCode"/>
            </div>
          </div>
        </v-col>
        <v-col cols="6" class="text-right">
          <div class="caption themeText2--text text-truncate">{{documentCreationDateTime}}</div>
          <div class="caption themeText2--text">{{documentSource}}</div>
        </v-col>
      </v-row>
      <div v-show="documentUrl">
        <v-icon small color="themeText2">mdi-link</v-icon>
        <a :href="documentUrl" target="_blank"> {{documentUrl}}</a>
      </div>
      <div v-show="hasAdditionalAttributes" class="mt-3">
        <order-additional-attributes :attributes="documentAdditionalAttributes" />
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import Copyable from '@/components/common/Copyable.vue'
import OrderAdditionalAttributes from '@/components/order/OrderAdditionalAttributes.vue'
import OrderDocumentType from '@/components/order/OrderDocumentType.vue'

export default {
  name: "OrderDocument",
  props: {
    document: Object,
  },
  components: {
    Copyable,
    OrderAdditionalAttributes,
    OrderDocumentType,
  },
  computed: {
    documentReference() {
      return (this.document && this.document.reference) ? this.document.reference : ""
    },
    documentTypeCode() {
      return (this.document && this.document.type_code) ? this.document.type_code : ""
    },
    documentCreationDateTime() {
      return (this.document && this.document.creation_date_time) ? this.document.creation_date_time : ""
    },
    documentSource() {
      return (this.document && this.document.source) ? this.document.source : ""
    },
    documentUrl() {
      return (this.document && this.document.url) ? this.document.url : ""
    },
    hasAdditionalAttributes() {
      return (this.document && this.document.additional_attributes && Object.keys(this.document.additional_attributes).length > 0) ? true : false
    },
    documentAdditionalAttributes() {
      return this.document ? this.document.additional_attributes : {}
    },
  },
}
</script>
<style scoped>
</style>
