<template>
  <v-expansion-panels multiple class="elevation-0">
    <!-- <v-expansion-panel v-for="(delivery,i) in fulfillment.deliveries" :key="'DL-'+i"> -->
    <v-expansion-panel v-for="(delivery,i) in sortedDeliveries" :key="'DL-'+i">
      <order-delivery-header :delivery="delivery" :currencyCode="currencyCode" />
      <order-delivery-content :fulfillment="fulfillment" :delivery="delivery" :currencyCode="currencyCode" />
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import OrderDeliveryHeader from '@/components/order/OrderDeliveryHeader.vue'
import OrderDeliveryContent from '@/components/order/OrderDeliveryContent.vue'

export default {
  name: "OrderDeliveries",
  props: {
    fulfillment: Object,
    currencyCode: String,
  },
  components: {
    OrderDeliveryHeader,
    OrderDeliveryContent,
  },
  computed: {
    sortedDeliveries() {
      let sortedDeliveries = []

      if ( this.fulfillment && this.fulfillment.deliveries ) {
        sortedDeliveries = this.fulfillment.deliveries.sort(function(d1,d2){
          let result = 0

          if ( d1.reference > d2.reference ) {
            // Keep order if reference 1 > reference 2
            result = -1
          } else if ( d1.reference < d2.reference ) {
            // Invert order if reference 1 < reference 2
            result = 1
          }

          return result
        })
      }

      return sortedDeliveries
    }
  },
}
</script>
<style lang="scss" scoped>
</style>
