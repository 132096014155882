import { getCurrencyDecimal } from "@/helpers/currency"

let deliveriesToCSV = (orders) => {
    let content = ""

    content = content + "Merchant Id,"
    content = content + "Channel Type,"
    content = content + "Channel Reference,"
    content = content + "Application Type,"
    content = content + "Application Reference,"
    content = content + "Order Id,"
    content = content + "Order Reference,"
    content = content + "Order Creation Date Time,"
    content = content + "Order Update Date Time,"
    content = content + "Order Type,"
    content = content + "Order Linked Reference,"
    content = content + "Order Status,"
    content = content + "Customer Type,"
    content = content + "Currency,"
    content = content + "Fulfiller Type,"
    content = content + "Fulfiller Reference,"
    content = content + "Fulfiller Partner Reference,"
    content = content + "Fulfillment Reference,"
    content = content + "Delivery Reference,"
    content = content + "Delivery Type,"
    content = content + "Delivery Status,"
    content = content + "Delivery Status Info,"
    content = content + "Delivery Update Date Time,"
    content = content + "Fulfiller Delivery Reference,"
    content = content + "Pickup Point Reference,"
    content = content + "Carrier Name,"
    content = content + "Carrier Method,"
    content = content + "Tracking Nb,"
    content = content + "Tracking References,"
    content = content + "Delivery Total Amount,"
    content = content + "Delivery To Collect Amount,"
    content = content + "Total,"
    content = content + "Total Products,"
    content = content + "Total Shipping Fees,"
    content = content + "Total Ordered,"
    content = content + "Total Cancelled,"
    content = content + "Total Returned,"
    content = content + "Total Adjusted,"
    content = content + "Total Adjusted (post-checkout),"
    content = content + "Delivery estimated date time start,"
    content = content + "Delivery estimated date time end,"
    content = content + "Delivery estimated date time updated ?,"
    content = content + "Delivery country code,"
    content = content + "Delivery province code,"
    content = content + "Delivery postal code,"
    content = content + "Billing country code,"
    content = content + "Billing province code,"
    content = content + "Billing postal code,"
    content = content + "Order CREATED,"
    content = content + "Order VALIDATED,"
    content = content + "Delivery VALIDATED,"
    content = content + "Delivery IN_PREPARATION,"
    content = content + "Delivery IN_TRANSIT,"
    content = content + "Delivery IN_DELIVERY,"
    content = content + "Delivery TO_COLLECT,"
    content = content + "Delivery FULFILLED,"
    content = content + "Delivery CANCELLED,"
    content = content + "Delivery SPLIT\n"

    for (const order of orders) {
        const merchantId = order.source ? order.source.merchant_id : ""
        let channelType = ""
        let channelReference = ""
        let applicationType = ""
        let applicationReference = ""
        const orderId = order.id
        const orderReference = order.reference
        const orderCreationDateTime = order.creation_date_time
        const orderUpdateDateTime = order.update_date_time
        const orderStatus = order.status_code
        const orderType = order.type_code
        const linkedOrderReference = order.linked_order_reference ? order.linked_order_reference : ""
        const customerTypeCode = order.customer && order.customer.type_code ? order.customer.type_code : ""
        const currencyCode = order.currency_code
        const decimal = getCurrencyDecimal(currencyCode)
        
        const billingCountryCode = order.customer && order.customer.billing_address && order.customer.billing_address.country_code ? order.customer.billing_address.country_code : ""
        const billingProvinceCode = order.customer && order.customer.billing_address && order.customer.billing_address.province_code ? order.customer.billing_address.province_code : ""
        const billingPostalCode = order.customer && order.customer.billing_address && order.customer.billing_address.postal_code ? order.customer.billing_address.postal_code : ""

        let orderCreated = ""
        let orderValidated = ""

        if (order.source && order.source.channel) {
            channelType = order.source.channel.type_code
            channelReference = order.source.channel.reference
        }

        if (order.source && order.source.application) {
            applicationType = order.source.application.type_code
            applicationReference = order.source.application.reference
        }

        for (const event of order.events) {
            if (event.type_code == "ORDER_STATUS_UPDATED" && event.payload) {
                if (event.payload.order_status_code == "CREATED") {
                    orderCreated = event.date_time
                }
                
                if (event.payload.order_status_code == "VALIDATED") {
                    orderValidated = event.date_time
                }
            }
        }

        for (const fulfillment of order.fulfillments) {
            const fulfillmentReference = fulfillment.reference
            let fulfillerType = ""
            let fulfillerReference = ""
            let fulfillerPartnerReference = ""

            if ( fulfillment.fulfiller ) {
                fulfillerType = fulfillment.fulfiller.type_code ? fulfillment.fulfiller.type_code : ""
                fulfillerReference = fulfillment.fulfiller.reference ? fulfillment.fulfiller.reference : ""
                fulfillerPartnerReference = fulfillment.fulfiller.partner_reference ? fulfillment.fulfiller.partner_reference : ""
            }

            for (const delivery of fulfillment.deliveries) {
                const deliveryReference = delivery.reference
                const deliveryType = delivery.type_code
                const deliveryStatus = delivery.status_code
                const deliveryStatusInfo = delivery.status_info ? delivery.status_info : ""
                const deliveryUpdateDateTime = delivery.update_date_time
                let carrierName = ""
                let carrierMethod = ""
                let fulfillerDeliveryReference = delivery.fulfiller_delivery_reference
                const pickupPointReference = delivery.pickup_point_reference ? delivery.pickup_point_reference : ""
                let trackingNb = 0
                let trackingReferences = ""
                const deliveryTotalAmount = delivery.total_amount
                const deliveryToCollectAmount = delivery.to_collect_amount

                const totalAmount = delivery.total_amount
                let totalProductsAmount = 0
                let totalShippingFeesAmount = 0
                let totalOrderedAmount = 0
                let totalCancelledAmount = 0
                let totalReturnedAmount = 0
                let totalAdjusted = 0
                let totalAdjustedPostCheckout = 0

                const estimatedDateTimeStart = delivery.estimated_date_time_start ? delivery.estimated_date_time_start : 0
                const estimatedDateTimeEnd = delivery.estimated_date_time_end ? delivery.estimated_date_time_end : 0
                let deliveryEstimatedDateTimeUpdated = "No"
                let deliveryValidated = ""
                let deliveryInPreparation = ""
                let deliveryInTransit = ""
                let deliveryInDelivery = ""
                let deliveryToCollect = ""
                let deliveryFulfilled = ""
                let deliveryCancelled = ""
                let deliverySplit = ""

                const deliveryCountryCode = delivery && delivery.address && delivery.address.country_code ? delivery.address.country_code : ""
                const deliveryProvinceCode = delivery && delivery.address && delivery.address.province_code ? delivery.address.province_code : ""
                const deliveryPostalCode = delivery && delivery.address && delivery.address.postal_code ? delivery.address.postal_code : ""

                if (delivery.carrier) {
                    carrierName = delivery.carrier.name
                    carrierMethod = delivery.carrier.method_reference
                } else if (delivery.additional_attributes) {
                    carrierName = delivery.additional_attributes.carrier_name ? delivery.additional_attributes.carrier_name : ""
                    carrierMethod = delivery.additional_attributes.carrier_method ? delivery.additional_attributes.carrier_method : ""
                }

                if (!fulfillerDeliveryReference && delivery.additional_attributes) {
                    fulfillerDeliveryReference = delivery.additional_attributes.fulfiller_delivery_reference ? delivery.additional_attributes.fulfiller_delivery_reference : ""
                }

                for (const tracking of delivery.trackings) {
                    trackingNb = trackingNb + 1

                    if (trackingNb != 1) {
                        trackingReferences = trackingReferences + " / "
                    }

                    trackingReferences = trackingReferences + tracking.reference
                }

                for (const item of delivery.line_items) {
                    switch (item.type_code) {
                        case "PRODUCT":
                            totalProductsAmount = totalProductsAmount + item.amount
                            break;
                        case "SHIPPING_FEES":
                            totalShippingFeesAmount = totalShippingFeesAmount + item.amount
                            break;
                        default:
                            break;
                    }

                    for (const adjustment of item.adjustments) {
                        totalAdjusted = totalAdjusted + adjustment.amount

                        if (adjustment.change_request_id) {
                            totalAdjustedPostCheckout = totalAdjustedPostCheckout + adjustment.amount
                        }
                    }
                }

                for (const cancellation of delivery.cancellations) {
                    const item = cancellation.line_item

                    totalCancelledAmount = totalCancelledAmount + item.amount

                    for (const adjustment of item.adjustments) {
                        totalAdjusted = totalAdjusted + adjustment.amount

                        if (adjustment.change_request_id) {
                            totalAdjustedPostCheckout = totalAdjustedPostCheckout + adjustment.amount
                        }
                    }
                }

                totalOrderedAmount = totalAmount + totalCancelledAmount + totalAdjustedPostCheckout

                for (const returned of fulfillment.returns) {
                    for (const item of returned.line_items) {
                        if (item.delivery_reference == deliveryReference) {
                            totalReturnedAmount = totalReturnedAmount + item.amount
                        }
                    }
                }

                for (const event of order.events) {

                    if (event.payload && event.payload.fulfillment_reference == fulfillmentReference && event.payload.delivery_reference == deliveryReference) {

                        switch (event.type_code) {
                            case "DELIVERY_ESTIMATED_DATE_UPDATED":
                                deliveryEstimatedDateTimeUpdated = "Yes"
                                break;

                            case "DELIVERY_STATUS_UPDATED":
                                const deliveryStatusCode = event.payload ? event.payload.delivery_status_code : ""
                                const deliveryStatusUpdatedDateTime = event.payload ? event.payload.date_time : ""

                                switch (deliveryStatusCode) {
                                    case "VALIDATED":
                                        deliveryValidated = deliveryStatusUpdatedDateTime
                                        break;
                                    case "IN_PREPARATION":
                                        deliveryInPreparation = deliveryStatusUpdatedDateTime
                                        break;

                                    case "IN_TRANSIT":
                                        deliveryInTransit = deliveryStatusUpdatedDateTime
                                        break;

                                    case "IN_DELIVERY":
                                        deliveryInDelivery = deliveryStatusUpdatedDateTime
                                        break;

                                    case "TO_COLLECT":
                                        deliveryToCollect = deliveryStatusUpdatedDateTime
                                        break;

                                    case "FULFILLED":
                                        deliveryFulfilled = deliveryStatusUpdatedDateTime
                                        break;

                                    case "CANCELLED":
                                        deliveryCancelled = deliveryStatusUpdatedDateTime
                                        break;

                                    case "SPLIT":
                                        deliverySplit = deliveryStatusUpdatedDateTime
                                        break;

                                    default:
                                        break;
                                }
                                break;


                            default:
                                break;
                        }
                    }
                }

                content = content + merchantId + ","
                content = content + channelType + ","
                content = content + channelReference + ","
                content = content + applicationType + ","
                content = content + applicationReference + ","
                content = content + orderId + ","
                content = content + orderReference + ","
                content = content + orderCreationDateTime + ","
                content = content + orderUpdateDateTime + ","
                content = content + orderType + ","
                content = content + linkedOrderReference + ","
                content = content + orderStatus + ","
                content = content + customerTypeCode + ","
                content = content + currencyCode + ","
                content = content + fulfillerType + ","
                content = content + fulfillerReference + ","
                content = content + fulfillerPartnerReference + ","
                content = content + fulfillmentReference + ","
                content = content + deliveryReference + ","
                content = content + deliveryType + ","
                content = content + deliveryStatus + ","
                content = content + deliveryStatusInfo + ","
                content = content + deliveryUpdateDateTime + ","
                content = content + fulfillerDeliveryReference + ","
                content = content + pickupPointReference + ","
                content = content + carrierName + ","
                content = content + carrierMethod + ","
                content = content + trackingNb + ","
                content = content + trackingReferences + ","
                content = content + deliveryTotalAmount.toFixed(decimal) + ","
                content = content + deliveryToCollectAmount.toFixed(decimal) + ","
                content = content + totalAmount.toFixed(decimal) + ","
                content = content + totalProductsAmount.toFixed(decimal) + ","
                content = content + totalShippingFeesAmount.toFixed(decimal) + ","
                content = content + totalOrderedAmount.toFixed(decimal) + ","
                content = content + totalCancelledAmount.toFixed(decimal) + ","
                content = content + totalReturnedAmount.toFixed(decimal) + ","
                content = content + totalAdjusted.toFixed(decimal) + ","
                content = content + totalAdjustedPostCheckout.toFixed(decimal) + ","
                content = content + estimatedDateTimeStart + ","
                content = content + estimatedDateTimeEnd + ","
                content = content + deliveryEstimatedDateTimeUpdated + ","
                content = content + deliveryCountryCode + ","
                content = content + deliveryProvinceCode + ","
                content = content + deliveryPostalCode + ","
                content = content + billingCountryCode + ","
                content = content + billingProvinceCode + ","
                content = content + billingPostalCode + ","
                content = content + orderCreated + ","
                content = content + orderValidated + ","
                content = content + deliveryValidated + ","
                content = content + deliveryInPreparation + ","
                content = content + deliveryInTransit + ","
                content = content + deliveryInDelivery + ","
                content = content + deliveryToCollect + ","
                content = content + deliveryFulfilled + ","
                content = content + deliveryCancelled + ","
                content = content + deliverySplit + "\n"
            }
        }
    }

    return content
}

export {
    deliveriesToCSV,
}