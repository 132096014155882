// Active ISO 4217 currency codes
// https://en.wikipedia.org/wiki/ISO_4217
const currencies = {
    "AED": { "decimal": 2, "name": "United Arab Emirates dirham" },
    "AFN": { "decimal": 2, "name": "Afghan afghani" },
    "ALL": { "decimal": 2, "name": "Albanian lek" },
    "AMD": { "decimal": 2, "name": "Armenian dram" },
    "ANG": { "decimal": 2, "name": "Netherlands Antillean guilder" },
    "AOA": { "decimal": 2, "name": "Angolan kwanza" },
    "ARS": { "decimal": 2, "name": "Argentine peso" },
    "AUD": { "decimal": 2, "name": "Australian dollar" },
    "AWG": { "decimal": 2, "name": "Aruban florin" },
    "AZN": { "decimal": 2, "name": "Azerbaijani manat" },
    "BAM": { "decimal": 2, "name": "Bosnia and Herzegovina convertible mark" },
    "BBD": { "decimal": 2, "name": "Barbados dollar" },
    "BDT": { "decimal": 2, "name": "Bangladeshi taka" },
    "BGN": { "decimal": 2, "name": "Bulgarian lev" },
    "BHD": { "decimal": 3, "name": "Bahraini dinar" },
    "BIF": { "decimal": 0, "name": "Burundian franc" },
    "BMD": { "decimal": 2, "name": "Bermudian dollar" },
    "BND": { "decimal": 2, "name": "Brunei dollar" },
    "BOB": { "decimal": 2, "name": "Boliviano" },
    "BOV": { "decimal": 2, "name": "Bolivian Mvdol (funds code)" },
    "BRL": { "decimal": 2, "name": "Brazilian real" },
    "BSD": { "decimal": 2, "name": "Bahamian dollar" },
    "BTN": { "decimal": 2, "name": "Bhutanese ngultrum" },
    "BWP": { "decimal": 2, "name": "Botswana pula" },
    "BYN": { "decimal": 2, "name": "Belarusian ruble" },
    "BZD": { "decimal": 2, "name": "Belize dollar" },
    "CAD": { "decimal": 2, "name": "Canadian dollar" },
    "CDF": { "decimal": 2, "name": "Congolese franc" },
    "CHE": { "decimal": 2, "name": "WIR euro (complementary currency)" },
    "CHF": { "decimal": 2, "name": "Swiss franc" },
    "CHW": { "decimal": 2, "name": "WIR franc (complementary currency)" },
    "CLF": { "decimal": 4, "name": "Unidad de Fomento (funds code)" },
    "CLP": { "decimal": 0, "name": "Chilean peso" },
    "COP": { "decimal": 2, "name": "Colombian peso" },
    "COU": { "decimal": 2, "name": "Unidad de Valor Real (UVR) (funds code)[8]" },
    "CRC": { "decimal": 2, "name": "Costa Rican colon" },
    "CUC": { "decimal": 2, "name": "Cuban convertible peso" },
    "CUP": { "decimal": 2, "name": "Cuban peso" },
    "CVE": { "decimal": 2, "name": "Cape Verdean escudo" },
    "CZK": { "decimal": 2, "name": "Czech koruna" },
    "DJF": { "decimal": 0, "name": "Djiboutian franc" },
    "DKK": { "decimal": 2, "name": "Danish krone" },
    "DOP": { "decimal": 2, "name": "Dominican peso" },
    "DZD": { "decimal": 2, "name": "Algerian dinar" },
    "EGP": { "decimal": 2, "name": "Egyptian pound" },
    "ERN": { "decimal": 2, "name": "Eritrean nakfa" },
    "ETB": { "decimal": 2, "name": "Ethiopian birr" },
    "EUR": { "decimal": 2, "name": "Euro" },
    "FJD": { "decimal": 2, "name": "Fiji dollar" },
    "FKP": { "decimal": 2, "name": "Falkland Islands pound" },
    "GBP": { "decimal": 2, "name": "Pound sterling" },
    "GEL": { "decimal": 2, "name": "Georgian lari" },
    "GHS": { "decimal": 2, "name": "Ghanaian cedi" },
    "GIP": { "decimal": 2, "name": "Gibraltar pound" },
    "GMD": { "decimal": 2, "name": "Gambian dalasi" },
    "GNF": { "decimal": 0, "name": "Guinean franc" },
    "GTQ": { "decimal": 2, "name": "Guatemalan quetzal" },
    "GYD": { "decimal": 2, "name": "Guyanese dollar" },
    "HKD": { "decimal": 2, "name": "Hong Kong dollar" },
    "HNL": { "decimal": 2, "name": "Honduran lempira" },
    "HRK": { "decimal": 2, "name": "Croatian kuna" },
    "HTG": { "decimal": 2, "name": "Haitian gourde" },
    "HUF": { "decimal": 2, "name": "Hungarian forint" },
    "IDR": { "decimal": 0, "name": "Indonesian rupiah" },
    "ILS": { "decimal": 2, "name": "Israeli new shekel" },
    "INR": { "decimal": 2, "name": "Indian rupee" },
    "IQD": { "decimal": 3, "name": "Iraqi dinar" },
    "IRR": { "decimal": 2, "name": "Iranian rial" },
    "ISK": { "decimal": 0, "name": "Icelandic króna (plural: krónur)" },
    "JMD": { "decimal": 2, "name": "Jamaican dollar" },
    "JOD": { "decimal": 3, "name": "Jordanian dinar" },
    "JPY": { "decimal": 0, "name": "Japanese yen" },
    "KES": { "decimal": 2, "name": "Kenyan shilling" },
    "KGS": { "decimal": 2, "name": "Kyrgyzstani som" },
    "KHR": { "decimal": 2, "name": "Cambodian riel" },
    "KMF": { "decimal": 0, "name": "Comoro franc" },
    "KPW": { "decimal": 2, "name": "North Korean won" },
    "KRW": { "decimal": 0, "name": "South Korean won" },
    "KWD": { "decimal": 3, "name": "Kuwaiti dinar" },
    "KYD": { "decimal": 2, "name": "Cayman Islands dollar" },
    "KZT": { "decimal": 2, "name": "Kazakhstani tenge" },
    "LAK": { "decimal": 2, "name": "Lao kip" },
    "LBP": { "decimal": 2, "name": "Lebanese pound" },
    "LKR": { "decimal": 2, "name": "Sri Lankan rupee" },
    "LRD": { "decimal": 2, "name": "Liberian dollar" },
    "LSL": { "decimal": 2, "name": "Lesotho loti" },
    "LYD": { "decimal": 3, "name": "Libyan dinar" },
    "MAD": { "decimal": 2, "name": "Moroccan dirham" },
    "MDL": { "decimal": 2, "name": "Moldovan leu" },
    "MGA": { "decimal": 2, "name": "Malagasy ariary" },
    "MKD": { "decimal": 2, "name": "North Macedonian denar" },
    "MMK": { "decimal": 2, "name": "Myanmar kyat" },
    "MNT": { "decimal": 2, "name": "Mongolian tögrög" },
    "MOP": { "decimal": 2, "name": "Macanese pataca" },
    "MRU": { "decimal": 2, "name": "Mauritanian ouguiya" },
    "MUR": { "decimal": 2, "name": "Mauritian rupee" },
    "MVR": { "decimal": 2, "name": "Maldivian rufiyaa" },
    "MWK": { "decimal": 2, "name": "Malawian kwacha" },
    "MXN": { "decimal": 2, "name": "Mexican peso" },
    "MXV": { "decimal": 2, "name": "Mexican Unidad de Inversion (UDI) (funds code)" },
    "MYR": { "decimal": 2, "name": "Malaysian ringgit" },
    "MZN": { "decimal": 2, "name": "Mozambican metical" },
    "NAD": { "decimal": 2, "name": "Namibian dollar" },
    "NGN": { "decimal": 2, "name": "Nigerian naira" },
    "NIO": { "decimal": 2, "name": "Nicaraguan córdoba" },
    "NOK": { "decimal": 2, "name": "Norwegian krone" },
    "NPR": { "decimal": 2, "name": "Nepalese rupee" },
    "NZD": { "decimal": 2, "name": "New Zealand dollar" },
    "OMR": { "decimal": 3, "name": "Omani rial" },
    "PAB": { "decimal": 2, "name": "Panamanian balboa" },
    "PEN": { "decimal": 2, "name": "Peruvian sol" },
    "PGK": { "decimal": 2, "name": "Papua New Guinean kina" },
    "PHP": { "decimal": 2, "name": "Philippine peso[12]" },
    "PKR": { "decimal": 2, "name": "Pakistani rupee" },
    "PLN": { "decimal": 2, "name": "Polish złoty" },
    "PYG": { "decimal": 0, "name": "Paraguayan guaraní" },
    "QAR": { "decimal": 2, "name": "Qatari riyal" },
    "RON": { "decimal": 2, "name": "Romanian leu" },
    "RSD": { "decimal": 2, "name": "Serbian dinar" },
    "CNY": { "decimal": 2, "name": "Renminbi[13]" },
    "RUB": { "decimal": 2, "name": "Russian ruble" },
    "RWF": { "decimal": 0, "name": "Rwandan franc" },
    "SAR": { "decimal": 2, "name": "Saudi riyal" },
    "SBD": { "decimal": 2, "name": "Solomon Islands dollar" },
    "SCR": { "decimal": 2, "name": "Seychelles rupee" },
    "SDG": { "decimal": 2, "name": "Sudanese pound" },
    "SEK": { "decimal": 2, "name": "Swedish krona (plural: kronor)" },
    "SGD": { "decimal": 2, "name": "Singapore dollar" },
    "SHP": { "decimal": 2, "name": "Saint Helena pound" },
    "SLL": { "decimal": 2, "name": "Sierra Leonean leone" },
    "SLE": { "decimal": 2, "name": "Sierra Leonean leone" },
    "SOS": { "decimal": 2, "name": "Somali shilling" },
    "SRD": { "decimal": 2, "name": "Surinamese dollar" },
    "SSP": { "decimal": 2, "name": "South Sudanese pound" },
    "STN": { "decimal": 2, "name": "São Tomé and Príncipe dobra" },
    "SVC": { "decimal": 2, "name": "Salvadoran colón" },
    "SYP": { "decimal": 2, "name": "Syrian pound" },
    "SZL": { "decimal": 2, "name": "Swazi lilangeni" },
    "THB": { "decimal": 2, "name": "Thai baht" },
    "TJS": { "decimal": 2, "name": "Tajikistani somoni" },
    "TMT": { "decimal": 2, "name": "Turkmenistan manat" },
    "TND": { "decimal": 3, "name": "Tunisian dinar" },
    "TOP": { "decimal": 2, "name": "Tongan paʻanga" },
    "TRY": { "decimal": 2, "name": "Turkish lira" },
    "TTD": { "decimal": 2, "name": "Trinidad and Tobago dollar" },
    "TWD": { "decimal": 2, "name": "New Taiwan dollar" },
    "TZS": { "decimal": 2, "name": "Tanzanian shilling" },
    "UAH": { "decimal": 2, "name": "Ukrainian hryvnia" },
    "UGX": { "decimal": 0, "name": "Ugandan shilling" },
    "USD": { "decimal": 2, "name": "United States dollar" },
    "USN": { "decimal": 2, "name": "United States dollar (next day) (funds code)" },
    "UYI": { "decimal": 0, "name": "Uruguay Peso en Unidades Indexadas (URUIURUI) (funds code)" },
    "UYU": { "decimal": 2, "name": "Uruguayan peso" },
    "UYW": { "decimal": 4, "name": "Unidad previsional[15]" },
    "UZS": { "decimal": 2, "name": "Uzbekistan sum" },
    "VED": { "decimal": 2, "name": "Venezuelan digital bolívar[16]" },
    "VES": { "decimal": 2, "name": "Venezuelan sovereign bolívar[12]" },
    "VND": { "decimal": 0, "name": "Vietnamese đồng" },
    "VUV": { "decimal": 0, "name": "Vanuatu vatu" },
    "WST": { "decimal": 2, "name": "Samoan tala" },
    "XAF": { "decimal": 0, "name": "CFA franc BEAC" },
    "XAG": { "decimal": 0, "name": "Silver (one troy ounce)" },
    "XAU": { "decimal": 0, "name": "Gold (one troy ounce)" },
    "XBA": { "decimal": 0, "name": "European Composite Unit (EURCO) (bond market unit)" },
    "XBB": { "decimal": 0, "name": "European Monetary Unit (E.M.U.-6) (bond market unit)" },
    "XBC": { "decimal": 0, "name": "European Unit of Account 9 (E.U.A.-9) (bond market unit)" },
    "XBD": { "decimal": 0, "name": "European Unit of Account 17 (E.U.A.-17) (bond market unit)" },
    "XCD": { "decimal": 2, "name": "East Caribbean dollar" },
    "XDR": { "decimal": 0, "name": "Special drawing rights" },
    "XOF": { "decimal": 0, "name": "CFA franc BCEAO" },
    "XPD": { "decimal": 0, "name": "Palladium (one troy ounce)" },
    "XPF": { "decimal": 0, "name": "CFP franc (franc Pacifique)" },
    "XPT": { "decimal": 0, "name": "Platinum (one troy ounce)" },
    "XSU": { "decimal": 0, "name": "SUCRE" },
    "XTS": { "decimal": 0, "name": "Code reserved for testing" },
    "XUA": { "decimal": 0, "name": "ADB Unit of Account" },
    "XXX": { "decimal": 0, "name": "No currency" },
    "YER": { "decimal": 2, "name": "Yemeni rial" },
    "ZAR": { "decimal": 2, "name": "South African rand" },
    "ZMW": { "decimal": 2, "name": "Zambian kwacha" },
    "ZWL": { "decimal": 2, "name": "Zimbabwean dollar" }
}

const getCurrencyDecimal = (code) => {
    let decimal = 0

    if (code && currencies[code]) {
        decimal = currencies[code].decimal
    }

    return decimal
}

export {
    currencies,
    getCurrencyDecimal
}