import colors from 'vuetify/lib/util/colors'

export default {
  primary: colors.blue.darken1,
  secondary: colors.red.base,
  themeAppBar: '#151515',
  themeBackground: colors.grey.darken4,
  themeCard: colors.grey.darken3,
  themeChip1: colors.blue.darken1,
  themeChip2: colors.grey.darken3,
  themeChip3: '#303030',
  themeChip4: '#505050',
  themeChip5: colors.green.base,
  // themeDivider: colors.grey.darken4,
  themePanelHeader: '#505050',
  themePanelContent: colors.grey.darken3,
  themePanelContent2: colors.grey.darken2,
  themeSearchBar: colors.grey.darken3,
  themeStatusOrder: colors.blue.darken1,
  themeStatusDelivery: colors.amber.darken1,
  themeStatusTransaction: colors.green.darken1,
  themeStatusRequest: colors.blue.darken1,
  themeStatusApproved: colors.green.darken1,
  themeStatusRejected: colors.red.darken1,
  themeTypeOrder: '#303030',
  themeText1: colors.shades.white,
  themeText2: colors.grey.darken1,
  // themeText3: colors.grey.darken2,
  themeText3: '#808080',
}
