const envs = [
  process.env.VUE_APP_PREPROD_ENV,
  process.env.VUE_APP_PROD_ENV
]

const isEnvSupported = (env) => {
  let result = false
  if ( envs.includes(env) ) {
    result = true
  }
  return result
}

export {
  envs,
  isEnvSupported
}
