<template>
  <v-chip v-show="typeCode" small label :color="typeColor" text-color="themeText2">
    <v-icon left small color="themeText2">{{typeIcon}}</v-icon>
    <span class="font-weight-bold">{{typeCode}}</span>
  </v-chip>
</template>
<script>
export default {
  name: "OrderType",
  props: {
    code: String,
  },
  computed: {
    typeCode() {
      return this.code
    },
    typeColor() {
      let typeCode = this.code
      let typeColor = ""

      switch (typeCode) {
        case "STANDARD":
        case "RESHIPMENT":
        case "REPLACEMENT":
        typeColor = "themeTypeOrder"
        break
        default:
        typeColor = "themeTypeOrder"
        break
      }

      return typeColor
    },
    typeIcon() {
      let typeCode = this.code
      let typeIcon = ""

      switch (typeCode) {
        case "STANDARD":
        typeIcon = "mdi-cube-outline"
        break
        case "RESHIPMENT":
        typeIcon = "mdi-cube-send"
        break
        case "REPLACEMENT":
        typeIcon = "mdi mdi-autorenew"
        break
        case "EXCHANGE":
        typeIcon = "mdi mdi-swap-horizontal"
        break

        default:
        typeIcon = "mdi-cube-outline"
        break
      }

      return typeIcon
    }
  },
}
</script>
<style lang="scss" scoped>
</style>
