<template>
  <v-row no-gutters align="end">
    <v-col cols="6" md="4">
      <div class="text-h6 themeText3--text">
        <v-icon color="themeText3">mdi-text-box-outline</v-icon>
        <span> Order</span>
      </div>
      <div class="font-weight-bold text-no-wrap text-truncate">
        <copyable :text="order.reference"/>
      </div>
      <div class="caption text-no-wrap text-truncate">
        <copyable :text="order.id"/>
      </div>
      <order-type :code="order.type_code"/>
      <div v-show="order.linked_order_reference" class="caption text-no-wrap text-truncate themeText2--text">
        <span>Linked order </span><copyable :text="order.linked_order_reference"/>
      </div>
      <div class="caption themeText2--text">
        <span>Revision #<strong>{{order.revision}}</strong></span>
      </div>
    </v-col>
    <v-col cols="6"  md="4" class="text-right pr-md-2">
      <order-status :code="order.status_code"/>
      <div class="caption themeText1--text">{{order.creation_date_time}}</div>
      <div class="caption themeText2--text">{{order.update_date_time}}</div>
    </v-col>
  </v-row>
</template>
<script>
import Copyable from '@/components/common/Copyable.vue'
import OrderStatus from '@/components/order/OrderStatus.vue'
import OrderType from '@/components/order/OrderType.vue'

export default {
  name: "OrderHeader",
  props: {
    order: Object,
  },
  components: {
    Copyable,
    OrderStatus,
    OrderType,
  },
}
</script>
<style lang="scss" scoped>
</style>
