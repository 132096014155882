<template>
  <v-menu v-model="menu" :close-on-content-click="false" offset-y bottom left transition="scale-transition" origin="top right">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="themeText2" class="text-none" small text v-bind="attrs" v-on="on">
        <v-icon small class="pr-2" :color="iconColor">{{icon}}</v-icon>Requester
      </v-btn>
    </template>
    <v-card class="themeCard">
      <div class="pa-2 py-4 themePanelHeader themeText2--text text-center body-2">Select the requester type to focus on</div>
      <v-list style="max-height: 270px" class="overflow-y-auto themeCard" :dark="themeIsDark">
        <v-list-item>
          <v-list-item-action>
            <v-switch inset dense v-model="requesterCustomer" @change="toggleRequesterType()" color="primary"></v-switch>
          </v-list-item-action>
          <v-list-item-title @click="toggleRequesterType('CUSTOMER')">
            <request-requester-type code="CUSTOMER"/>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-switch inset dense v-model="requesterFulfiller" @change="toggleRequesterType()" color="primary"></v-switch>
          </v-list-item-action>
          <v-list-item-title @click="toggleRequesterType('FULFILLER')">
            <request-requester-type code="FULFILLER"/>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-switch inset dense v-model="requesterMerchant" @change="toggleRequesterType()" color="primary"></v-switch>
          </v-list-item-action>
          <v-list-item-title @click="toggleRequesterType('MERCHANT')">
            <request-requester-type code="MERCHANT"/>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-switch inset dense v-model="requesterOrderManager" @change="toggleRequesterType()" color="primary"></v-switch>
          </v-list-item-action>
          <v-list-item-title @click="toggleRequesterType('ORDER_MANAGER')">
            <request-requester-type code="ORDER_MANAGER"/>
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <div class="themePanelHeader pa-2">
        <v-btn color="themeText2" text block class="text-none" @click="removeAllTypeFilters()">Remove all requester filters</v-btn>
      </div>
    </v-card>
  </v-menu>
</template>
<script>
import RequestRequesterType from '@/components/request/RequestRequesterType.vue'
import {mapGetters,mapActions} from "vuex"
import {MERCHANT_NS} from "@/store/modules/merchant/constants"
import {REQUEST_NS} from "@/store/modules/request/constants"
import {THEME_NS} from "@/store/modules/theme/constants"

export default {
  name: "RequestsMenuRequesterTypeCode",
  components: {
    RequestRequesterType,
  },
  data: () => ({
    menu: false,
    requesterTypeCodeQuery: "",
    requesterCustomer: false,
    requesterFulfiller: false,
    requesterMerchant: false,
    requesterOrderManager: false,
  }),
  created() {
    if (this.searchQueryParams && this.searchQueryParams['requester_type_code']) {
      this.requesterTypeCodeQuery = this.searchQueryParams['requester_type_code']
      let requesterTypeCodes = this.requesterTypeCodeQuery.split(',')

      for (let type of requesterTypeCodes) {
        switch (type) {
          case "CUSTOMER":
          this.requesterCustomer = !this.requesterCustomer
          break;
          case "FULFILLER":
          this.requesterFulfiller = !this.requesterFulfiller
          break;
          case "MERCHANT":
          this.requesterMerchant = !this.requesterMerchant
          break;
          case "ORDER_MANAGER":
          this.requesterOrderManager = !this.requesterOrderManager
          break;
          default:
          break;
        }
      }
    }
  },
  computed: {
    ...mapGetters(THEME_NS, ['themeIsDark']),
    ...mapGetters(MERCHANT_NS, [
      'merchant'
    ]),
    ...mapGetters(REQUEST_NS, [
      'searchQueryParams'
    ]),
    icon() {
      return (this.requesterTypeCodeQuery == "") ? 'mdi-square-rounded-outline' : 'mdi-square-rounded'
    },
    iconColor() {
      return (this.requesterTypeCodeQuery == "") ? 'themeText2' : 'primary'
    },
  },
  methods: {
    ...mapActions(REQUEST_NS, ['fetchRequests','addSearchQueryParam','removeSearchQueryParam','updateRequestsURL']),
    removeAllTypeFilters() {
      this.requesterCustomer = false
      this.requesterFulfiller = false
      this.requesterMerchant = false
      this.requesterOrderManager = false
      this.updateTypeCodeQuery()
      this.updateSearchResults()
    },
    toggleRequesterType(type) {
      switch (type) {
        case "CUSTOMER":
        this.requesterCustomer = !this.requesterCustomer
        break;
        case "FULFILLER":
        this.requesterFulfiller = !this.requesterFulfiller
        break;
        case "MERCHANT":
        this.requesterMerchant = !this.requesterMerchant
        break;
        case "ORDER_MANAGER":
        this.requesterOrderManager = !this.requesterOrderManager
        break;
        default:
        break;
      }
      this.updateTypeCodeQuery()
      this.updateSearchResults()
    },
    updateTypeCodeQuery() {
      this.requesterTypeCodeQuery = ""

      if (this.requesterCustomer) {
        this.requesterTypeCodeQuery += "CUSTOMER"
        if (this.requesterFulfiller || this.requesterMerchant || this.requesterOrderManager) {
          this.requesterTypeCodeQuery += ","
        }
      }

      if (this.requesterFulfiller) {
        this.requesterTypeCodeQuery += "FULFILLER"
        if (this.requesterMerchant || this.requesterOrderManager) {
          this.requesterTypeCodeQuery += ","
        }
      }

      if (this.requesterMerchant) {
        this.requesterTypeCodeQuery += "MERCHANT"
        if (this.requesterOrderManager) {
          this.requesterTypeCodeQuery += ","
        }
      }

      if (this.requesterOrderManager) {
        this.requesterTypeCodeQuery += "ORDER_MANAGER"
      }

    },
    updateSearchResults() {
      if ( this.requesterTypeCodeQuery ) {
        this.addSearchQueryParam({'key':'requester_type_code','value':this.requesterTypeCodeQuery})
      } else {
        this.removeSearchQueryParam('requester_type_code')
      }

      this.updateRequestsURL({'vue':this})
      this.fetchRequests({'merchantId':this.merchant.id, 'params':this.searchQueryParams})
    }
  },
}
</script>
