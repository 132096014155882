<template>
  <div class="">
    <app-bar/>
    <v-container fluid>
      <v-row class="text-center">
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              <v-hover v-slot="{ hover }">
                <v-card :elevation="hover ? 4 : 2" @click="gotoTestOrder()" class="fill-height themeCard">
                  <v-card-text>
                    <div>
                      <v-icon size="48" color="themeText2">mdi-text-box-outline</v-icon>
                    </div>
                    <div class="title font-weight-thin text-center themeText1--text">Order</div>
                    <div class="caption themeText1--text">Test order display.</div>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
            <v-col cols="6">
              <v-hover v-slot="{ hover }">
                <v-card :elevation="hover ? 4 : 2" @click="gotoTestChangeRequests()" class="fill-height themeCard">
                  <v-card-text>
                    <div>
                      <v-icon size="48" color="themeText2">mdi-square-edit-outline</v-icon>
                    </div>
                    <div class="title font-weight-thin text-center themeText1--text">Change request</div>
                    <div class="caption themeText1--text">Test request display.</div>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="pt-2 pb-8">
        <app-footer />
      </div>
    </v-container>
  </div>
</template>

<script>
import AppBar from '@/components/app/AppBar.vue'
import AppFooter from '@/components/app/AppFooter.vue'

export default {
  name: 'MerchantPage',
  components: {
    AppBar,
    AppFooter,
  },
  methods: {
    gotoTestOrder() {
      this.$router.push({ name: 'OrderTest' })
    },
    gotoTestChangeRequest() {
      this.$router.push({ name: 'ChangeRequestTest' })
    },
  }
}
</script>
