<template>
  <v-chip small label :color="statusColor" text-color="white">
    <v-icon left small>{{ statusIcon }}</v-icon>
    <span class="font-weight-bold">{{ statusCode }}</span>
  </v-chip>
</template>
<script>
export default {
  name: "OrderTransactionStatus",
  props: {
    code: String,
  },
  computed: {
    statusCode() {
      let statusCode = this.code
      if (statusCode) {
        statusCode = statusCode.split('_').join(' ')
      }
      return statusCode
    },
    statusColor() {
      let statusCode = this.code
      let statusColor = ""

      switch (statusCode) {
        case "AUTHORIZE_PENDING":
        case "AUTHORIZE_SUCCESS":
        case "CAPTURE_PENDING":
        case "CAPTURE_SUCCESS":
        case "REFUND_PENDING":
        case "REFUND_SUCCESS":
        case "REFUND_CUSTOMER":
        case "REFUND_CUSTOMER_SUCCESS":
        case "REFUND_MANUAL":
        case "REFUND_MANUAL_APPROVED":
        case "REFUND_STORE_CREDIT_PENDING":
        case "REFUND_STORE_CREDIT_SUCCESS":
        case "REJECT_SUCCESS":
        case "VOID_PENDING":
        case "VOID_SUCCESS":
          statusColor = "themeStatusTransaction"
          break

        case "AUTHORIZE_ERROR":
        case "CAPTURE_ERROR":
        case "REFUND_ERROR":
        case "REFUND_MANUAL_REJECTED":
        case "VOID_ERROR":
          statusColor = "themeStatusRejected"
          break

        default:
          statusColor = ""
          break
      }

      return statusColor
    },
    statusIcon() {
      let statusCode = this.code
      let statusIcon = ""

      switch (statusCode) {
        case "AUTHORIZE_PENDING":
        case "CAPTURE_PENDING":
        case "REFUND_PENDING":
        case "VOID_PENDING":
          statusIcon = "mdi-checkbox-blank-circle-outline"
          break
        case "AUTHORIZE_SUCCESS":
        case "CAPTURE_SUCCESS":
        case "REFUND_CUSTOMER_SUCCESS":
        case "REFUND_MANUAL_APPROVED":
        case "REFUND_SUCCESS":
        case "REJECT_SUCCESS":
        case "VOID_SUCCESS":
          statusIcon = "mdi-check-circle-outline"
          break
        case "AUTHORIZE_ERROR":
        case "CAPTURE_ERROR":
        case "REFUND_ERROR":
        case "REFUND_MANUAL":
        case "REFUND_MANUAL_REJECTED":
        case "VOID_ERROR":
          statusIcon = "mdi-alert-circle-outline"
          break

        default:
          statusIcon = "mdi-help-circle-outline"
          break
      }

      return statusIcon
    }
  },
}
</script>
<style scoped></style>
