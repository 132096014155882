<template>
  <v-dialog v-model="dialog" transition="dialog-bottom-transition" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn :disabled="!requestExists" color="themeText2" class="text-none" small text v-bind="attrs" v-on="on"><v-icon small class="pr-2">mdi-code-braces</v-icon>Json</v-btn>
    </template>
    <v-card class="themeCard">
      <v-toolbar flat dense color="themePanelHeader">
        <span class="themeText1--text text-h6">{{request.type_code}} / {{request.id}}</span>
        <v-spacer></v-spacer>
        <v-btn text icon @click="dialog = false"><v-icon small color="themeText3">mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text class="themeText1--text">
        <pre>{{requestJson}}</pre>
        <v-btn fab absolute top right class="copy-btn" small color="transparent" elevation="0" @click="copyToClipboard">
          <v-icon color="themeText3">{{icon}}</v-icon>
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import {mapGetters} from "vuex"
import {REQUEST_NS} from "@/store/modules/request/constants"

export default {
  name: "RequestDialogJson",
  data: () => ({
    dialog: false,
    icon: "mdi-clipboard-text-outline",
    defaultIcon: "mdi-clipboard-text-outline",
    copiedIcon: "mdi-clipboard-check-outline",
  }),
  computed: {
    ...mapGetters(REQUEST_NS, [
      'request',
      'requestJson'
    ]),
    requestExists() {
      let result = false
      if ( this.request && this.request.id ) {
        result = true
      }
      return result
    }
  },
  methods: {
    copyToClipboard() {
      this.icon = this.copiedIcon
      navigator.clipboard.writeText(this.requestJson)
      setTimeout(() => {  this.icon = this.defaultIcon }, 250)
    }
  }
}
</script>
<style scoped>
.copy-btn {
  margin-top: 80px;
}
</style>
