<template>
  <v-list-item class="pl-8 themeText1--text" three-line>
    <v-row>
      <v-col>
        <copyable :text="adjustmentReference" />
        <div class="caption">{{adjustmentTypeCode}}</div>
        <div class="caption themeText2--text">{{adjustmentReasonCode}}</div>
        <div class="caption themeText2--text">{{adjustmentMessage}}</div>
      </v-col>
      <v-col class="text-right">
        <div class="caption">{{adjustmentAmount}}  {{currencyCode}}</div>
        <div class="caption themeText2--text">
          <copyable :text="adjustmentChangeRequestId" />
        </div>
      </v-col>
    </v-row>
  </v-list-item>
</template>
<script>
import {getCurrencyDecimal} from "@/helpers/currency"
import Copyable from '@/components/common/Copyable.vue'

export default {
  name: "OrderAdjustmentItem",
  props: {
    adjustment: Object,
    currencyCode: String,
  },
  components: {
    Copyable,
  },
  computed: {
    adjustmentReference() {
      return this.adjustment ? this.adjustment.reference : ""
    },
    adjustmentAmount() {
      const currencyCode = this.currencyCode
      const decimal = getCurrencyDecimal(currencyCode)
      return this.adjustment && this.adjustment.amount >=0 ? this.adjustment.amount.toFixed(decimal) : (0).toFixed(decimal)
    },
    adjustmentTypeCode() {
      return this.adjustment ? this.adjustment.type_code : ""
    },
    adjustmentReasonCode() {
      return this.adjustment ? this.adjustment.reason_code : ""
    },
    adjustmentMessage() {
      return this.adjustment ? this.adjustment.message : ""
    },
    adjustmentChangeRequestId() {
      return this.adjustment ? this.adjustment.change_request_id : ""
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
