<template>
  <v-chip small label :color="typeColor" text-color="themeText2">
    <v-icon left small color="themeText2">{{ typeIcon }}</v-icon>
    <span class="font-weight-bold">{{ typeCode }}</span>
  </v-chip>
</template>
<script>
export default {
  name: "RequestType",
  props: {
    code: String,
  },
  computed: {
    typeCode() {
      let typeCode = this.code
      if (typeCode) {
        typeCode = typeCode.split('_').join(' ')
      }
      return typeCode
    },
    typeColor() {
      let typeCode = this.code
      let typeColor = ""

      switch (typeCode) {
        case "ORDER_CANCELLATION":
        case "ITEMS_CANCELLATION":
        case "FULFILLMENT_ADJUSTMENT":
        case "ITEMS_ADJUSTMENT":
        case "REFUND":
        case "REFUND_APPROVED":
        case "REFUND_CUSTOMER":
        case "REFUND_MANUAL":
        case "RETURN":
        case "RETURN_APPROVED":
          typeColor = "themeChip3"
          break
        default:
          typeColor = "themeChip3"
          break
      }

      return typeColor
    },
    typeIcon() {
      let typeCode = this.code
      let typeIcon = ""

      switch (typeCode) {
        case "ORDER_CANCELLATION":
        case "ITEMS_CANCELLATION":
          typeIcon = "mdi-cancel"
          break
        case "FULFILLMENT_ADJUSTMENT":
        case "ITEMS_ADJUSTMENT":
          typeIcon = "mdi-sale"
          break
        case "REFUND":
        case "REFUND_APPROVED":
        case "REFUND_CUSTOMER":
        case "REFUND_MANUAL":
          typeIcon = "mdi-credit-card-outline"
          break
        case "RETURN":
        case "RETURN_APPROVED":
          typeIcon = "mdi-arrow-left-bold-outline"
          break

        default:
          typeIcon = "mdi-help-circle-outline"
          break
      }

      return typeIcon
    },
  },
}
</script>
<style lang="scss" scoped></style>
