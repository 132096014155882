
const mutations = {
  updateRequests(state, requests) {
    state.requests = requests
  },
  addRequests(state, requests) {
    state.requests.push(...requests)
  },
  updateTotalNumber(state, totalNumber) {
    state.totalNumber = totalNumber
  },
  updateShowingNumber(state, showingNumber) {
    state.showingNumber = showingNumber
  },
  increaseShowingNumber(state, number) {
    state.showingNumber += number
  },
  updateRequest(state, request) {
    state.request = request
  },
  updateSearchQueryParams(state, queryParams) {
    let searchQueryParams = {}

    if ( queryParams ) {
      if ( queryParams['id'] ) {
        searchQueryParams['id'] = queryParams['id']
      }

      if ( queryParams['type_code'] ) {
        searchQueryParams['type_code'] = queryParams['type_code']
      }

      if ( queryParams['requester_type_code'] ) {
        searchQueryParams['requester_type_code'] = queryParams['requester_type_code']
      }

      if ( queryParams['status_code'] ) {
        searchQueryParams['status_code'] = queryParams['status_code']
      }

      if ( queryParams['order_id'] ) {
        searchQueryParams['order_id'] = queryParams['order_id']
      }

      if ( queryParams['order_reference'] ) {
        searchQueryParams['order_reference'] = queryParams['order_reference']
      }

      if ( queryParams['creation_date_time_start'] ) {
        searchQueryParams['creation_date_time_start'] = queryParams['creation_date_time_start']
      }

      if ( queryParams['creation_date_time_end'] ) {
        searchQueryParams['creation_date_time_end'] = queryParams['creation_date_time_end']
      }

      if ( queryParams['customer_reference'] ) {
        searchQueryParams['customer_reference'] = queryParams['customer_reference']
      }
    }

    state.searchQueryParams = searchQueryParams
  },
  updateSearchQueryParamsUrl(state) {
    state.searchQueryParamsURL = Object.keys(state.searchQueryParams).map(key => {
      return (
        encodeURIComponent(key) + '=' + encodeURIComponent(state.searchQueryParams[key])
      )
    }).join('&')
  },
  addSearchQueryParam(state, {key,value}) {
    if ( key && value ) {
      switch (key) {
        case 'id':
        case 'type_code':
        case 'requester_type_code':
        case 'status_code':
        case 'order_id':
        case 'order_reference':
        case 'creation_date_time_start':
        case 'creation_date_time_end':
        case 'customer_reference':
        state.searchQueryParams[key] = value
        break;
        default:
        break;
      }
    }
  },
  removeSearchQueryParam(state, key){
    if ( key && state.searchQueryParams.hasOwnProperty(key)) {
      delete state.searchQueryParams[key]
    }
  },
  updateErrorMessage(state,message) {
    state.errorMessage = message
  },
  toggleLoadingFlag(state) {
    state.loadingFlag = !state.loadingFlag
  },
};

export default mutations;
