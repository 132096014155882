<template>
  <v-container>
    <v-row class="px-4">
      <div class="caption themeText2--text text-left">
        <span>Version </span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span class="clickable primary--text" @click="forceRefresh()" v-bind="attrs" v-on="on">{{version}}</span>
          </template>
          <span><v-icon left color="white">mdi-update</v-icon>Click to get the latest version</span>
        </v-tooltip>
      </div>
      <v-spacer></v-spacer>
      <div class="caption themeText2--text">
        <v-icon small color="primary">mdi-code-tags</v-icon>
        <span> with </span>
        <v-icon small color="secondary">mdi-heart</v-icon>
        <span> by </span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span class="clickable primary--text" @click="contactUs()" v-bind="attrs" v-on="on">OneOM team</span>
          </template>
          <span><v-icon left color="white">mdi-email-edit</v-icon>Send an email to the OneOM team</span>
        </v-tooltip>
      </div>
      <v-spacer></v-spacer>
      <div class="caption themeText2--text">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span class="clickable primary--text" @click="gotoTheDocumentation()" v-bind="attrs" v-on="on">Documentation</span>
          </template>
          <span><v-icon left color="white">mdi-book-open</v-icon>Go to the OneOM documentation</span>
        </v-tooltip>
      </div>
    </v-row>
  </v-container>
</template>
<script>

export default {
  name: "AppFooter",
  computed: {
    version() {
      return process.env.PACKAGE_VERSION
    }
  },
  methods: {
    forceRefresh() {
      window.location.reload(true)
    },
    contactUs() {
      window.open("mailto:oneom@decathlon.net")
    },
    gotoTheDocumentation() {
      window.open("https://docapi.decathlon.net/oneom")
    },
  }
}
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
</style>
