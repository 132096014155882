<template>
  <div>
    <div class="text-h6 py-3 themeText3--text">
      <v-icon color="themeText3">mdi-cancel</v-icon>
      <span> Cancellations</span>
    </div>
    <v-expansion-panels multiple>
      <v-expansion-panel v-for="(cancellation,index) in request.cancellations" :key="index">
        <v-expansion-panel-header class="themePanelHeader">
          <v-row no-gutters align="center" class="text-body-2">
            <v-col cols="6">
              <div class="caption">
                <div class="themeText1--text">{{cancellationReasonCode(cancellation)}}</div>
                <div class="themeText2--text">{{cancellation.message}}</div>
              </div>
            </v-col>
            <v-col cols="6" class="text-right">
              <request-return-status :code="cancellation.status_code" />
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="themePanelContent text-body-2">
          <v-container v-show="cancellation.line_item">
            <div>
              <span class="themeText2--text">Fulfillment : </span>
              <copyable :text="cancellation.line_item ? cancellation.line_item.fulfillment_reference : ''" />
            </div>
            <div>
              <span class="themeText2--text">Delivery : </span>
              <copyable :text="cancellation.line_item ? cancellation.line_item.delivery_reference : ''" />
            </div>
            <div>
              <span class="themeText2--text">Item : </span>
              <copyable :text="cancellation.line_item ? cancellation.line_item.reference : ''" />
            </div>
            <div>
              <span class="themeText2--text">Quantity : </span>
              <span class="themeText1--text">{{cancellation.line_item ? cancellation.line_item.quantity : ''}}</span>
            </div>
          </v-container>
          <request-rejected :rejected="cancellation.rejected" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import Copyable from '@/components/common/Copyable.vue'
import RequestReturnStatus from '@/components/request/RequestReturnStatus.vue'
import RequestRejected from '@/components/request/RequestRejected.vue'

export default {
  name: "RequestCancellations",
  props: {
    request: Object,
  },
  components: {
    Copyable,
    RequestReturnStatus,
    RequestRejected,
  },
  methods: {
    cancellationReasonCode(cancellation){
      return (cancellation) ? cancellation.reason_code : ''
    }
  }
}
</script>
<style scoped>
</style>
