<template>
  <div v-show="customer">
    <div class="text-h6 py-3 themeText3--text"><v-icon color="themeText3">mdi-account-circle</v-icon> Customer</div>
    <v-card class="themeCard">
      <v-list-item class="body-2 py-3">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col>
                 <div class="font-weight-bold">
                  <span class="body-2 themeText2--text">type : </span>
                  <span class="themeText1--text">{{customerTypeCode}}</span>
                </div>
                <div class="font-weight-bold">
                  <span class="body-2 themeText2--text">reference : </span>
                  <copyable :text="customerReference" />
                </div>
                <div class="body-1">
                  <span class="body-2 themeText2--text">name : </span>
                  <span class="themeText1--text">{{customerName}}</span>
                </div>
                <div class="">
                  <span class="body-2 themeText2--text">email : </span>
                  <span class="themeText1--text">{{customerEmail}}</span>
                </div>
                <div class="">
                  <span class="body-2 themeText2--text">phone : </span>
                  <span class="themeText1--text">{{customerPhone}}</span>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-list-item>
      <div v-show="hasBillingAddress">
        <v-divider class="themeDivider"></v-divider>
        <v-list-item class="body-2 py-3">
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col>
                  <order-address type="Billing address" :address="customerAddress" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-list-item>
      </div>
      <div v-show="hasAdditionalAttributes">
        <v-divider class="themeDivider"></v-divider>
        <div class="text-body-2 pa-4">
          <order-additional-attributes :attributes="customerAdditionalAttributes" />
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import Copyable from '@/components/common/Copyable.vue'
import OrderAddress from '@/components/order/OrderAddress.vue'
import OrderAdditionalAttributes from '@/components/order/OrderAdditionalAttributes.vue'

export default {
  name: "OrderCustomer",
  props: {
    customer: Object,
  },
  components: {
    Copyable,
    OrderAddress,
    OrderAdditionalAttributes,
  },
  computed: {
    customerReference() {
      return (this.customer && this.customer.reference) ? this.customer.reference : ""
    },
    customerTypeCode() {
      return (this.customer && this.customer.type_code) ? this.customer.type_code : ""
    },
    customerName() {
      let name = ""

      if (this.customerFirstName) {
        name += this.customerFirstName
      }

      if (this.customerFirstName && this.customerLastName) {
        name += " "
      }

      if (this.customerLastName) {
        name += this.customerLastName
      }

      return name
    },
    customerFirstName() {
      return (this.customer && this.customer.billing_address && this.customer.billing_address.first_name) ? this.customer.billing_address.first_name : ""
    },
    customerLastName() {
      return (this.customer && this.customer.billing_address && this.customer.billing_address.last_name) ? this.customer.billing_address.last_name : ""
    },
    customerEmail() {
      return (this.customer && this.customer.billing_address && this.customer.billing_address.email) ? this.customer.billing_address.email : ""
    },
    customerPhone() {
      return (this.customer && this.customer.billing_address && this.customer.billing_address.phone) ? this.customer.billing_address.phone : ""
    },
    customerAddress() {
      return (this.customer) ? this.customer.billing_address : {}
    },
    customerAdditionalAttributes() {
      return (this.customer) ? this.customer.additional_attributes : {}
    },
    hasBillingAddress() {
      return (this.customer && this.customer.billing_address)
    },
    hasAdditionalAttributes() {
      return (this.customerAdditionalAttributes && Object.keys(this.customerAdditionalAttributes).length) ? true : false
    },
  }
}
</script>
<style lang="scss" scoped>
</style>
