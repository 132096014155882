import {getMerchants} from "@/helpers/apiV1";

const actions = {
  fetchMerchants({getters, commit},{params}) {
    commit('updateMerchants',getMerchants(params))
  },
  selectMerchantToDisplay({getters, commit},merchant) {
    commit('updateMerchant',merchant)
  },
  selectMerchantById({getters, commit},merchantId) {
    let merchants = getters.merchants
    let merchant = {}

    merchants.some( (el) => {
      if (el.id === merchantId) {
        merchant = el
        return true
      }
    })

    commit('updateMerchant',merchant)
  },
}

export default actions;
