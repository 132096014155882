<template>

  <v-tooltip right color="transparent" fixed>
    <template v-slot:activator="{ on, attrs }">
      <span class="copyable themeText1--text" v-bind="attrs" v-on="on" @click.self.stop="copyToClipboard()">{{text}}</span>
    </template>
    <span class="text-caption"><v-icon small color="themeText2">{{icon}}</v-icon></span>
  </v-tooltip>

</template>
<script>
export default {
  name: "Copyable",
  data: () => ({
    icon: "mdi-clipboard-text-outline",
    defaultIcon: "mdi-clipboard-text-outline",
    copiedIcon: "mdi-clipboard-check-outline",
  }),
  props: {
    text: String
  },
  methods: {
    copyToClipboard() {
      this.icon = this.copiedIcon
      navigator.clipboard.writeText(this.text)
      setTimeout(() => {  this.icon = this.defaultIcon }, 250)
    }
  }
}
</script>
<style scoped>
.copyable:hover {
  cursor: default;
  text-decoration: underline;
}
</style>
