<template>
  <v-dialog v-model="dialog" transition="dialog-bottom-transition" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn :disabled="!orderExists" color="themeText2" class="text-none" small text v-bind="attrs" v-on="on"><v-icon small class="pr-2">mdi-code-braces</v-icon>Json</v-btn>
    </template>
    <v-card class="themeCard">
      <v-toolbar flat dense color="themePanelHeader">
        <span class="themeText1--text text-h6">
          <copyable :text="order.reference"/>
        </span>
        <v-spacer></v-spacer>
        <v-btn text icon @click="dialog = false"><v-icon small color="themeText3">mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text class="themeText1--text">
        <pre>{{orderJson}}</pre>
        <v-btn fab absolute top right class="copy-btn" small color="transparent" elevation="0" @click="copyToClipboard">
          <v-icon color="themeText3">{{icon}}</v-icon>
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import {mapGetters} from "vuex"
import {ORDER_NS} from "@/store/modules/order/constants"
import Copyable from '@/components/common/Copyable.vue'

export default {
  name: "OrderDialogJson",
  data: () => ({
    dialog: false,
    icon: "mdi-clipboard-text-outline",
    defaultIcon: "mdi-clipboard-text-outline",
    copiedIcon: "mdi-clipboard-check-outline",
  }),
  components: {
    Copyable,
  },
  computed: {
    ...mapGetters(ORDER_NS, [
      'order',
      'orderJson'
    ]),
    orderExists() {
      let result = false
      if (this.order.constructor === Object && Object.entries(this.order).length !== 0) {
        result = true
      }
      return result
    }
  },
  methods: {
    copyToClipboard() {
      this.icon = this.copiedIcon
      navigator.clipboard.writeText(this.orderJson)
      setTimeout(() => {  this.icon = this.defaultIcon }, 250)
    }
  }
}
</script>
<style scoped>
.copy-btn {
  margin-top: 80px;
}
</style>
