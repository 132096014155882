import { getCurrencyDecimal } from "@/helpers/currency"

let ordersToCSV = (orders) => {
    let content = ""

    content = content + "Merchant Id,"
    content = content + "Channel Type,"
    content = content + "Channel Reference,"
    content = content + "Application Type,"
    content = content + "Application Reference,"
    content = content + "Order Id,"
    content = content + "Order Reference,"
    content = content + "Order Creation Date Time,"
    content = content + "Order Update Date Time,"
    content = content + "Order Type,"
    content = content + "Order Linked Reference,"
    content = content + "Order Status,"
    content = content + "Customer Type,"
    content = content + "Currency,"
    content = content + "Delivery Nb,"
    content = content + "Delivery Type,"
    content = content + "Delivery Status,"
    content = content + "Delivery Total,"
    content = content + "Delivery To collect,"
    content = content + "Total,"
    content = content + "Total Products,"
    content = content + "Total Products Qty,"
    content = content + "Total Shipping Fees,"
    content = content + "Total Ordered,"
    content = content + "Total Ordered Qty,"
    content = content + "Total Cancelled,"
    content = content + "Total Cancelled Qty,"
    content = content + "Cancellation reasons,"
    content = content + "Total Returned,"
    content = content + "Total Returned Qty,"
    content = content + "Return reasons,"
    content = content + "Total Adjusted,"
    content = content + "Total Adjusted (post-checkout),"
    content = content + "Adjustment reasons,"
    content = content + "Payment Nb,"
    content = content + "Payment method,"
    content = content + "Total Payment,"
    content = content + "Total Paid,"
    content = content + "Total Void,"
    content = content + "Total Refund\n"

    for (let order of orders) {
        const merchantId = order.source ? order.source.merchant_id : ""
        let channelType = ""
        let channelReference = ""
        let applicationType = ""
        let applicationReference = ""
        const orderId = order.id
        const orderReference = order.reference
        const orderCreationDateTime = order.creation_date_time
        const orderUpdateDateTime = order.update_date_time
        const orderStatus = order.status_code
        const orderType = order.type_code
        const linkedOrderReference = order.linked_order_reference ? order.linked_order_reference : ""
        const customerTypeCode = order.customer && order.customer.type_code ? order.customer.type_code : ""
        const currencyCode = order.currency_code
        const decimal = getCurrencyDecimal(currencyCode)
        let deliveryNb = 0
        let deliveryType = ""
        let deliveryStatus = ""
        let deliveryTotal = ""
        let deliveryToCollect = ""
        let totalAmount = 0
        let totalProductsAmount = 0
        let totalProductsQty = 0
        let totalShippingFeesAmount = 0
        let totalOrderedAmount = 0
        let totalOrderedQty = 0
        let totalCancelledAmount = 0
        let totalCancelledQty = 0
        let cancellationReasonCodes = []
        let uniqueCancellationReasonCodes = []
        let totalReturnedAmount = 0
        let totalReturnedQty = 0
        let returnReasonCodes = []
        let uniqueReturnReasonCodes = []
        let totalAdjusted = 0
        let totalAdjustedPostCheckout = 0
        let adjustmentReasonCodes = []
        let uniqueAdjustmentReasonCodes = []
        let paymentNb = 0
        let paymentMethod = ""
        let totalPaymentAmount = 0
        let totalPaidAmount = 0
        let totalVoidAmount = 0
        let totalRefundAmount = 0

        if (order.source && order.source.channel) {
            channelType = order.source.channel.type_code
            channelReference = order.source.channel.reference
        }

        if (order.source && order.source.application) {
            applicationType = order.source.application.type_code
            applicationReference = order.source.application.reference
        }

        for (const fulfillment of order.fulfillments) {
            totalAmount = totalAmount + fulfillment.total_amount

            for (const item of fulfillment.line_items) {
                switch (item.type_code) {
                    case "PRODUCT":
                        totalProductsAmount = totalProductsAmount + item.amount
                        totalProductsQty = totalProductsQty + item.quantity
                        break;
                    case "SHIPPING_FEES":
                        totalShippingFeesAmount = totalShippingFeesAmount + item.amount
                        break;
                    default:
                        break;
                }

                for (const adjustment of item.adjustments) {
                    totalAdjusted = totalAdjusted + adjustment.amount
                    
                    adjustmentReasonCodes.push(adjustment.reason_code)

                    if (adjustment.change_request_id) {
                        totalAdjustedPostCheckout = totalAdjustedPostCheckout + adjustment.amount
                    }
                }
            }

            for (const delivery of fulfillment.deliveries) {
                deliveryNb = deliveryNb + 1

                if (deliveryNb != 1) {
                    deliveryType = deliveryType + " / "
                    deliveryStatus = deliveryStatus + " / "
                    deliveryTotal = deliveryTotal + " / "
                    deliveryToCollect = deliveryToCollect + " / "
                }

                deliveryType = deliveryType + delivery.type_code
                deliveryStatus = deliveryStatus + delivery.status_code
                deliveryTotal = deliveryTotal + delivery.total_amount.toFixed(decimal)
                deliveryToCollect = deliveryToCollect + delivery.to_collect_amount.toFixed(decimal)
            }

            for (const cancellation of fulfillment.cancellations) {
                totalCancelledAmount = totalCancelledAmount + cancellation.total_amount

                
                for (const item of cancellation.line_items) {
                    cancellationReasonCodes.push(item.reason_code)

                    if (item.type_code == "PRODUCT") {
                        totalCancelledQty = totalCancelledQty + item.quantity
                    }

                    for (const adjustment of item.adjustments) {
                        totalAdjusted = totalAdjusted + adjustment.amount

                        if (adjustment.change_request_id) {
                            totalAdjustedPostCheckout = totalAdjustedPostCheckout + adjustment.amount
                        }
                    }
                }
            }

            for (const returned of fulfillment.returns) {
                totalReturnedAmount = totalReturnedAmount + returned.total_amount

                for (const item of returned.line_items) {

                    returnReasonCodes.push(item.reason_code)

                    if (item.type_code == "PRODUCT") {
                        totalReturnedQty = totalReturnedQty + item.quantity
                    }
                }
            }
        }

        totalOrderedAmount = totalAmount + totalCancelledAmount + totalAdjustedPostCheckout
        totalOrderedQty = totalProductsQty + totalCancelledQty

        for (const payment of order.payments) {
            totalPaymentAmount = totalPaymentAmount + payment.amount
            paymentNb = paymentNb + 1

            if (paymentNb != 1) {
                paymentMethod = paymentMethod + " / "
            }

            paymentMethod = paymentMethod + payment.method_reference

            for (const transaction of payment.transactions) {
                if (transaction.status_code == "CAPTURE_SUCCESS") {
                    totalPaidAmount = totalPaidAmount + transaction.amount
                }
            }

            for (const cancellation of payment.cancellations) {
                totalVoidAmount = totalVoidAmount + cancellation.amount
            }

            for (const refund of payment.refunds) {
                totalRefundAmount = totalRefundAmount + refund.amount
            }
        }

        uniqueCancellationReasonCodes = [...new Set(cancellationReasonCodes)]
        uniqueReturnReasonCodes = [...new Set(returnReasonCodes)]
        uniqueAdjustmentReasonCodes = [...new Set(adjustmentReasonCodes)]

        content = content + merchantId + ","
        content = content + channelType + ","
        content = content + channelReference + ","
        content = content + applicationType + ","
        content = content + applicationReference + ","
        content = content + orderId + ","
        content = content + orderReference + ","
        content = content + orderCreationDateTime + ","
        content = content + orderUpdateDateTime + ","
        content = content + orderType + ","
        content = content + linkedOrderReference + ","
        content = content + orderStatus + ","
        content = content + customerTypeCode + ","
        content = content + currencyCode + ","
        content = content + deliveryNb + ","
        content = content + deliveryType + ","
        content = content + deliveryStatus + ","
        content = content + deliveryTotal + ","
        content = content + deliveryToCollect + ","
        content = content + totalAmount.toFixed(decimal) + ","
        content = content + totalProductsAmount.toFixed(decimal) + ","
        content = content + totalProductsQty + ","
        content = content + totalShippingFeesAmount.toFixed(decimal) + ","
        content = content + totalOrderedAmount.toFixed(decimal) + ","
        content = content + totalOrderedQty + ","
        content = content + totalCancelledAmount.toFixed(decimal) + ","
        content = content + totalCancelledQty + ","
        content = content + uniqueCancellationReasonCodes.join(" / ") + ","
        content = content + totalReturnedAmount.toFixed(decimal) + ","
        content = content + totalReturnedQty + ","
        content = content + uniqueReturnReasonCodes.join(" / ") + ","
        content = content + totalAdjusted.toFixed(decimal) + ","
        content = content + totalAdjustedPostCheckout.toFixed(decimal) + ","
        content = content + uniqueAdjustmentReasonCodes.join(" / ") + ","
        content = content + paymentNb + ","
        content = content + paymentMethod + ","
        content = content + totalPaymentAmount.toFixed(decimal) + ","
        content = content + totalPaidAmount.toFixed(decimal) + ","
        content = content + totalVoidAmount.toFixed(decimal) + ","
        content = content + totalRefundAmount.toFixed(decimal) + "\n"
    }

    return content
}

export {
    ordersToCSV,
}