<template>
  <div v-show="hasSource">
    <div class="text-h6 py-3 themeText3--text"><v-icon color="themeText3">mdi-import</v-icon> Source</div>
    <v-card class="themeCard">
      <v-list-item class="body-2 py-3">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col>
                <div class="body-2">
                </div>
                <div class="font-weight-bold">
                  <copyable :text="merchantId" />
                </div>
                <v-row no-gutters class="align-center">
                  <v-icon small color="primary" class="mr-1">{{channelIcon}}</v-icon>
                  <copyable :text="channelReference" />
                  <v-spacer></v-spacer>
                  <div class="themeText2--text caption">{{channelTypeCode}}</div>
                </v-row>
                <v-row no-gutters class="align-center">
                  <v-icon small color="primary" class="mr-1">{{applicationIcon}}</v-icon>
                  <copyable :text="applicationReference" />
                  <v-spacer></v-spacer>
                  <div class="themeText2--text caption">{{applicationTypeCode}}</div>
                </v-row>
                <v-row v-show="userReference" no-gutters class="align-center">
                  <v-icon small color="primary" class="mr-1">{{userIcon}}</v-icon>
                  <copyable :text="userReference" />
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-list-item>
    </v-card>
  </div>
</template>
<script>
import Copyable from '@/components/common/Copyable.vue'

export default {
  name: "OrderSource",
  props: {
    source: Object,
  },
  components: {
    Copyable,
  },
  computed: {
    hasSource() {
      return (this.source)
    },
    merchantId() {
      return (this.source) ? this.source.merchant_id : ''
    },
    channelTypeCode() {
      return (this.source && this.source.channel) ? this.source.channel.type_code : ''
    },
    channelReference() {
      return (this.source && this.source.channel) ? this.source.channel.reference : ''
    },
    channelIcon() {
      let icon = ""
      let typeCode = (this.source && this.source.channel) ? this.source.channel.type_code : ''

      switch (typeCode) {
        case "ONLINE":
        icon = "mdi-web"
        break;
        case "IN_STORE":
        icon = "mdi-storefront"
        break;
        case "OUT_STORE":
        icon = "mdi-storefront-outline"
        break;
        default:
        icon = "mdi-help"
        break;
      }

      return icon
    },
    applicationTypeCode() {
      return (this.source && this.source.application) ? this.source.application.type_code : ''
    },
    applicationReference() {
      return (this.source && this.source.application) ? this.source.application.reference : ''
    },
    applicationIcon() {
      let icon = ""
      let typeCode = (this.source && this.source.application) ? this.source.application.type_code : ''

      switch (typeCode) {
        case "MOBILE_APP":
        case "MOBILE_POS":
        case "TEAM_MEMBER_APP":
        icon = "mdi-cellphone"
        break;
        case "KIOSK":
        icon = "mdi-monitor"
        break;
        case "WEB_APP":
        case "TEAM_MEMBER_PORTAL":
        icon = "mdi-monitor"
        break;
        default:
        icon = "mdi-help"
        break;
      }

      return icon
    },
    userReference() {
      return this.source ? this.source.user_reference : ''
    },
    userIcon() {
      return "mdi-account"
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
