
const mutations = {
  updateMerchant(state, merchant) {
    state.merchant = merchant;
  },
  updateMerchants(state, merchants) {
    state.merchants = merchants;
  }
};

export default mutations;
