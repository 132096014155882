<template>
  <div>
    <v-row>
      <v-col cols="12" sm="10" offset-sm="1" md="8" offset-md="2" class="pb-6">
        <div class="title font-weight-thin text-center themeText1--text">Merchants</div>
          <v-text-field dense solo clearable hide-details
          v-model="searchInput"
          prepend-inner-icon="mdi-magnify"
          :append-icon="searchIcon"
          @click:append="toggleSearchMode()"
          @keyup.enter="search()"
          :dark="themeIsDark"
          color="primary"
          background-color="themeSearchBar"
          name="name" :placeholder="searchPlaceholder" id="id"/>
        </v-col>
    </v-row>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex"
import {MERCHANT_NS} from "@/store/modules/merchant/constants"
import {ENV_NS} from "@/store/modules/env/constants"
import {THEME_NS} from "@/store/modules/theme/constants"

export default {
  name: "MerchantsHeader",
  data: () => ({
    searchById: true,
    searchInput: ""
  }),
  props: {
  },
  components: {
  },
  computed: {
    ...mapGetters(THEME_NS, ['themeIsDark']),
    searchIcon() {
      return ( this.searchById ) ? "mdi-toggle-switch-off-outline" : "mdi-toggle-switch-outline"
    },
    searchPlaceholder() {
      return ( this.searchById ) ? "Search merchants by id" : "Search merchants by country code"
    },
  },
  methods: {
    ...mapGetters(ENV_NS, ['env']),
    ...mapActions(MERCHANT_NS, [
      'fetchMerchants'
    ]),
    toggleSearchMode() {
      this.searchById = !this.searchById
    },
    search() {
      let params = {}

      params.env = this.env()

      if ( this.searchById ) {
        params.id = this.searchInput
      } else {
        params.country_code = this.searchInput
      }

      this.fetchMerchants({params})
    }
  },
}
</script>
<style lang="scss" scoped>
</style>
