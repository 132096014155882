import axios from 'axios'

import {parameters} from "@/helpers/parameters"

const httpClient = axios.create()

import {ENV_NS} from "@/store/modules/env/constants";
import {USER_NS} from "@/store/modules/user/constants"
import store from "@/store"

const authInterceptor = config => {
  const env = localStorage.getItem(ENV_NS)
  let authorization = `Bearer ${store.getters[USER_NS+'/accessToken'](env)}`

  if(isTokenValid()){
    config.headers['authorization'] = authorization
    return config
  }

  localStorage.setItem('origin_url_backup', window.location.href)
  location.replace(`${parameters.fedUrl}/as/authorization.oauth2?client_id=${parameters.clientId}&response_type=code&redirect_uri=${parameters.redirectUri}&scope=openid%20profile`)
  return config
};

const isTokenValid = () => {
  const env = localStorage.getItem(ENV_NS)
  return store.getters[USER_NS+'/isTokenValid'](env)
};

const refreshToken = async (code) => {
  const env = localStorage.getItem(ENV_NS)
  const params = new URLSearchParams()

  store.dispatch(ENV_NS+'/selectEnv', env)

  params.append('grant_type', 'authorization_code')
  params.append('code', code)
  params.append('client_id', parameters.clientId)
  params.append('redirect_uri', parameters.redirectUri)

  return axios.request({
    method: "post",
    url: "/as/token.oauth2",
    baseURL: parameters.fedUrl,
    data: params
  })
  .then(function (response) {
    let data = response.data
    store.dispatch(USER_NS+'/updateData', {'env':env,'data':data})
    return response
  })
  .catch(function (error) {
    console.log("refreshToken error="+error)
    throw error
  })
};

httpClient.interceptors.request.use(authInterceptor)

httpClient.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error)
  }
);

export { httpClient, isTokenValid, refreshToken }
