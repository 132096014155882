import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'
import {ENV_NS} from "@/store/modules/env/constants"
import {MERCHANT_NS} from "@/store/modules/merchant/constants"

import HomePage from '@/views/HomePage.vue'
import MerchantsPage from '@/views/MerchantsPage.vue'
import MerchantPage from '@/views/MerchantPage.vue'
import OrdersPage from '@/views/OrdersPage.vue'
import OrderPage from '@/views/OrderPage.vue'
import RequestsPage from '@/views/RequestsPage.vue'
import RequestPage from '@/views/RequestPage.vue'
import FedPage from '@/views/FedPage.vue'
import NotFoundPage from '@/views/NotFoundPage.vue'
import TestPage from '@/views/TestPage.vue'
import OrderTestPage from '@/views/OrderTestPage.vue'

import {isEnvSupported} from "@/helpers/env"
import {isMerchantIdSupported} from "@/helpers/merchants"
import {isTokenValid} from "@/helpers/httpClient"
import {parameters} from "@/helpers/parameters"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/accessCode/:fedParams?',
    name: 'FedPage',
    component: FedPage
  },
  {
    path: '/test',
    name: 'Test',
    component: TestPage
  },
  {
    path: '/test/order',
    name: 'OrderTest',
    component: OrderTestPage
  },
  {
    path: '/:env/merchants',
    name: 'Merchants',
    component: MerchantsPage
  },
  {
    path: '/:env/:merchantId',
    name: 'Merchant',
    component: MerchantPage
  },
  {
    path: '/:env/:merchantId/orders',
    name: 'Orders',
    component: OrdersPage
  },
  {
    path: '/:env/:merchantId/order/:id',
    name: 'Order',
    component: OrderPage
  },
  {
    path: '/:env/:merchantId/requests',
    name: 'ChangeRequests',
    component: RequestsPage
  },
  {
    path: '/:env/:merchantId/request/:requestId',
    name: 'ChangeRequest',
    component: RequestPage
  },
  {
    path: '*',
    name: 'NotFoundPage',
    component: NotFoundPage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Add guard for every route to validate authentication (or redirect to Fed)
router.beforeEach((to, from, next) => {

  if ( to.params && to.params.env && to.params.env != "accessCode") {
    if (isEnvSupported(to.params.env)) {
      store.dispatch(ENV_NS+'/selectEnv',to.params.env)

      if ( to.params.merchantId ) {
        if ( isMerchantIdSupported(to.params.env,to.params.merchantId) ) {
          store.dispatch(MERCHANT_NS+'/selectMerchantById',to.params.merchantId)
        } else {
          next({ name: 'NotFoundPage', params: { '0': to.path } })
        }
      }

      if (to.name=="FedPage" || isTokenValid()) {
        next()
      } else {
        localStorage.setItem('origin_url_backup', window.location.href)
        location.replace(`${parameters.fedUrl}/as/authorization.oauth2?client_id=${parameters.clientId}&response_type=code&redirect_uri=${parameters.redirectUri}&scope=openid%20profile`)
      }
    } else {
      next({ name: 'NotFoundPage', params: { '0': to.path } })
    }
  } else {
    next()
  }
})

export default router
