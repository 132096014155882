const state = {
  requests: [],
  totalNumber: 0,
  showingNumber: 0,
  request: {},
  searchQueryParams: {},
  searchQueryParamsURL: "",
  errorMessage: "",
  loadingFlag: false
};

export default state;
