<template>
  <div v-show="!isEmpty">
    <div class="text-h6 py-3 themeText3--text"><v-icon color="themeText3">mdi-dolly</v-icon> Fulfillments</div>
    <v-expansion-panels multiple v-model="panels">
      <order-fulfillment v-for="fulfillment in fulfillments" :key="fulfillment.reference" :order="order" :fulfillment="fulfillment" />
    </v-expansion-panels>
  </div>
</template>
<script>
import OrderFulfillment from '@/components/order/OrderFulfillment.vue'
export default {
  name: "OrderFulfillments",
  props: {
    order: Object,
  },
  data: () => ({
    _panels: []
  }),
  components: {
    OrderFulfillment,
  },
  computed: {
    panels: {
      get() {
        let fulfillments = []
        this._panels = []

        if (this.order && this.order.fulfillments) {
          fulfillments = this.order.fulfillments
        }

        for (let i = 0; i < fulfillments.length; i++) {
          this._panels.push(i)
        }

        return this._panels
      },
      set(newValue) {
        this._panels = newValue
      }
    },
    isEmpty() {
      return (this.order === undefined || this.order && this.order.fulfillments === undefined || this.order.fulfillments && this.order.fulfillments.length === 0)
    },
    fulfillments() {
      return (this.order && this.order.fulfillments) ? this.order.fulfillments : []
    }
  }
}
</script>
<style scoped>
</style>
