<template>
  <v-menu v-model="menu" :close-on-content-click="false" offset-y bottom left transition="scale-transition" origin="top right">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="themeText2" class="text-none" small text v-bind="attrs" v-on="on">
        <v-icon small class="pr-2" :color="iconColor">{{icon}}</v-icon>Type
      </v-btn>
    </template>
    <v-card class="themeCard">
      <div class="pa-2 py-4 themePanelHeader themeText2--text text-center body-2">Select the request type to focus on</div>
      <v-list style="max-height: 270px" class="overflow-y-auto themeCard" :dark="themeIsDark">
        <v-list-item>
          <v-list-item-action>
            <v-switch inset dense v-model="orderCancellation" @change="toggleType()" color="primary"></v-switch>
          </v-list-item-action>
          <v-list-item-title @click="toggleType('ORDER_CANCELLATION')">
            <request-type code="ORDER_CANCELLATION"/>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-switch inset dense v-model="itemsCancellation" @change="toggleType()" color="primary"></v-switch>
          </v-list-item-action>
          <v-list-item-title @click="toggleType('ITEMS_CANCELLATION')">
            <request-type code="ITEMS_CANCELLATION"/>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-switch inset dense v-model="fulfillmentAdjustment" @change="toggleType()" color="primary"></v-switch>
          </v-list-item-action>
          <v-list-item-title @click="toggleType('FULFILLMENT_ADJUSTMENT')">
            <request-type code="FULFILLMENT_ADJUSTMENT"/>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-switch inset dense v-model="itemsAdjustment" @change="toggleType()" color="primary"></v-switch>
          </v-list-item-action>
          <v-list-item-title @click="toggleType('ITEMS_ADJUSTMENT')">
            <request-type code="ITEMS_ADJUSTMENT"/>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-switch inset dense v-model="refund" @change="toggleType()" color="primary"></v-switch>
          </v-list-item-action>
          <v-list-item-title @click="toggleType('REFUND')">
            <request-type code="REFUND"/>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-switch inset dense v-model="refundApproved" @change="toggleType()" color="primary"></v-switch>
          </v-list-item-action>
          <v-list-item-title @click="toggleType('REFUND_APPROVED')">
            <request-type code="REFUND_APPROVED"/>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-switch inset dense v-model="refundCustomer" @change="toggleType()" color="primary"></v-switch>
          </v-list-item-action>
          <v-list-item-title @click="toggleType('REFUND_CUSTOMER')">
            <request-type code="REFUND_CUSTOMER"/>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-switch inset dense v-model="refundManual" @change="toggleType()" color="primary"></v-switch>
          </v-list-item-action>
          <v-list-item-title @click="toggleType('REFUND_MANUAL')">
            <request-type code="REFUND_MANUAL"/>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-switch inset dense v-model="returnType" @change="toggleType()" color="primary"></v-switch>
          </v-list-item-action>
          <v-list-item-title @click="toggleType('RETURN')">
            <request-type code="RETURN"/>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-switch inset dense v-model="returnApproved" @change="toggleType()" color="primary"></v-switch>
          </v-list-item-action>
          <v-list-item-title @click="toggleType('RETURN_APPROVED')">
            <request-type code="RETURN_APPROVED"/>
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <div class="themePanelHeader pa-2">
        <v-btn color="themeText2" text block class="text-none" @click="removeAllTypeFilters()">Remove all type filters</v-btn>
      </div>
    </v-card>
  </v-menu>
</template>
<script>
import RequestType from '@/components/request/RequestType.vue'
import {mapGetters,mapActions} from "vuex"
import {MERCHANT_NS} from "@/store/modules/merchant/constants"
import {REQUEST_NS} from "@/store/modules/request/constants"
import {THEME_NS} from "@/store/modules/theme/constants"

export default {
  name: "RequestsMenuTypeCode",
  components: {
    RequestType,
  },
  data: () => ({
    menu: false,
    typeCodeQuery: "",
    orderCancellation: false,
    itemsCancellation: false,
    fulfillmentAdjustment: false,
    itemsAdjustment: false,
    refund: false,
    refundApproved: false,
    refundCustomer: false,
    refundManual: false,
    returnType: false,
    returnApproved: false,
  }),
  created() {
    if (this.searchQueryParams && this.searchQueryParams['type_code']) {
      this.typeCodeQuery = this.searchQueryParams['type_code']
      let typeCodes = this.typeCodeQuery.split(',')

      for (let type of typeCodes) {
        switch (type) {
          case "ORDER_CANCELLATION":
          this.orderCancellation = !this.orderCancellation
          break;
          case "ITEMS_CANCELLATION":
          this.itemsCancellation = !this.itemsCancellation
          break;
          case "FULFILLMENT_ADJUSTMENT":
          this.fulfillmentAdjustment = !this.fulfillmentAdjustment
          break;
          case "ITEMS_ADJUSTMENT":
          this.itemsAdjustment = !this.itemsAdjustment
          break;
          case "REFUND":
          this.refund = !this.refund
          break;
          case "REFUND_APPROVED":
          this.refundApproved = !this.refundApproved
          break;
          case "REFUND_CUSTOMER":
          this.refundCustomer = !this.refundCustomer
          break;
          case "REFUND_MANUAL":
          this.refundManual = !this.refundManual
          break;
          case "RETURN":
          this.returnType = !this.returnType
          break;
          case "RETURN_APPROVED":
          this.returnApproved = !this.returnApproved
          break;
          default:
          break;
        }
      }
    }
  },
  computed: {
    ...mapGetters(THEME_NS, ['themeIsDark']),
    ...mapGetters(MERCHANT_NS, [
      'merchant'
    ]),
    ...mapGetters(REQUEST_NS, [
      'searchQueryParams'
    ]),
    icon() {
      return (this.typeCodeQuery == "") ? 'mdi-square-rounded-outline' : 'mdi-square-rounded'
    },
    iconColor() {
      return (this.typeCodeQuery == "") ? 'themeText2' : 'primary'
    },
  },
  methods: {
    ...mapActions(REQUEST_NS, ['fetchRequests','addSearchQueryParam','removeSearchQueryParam','updateRequestsURL']),
    removeAllTypeFilters() {
      this.orderCancellation = false
      this.itemsCancellation = false
      this.fulfillmentAdjustment = false
      this.itemsAdjustment = false
      this.refund = false
      this.refundApproved = false
      this.refundCustomer = false
      this.refundManual = false
      this.returnType = false
      this.returnApproved = false
      this.updateTypeCodeQuery()
      this.updateSearchResults()
    },
    toggleType(type) {
      switch (type) {
        case "ORDER_CANCELLATION":
        this.orderCancellation = !this.orderCancellation
        break;
        case "ITEMS_CANCELLATION":
        this.itemsCancellation = !this.itemsCancellation
        break;
        case "FULFILLMENT_ADJUSTMENT":
        this.fulfillmentAdjustment = !this.fulfillmentAdjustment
        break;
        case "ITEMS_ADJUSTMENT":
        this.itemsAdjustment = !this.itemsAdjustment
        break;
        case "REFUND":
        this.refund = !this.refund
        break;
        case "REFUND_APPROVED":
        this.refundApproved = !this.refundApproved
        break;
        case "REFUND_CUSTOMER":
        this.refundCustomer = !this.refundCustomer
        break;
        case "REFUND_MANUAL":
        this.refundManual = !this.refundManual
        break;
        case "RETURN":
        this.returnType = !this.returnType
        break;
        case "RETURN_APPROVED":
        this.returnApproved = !this.returnApproved
        break;
        default:
        break;
      }
      this.updateTypeCodeQuery()
      this.updateSearchResults()
    },
    updateTypeCodeQuery() {
      this.typeCodeQuery = ""

      if (this.orderCancellation) {
        this.typeCodeQuery += "ORDER_CANCELLATION"
        if (this.itemsCancellation || this.fulfillmentAdjustment || this.itemsAdjustment || this.refund || this.refundApproved || this.refundCustomer || this.refundManual || this.returnType || this.returnApproved) {
          this.typeCodeQuery += ","
        }
      }

      if (this.itemsCancellation) {
        this.typeCodeQuery += "ITEMS_CANCELLATION"
        if (this.fulfillmentAdjustment || this.itemsAdjustment || this.refund || this.refundApproved || this.refundCustomer || this.refundManual || this.returnType || this.returnApproved) {
          this.typeCodeQuery += ","
        }
      }

      if (this.fulfillmentAdjustment) {
        this.typeCodeQuery += "FULFILLMENT_ADJUSTMENT"
        if (this.itemsAdjustment || this.refund || this.refundApproved || this.refundCustomer || this.refundManual || this.returnType || this.returnApproved) {
          this.typeCodeQuery += ","
        }
      }

      if (this.itemsAdjustment) {
        this.typeCodeQuery += "ITEMS_ADJUSTMENT"
        if (this.refund || this.refundApproved || this.refundCustomer || this.refundManual || this.returnType || this.returnApproved) {
          this.typeCodeQuery += ","
        }
      }

      if (this.refund) {
        this.typeCodeQuery += "REFUND"
        if (this.refundApproved || this.refundCustomer || this.refundManual || this.returnType || this.returnApproved) {
          this.typeCodeQuery += ","
        }
      }

      if (this.refundApproved) {
        this.typeCodeQuery += "REFUND_APPROVED"
        if (this.refundCustomer || this.refundManual || this.returnType || this.returnApproved) {
          this.typeCodeQuery += ","
        }
      }

      if (this.refundCustomer) {
        this.typeCodeQuery += "REFUND_CUSTOMER"
        if (this.refundManual || this.returnType || this.returnApproved) {
          this.typeCodeQuery += ","
        }
      }

      if (this.refundManual) {
        this.typeCodeQuery += "REFUND_MANUAL"
        if (this.returnType || this.returnApproved) {
          this.typeCodeQuery += ","
        }
      }

      if (this.returnType) {
        this.typeCodeQuery += "RETURN"
        if (this.returnApproved) {
          this.typeCodeQuery += ","
        }
      }

      if (this.returnApproved) {
        this.typeCodeQuery += "RETURN_APPROVED"
      }

    },
    updateSearchResults() {
      if ( this.typeCodeQuery ) {
        this.addSearchQueryParam({'key':'type_code','value':this.typeCodeQuery})
      } else {
        this.removeSearchQueryParam('type_code')
      }

      this.updateRequestsURL({'vue':this})
      this.fetchRequests({'merchantId':this.merchant.id, 'params':this.searchQueryParams})
    }
  },
}
</script>
