<template>
  <v-container class="text-left">
    <merchants-header />
    <v-card class="body-2 themeCard">
      <template v-for="(merchant,index) in merchants">
        <merchants-line @click="" :key="merchant.id" :merchant="merchant" />
        <v-divider v-if="!isLastMerchant(index,merchants)"></v-divider>
      </template>
    </v-card>
  </v-container>
</template>

<script>
import MerchantsHeader from '@/components/merchants/MerchantsHeader.vue'
import MerchantsLine from '@/components/merchants/MerchantsLine.vue'

import {mapActions, mapGetters} from "vuex"
import {MERCHANT_NS} from "@/store/modules/merchant/constants"

export default {
  name: 'Merchants',
  components: {
    MerchantsHeader,
    MerchantsLine,
  },
  computed: {
    ...mapGetters(MERCHANT_NS, [
      'merchants'
    ])
  },
  methods: {
    ...mapActions(MERCHANT_NS, [
      'fetchMerchants'
    ]),
    isLastMerchant(index,merchants) {
      return (index == merchants.length - 1)
    }
  }
}
</script>
