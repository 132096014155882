<template>
  <v-expansion-panel-content color="themePanelContent" class="themeText1--text">
    <v-row class="mt-1">
      <v-col cols="12" md="6">
        <v-row no-gutters v-for="(item,i) in deliveryLineItems" :key="'LI-'+i">
          <v-col>
            <order-delivery-line-item :fulfillment="fulfillment" :item="item" />
          </v-col>
        </v-row>
        <div v-show="hasCancellations" class="font-weight-bold">Cancellations</div>
        <v-row no-gutters v-for="(item,i) in deliveryCancellations" :key="'CL-'+i">
          <v-col>
            <order-delivery-line-item :cancellation="true" :fulfillment="fulfillment" :item="item" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-card outlined class="themePanelHeader">
          <v-card-text>
            <div class="themeText2--text font-weight-bold">Delivery details</div>
            <div>
              <span class="themeText2--text">Type </span>
              <span class="themeText1--text">{{deliveryTypeCode}}</span>
            </div>
            <div v-show="deliveryPickupPointReference" class="text-truncate">
              <span class="themeText2--text">Pickup point </span>
              <span class="themeText1--text">{{deliveryPickupPointReference}}</span>
            </div>
            <div>
              <span class="themeText2--text">Status info </span>
              <span class="themeText1--text">{{deliveryStatusInfo}}</span>
            </div>
            <div>
              <span class="themeText2--text">Cancellable </span>
              <span class="themeText1--text">{{deliveryCancellable}}</span>
            </div>
            <div>
              <span class="themeText2--text">Created at </span>
              <span class="themeText1--text">{{deliveryCreationDateTime}}</span>
            </div>
            <div>
              <span class="themeText2--text">Updated at </span>
              <span class="themeText1--text">{{deliveryUpdateDateTime}}</span>
            </div>
            <div>
              <span class="themeText2--text">Fulfiller delivery reference </span>
              <copyable :text="fulfillerDeliveryReference"/>
            </div>
            </v-card-text>
            <v-divider class="themeDivider"></v-divider>
            <v-card-text>
            <div class="themeText2--text font-weight-bold">Carrier</div>
            <div>
              <span class="themeText2--text">Name </span>
              <span class="themeText1--text">{{carrierName}}</span>
            </div>
            <div>
              <span class="themeText2--text">Method reference </span>
              <span class="themeText1--text">{{carrierMethodReference}}</span>
            </div>
            <div v-show="hasTrackings">
              <span class="themeText2--text">Trackings</span>
            </div>
            <div v-for="(tracking,i) in deliveryTrackings" :key="i">
              <span class="themeText1--text">{{tracking.company}} </span>
              <span v-show="tracking.reference"><a :href="tracking.url" target="_blank">{{tracking.reference}}</a></span>
              <span v-show="!tracking.reference"  class="themeText1--text">{{tracking.url}}</span>
            </div>
          </v-card-text>
          <v-divider class="themeDivider"></v-divider>
          <v-card-text>
            <order-address type="Delivery address" :address="deliveryAddress" />
          </v-card-text>
          <v-divider class="themeDivider"></v-divider>
          <v-card-text>
            <div class="themeText2--text font-weight-bold">Estimated delivery</div>
            <div>
              <span class="themeText2--text">≥ </span>
              <span class="themeText1--text">{{deliveryEstimatedDateTimeStart}}</span>
            </div>
            <div>
              <span class="themeText2--text">≤ </span>
              <span class="themeText1--text">{{deliveryEstimatedDateTimeEnd}}</span>
            </div>
          </v-card-text>
          <v-divider class="themeDivider"></v-divider>
          <v-card-text>
            <div class="themeText2--text font-weight-bold">Payment details</div>
            <div>
              <span class="themeText2--text">Delivery total amount </span>
              <span class="themeText1--text">{{totalAmount}} {{currencyCode}}</span>
            </div>
            <div>
              <span class="themeText2--text">To be collected at delivery </span>
              <span class="themeText1--text">{{toCollectAmount}} {{currencyCode}}</span>
            </div>
          </v-card-text>
          <div v-show="hasAdditionalAttributes">
            <v-divider class="themeDivider"></v-divider>
            <v-card-text>
              <order-additional-attributes :attributes="deliveryAdditionalAttributes" />
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <order-documents :documents="deliveryDocuments"/>
  </v-expansion-panel-content>
</template>
<script>
import {getCurrencyDecimal} from "@/helpers/currency"
import Copyable from '@/components/common/Copyable.vue'
import OrderAdditionalAttributes from '@/components/order/OrderAdditionalAttributes.vue'
import OrderAddress from '@/components/order/OrderAddress.vue'
import OrderDeliveryLineItem from '@/components/order/OrderDeliveryLineItem.vue'
import OrderDocuments from '@/components/order/OrderDocuments.vue'

export default {
  name: "OrderDeliveryContent",
  props: {
    fulfillment: Object,
    delivery: Object,
    currencyCode: String,
  },
  components: {
    Copyable,
    OrderAdditionalAttributes,
    OrderAddress,
    OrderDeliveryLineItem,
    OrderDocuments,
  },
  computed: {
    deliveryLineItems() {
      return this.delivery ? this.delivery.line_items : []
    },
    hasCancellations() {
      return (this.delivery && this.delivery.cancellations && this.delivery.cancellations.length > 0) ? true : false
    },
    deliveryCancellations() {
      return this.delivery ? this.delivery.cancellations : []
    },
    deliveryTypeCode() {
      return this.delivery ? this.delivery.type_code : ""
    },
    deliveryPickupPointReference() {
      return this.delivery ? this.delivery.pickup_point_reference : ""
    },
    deliveryStatusInfo() {
      return this.delivery ? this.delivery.status_info : ""
    },
    deliveryCancellable() {
      return this.delivery ? this.delivery.cancellable : ""
    },
    deliveryCreationDateTime() {
      return this.delivery ? this.delivery.creation_date_time : ""
    },
    deliveryUpdateDateTime() {
      return this.delivery ? this.delivery.update_date_time : ""
    },
    fulfillerDeliveryReference() {
      return this.delivery ? this.delivery.fulfiller_delivery_reference : ""
    },
    carrierName() {
      return this.delivery && this.delivery.carrier ? this.delivery.carrier.name : ""
    },
    carrierMethodReference() {
      return this.delivery && this.delivery.carrier ? this.delivery.carrier.method_reference : ""
    },
    totalAmount() {
      const currencyCode = this.currencyCode
      const decimal = getCurrencyDecimal(currencyCode)
      return (this.delivery && this.delivery.total_amount >= 0) ? this.delivery.total_amount.toFixed(decimal) : (0).toFixed(decimal)
    },
    toCollectAmount() {
      const currencyCode = this.currencyCode
      const decimal = getCurrencyDecimal(currencyCode)
      return (this.delivery && this.delivery.to_collect_amount >= 0) ? this.delivery.to_collect_amount.toFixed(decimal) : (0).toFixed(decimal)
    },
    hasTrackings() {
      return (this.delivery && this.delivery.trackings && this.delivery.trackings.length > 0) ? true : false
    },
    deliveryTrackings() {
      return this.delivery ? this.delivery.trackings : []
    },
    deliveryAddress() {
      return this.delivery ? this.delivery.address : {}
    },
    deliveryEstimatedDateTimeStart() {
      return this.delivery ? this.delivery.estimated_date_time_start : ""
    },
    deliveryEstimatedDateTimeEnd() {
      return this.delivery ? this.delivery.estimated_date_time_end : ""
    },
    hasAdditionalAttributes() {
      return (this.delivery && this.delivery.additional_attributes && Object.keys(this.delivery.additional_attributes).length > 0) ? true : false
    },
    deliveryAdditionalAttributes() {
      return this.delivery ? this.delivery.additional_attributes : {}
    },
    deliveryDocuments() {
      return this.delivery ? this.delivery.documents : []
    },
  }
}
</script>
<style lang="scss" scoped>
</style>
