let apiUrl = ""
let clientId = ""
let fedUrl = ""
let redirectUri = `${window.location.origin}/accessCode`
let xapikey = ""
let allowedOrigins = process.env.VUE_APP_ALLOWED_ORIGINS.split(',')

const parameters = {
  apiUrl,
  clientId,
  fedUrl,
  redirectUri,
  xapikey,
  allowedOrigins
};

const switchEnv = (env) => {
  switch (env) {
    case process.env.VUE_APP_PREPROD_ENV:
      parameters.apiUrl=process.env.VUE_APP_PREPROD_API_URL
      parameters.clientId=process.env.VUE_APP_PREPROD_CLIENT_ID
      parameters.fedUrl=process.env.VUE_APP_PREPROD_FED_URL
    break;

    case process.env.VUE_APP_PROD_ENV:
      parameters.apiUrl=process.env.VUE_APP_PROD_API_URL
      parameters.clientId=process.env.VUE_APP_PROD_CLIENT_ID
      parameters.fedUrl=process.env.VUE_APP_PROD_FED_URL
    break;

    default:
    break;
  }
}

export {
  parameters,
  switchEnv
}
