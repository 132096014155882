<template>
  <div>
    <app-bar/>
    <order-toolbar-test />
    <v-container fluid class="pt-0">
      <v-row v-show="orderIsValid">
        <v-col cols="12">
          <order :order="order"/>
        </v-col>
      </v-row>
      <div v-show="!orderIsValid" class="text-center grey--text text--lighten-2">
        <v-icon size="128" color="grey lighten-3">mdi-emoticon-confused-outline</v-icon>
        <div class="text-h6">Whoops ! We couldn't find anything</div>
        <div>Double check your order id or try something else.</div>
      </div>
      <div class="pt-4 pb-8">
        <app-footer />
      </div>
    </v-container>
  </div>
</template>

<script>
import AppBar from '@/components/app/AppBar.vue'
import AppFooter from '@/components/app/AppFooter.vue'
import OrderToolbarTest from '@/components/order/OrderToolbarTest.vue'
import Order from '@/components/order/Order.vue'
import DataInput from '@/components/common/DataInput.vue'
import {mapActions, mapGetters} from "vuex"
import {ORDER_NS} from "@/store/modules/order/constants"

export default {
  name: 'OrderPage',
  components: {
    AppBar,
    AppFooter,
    Order,
    OrderToolbarTest,
  },
  data: () => ({
    initialInput: ""
  }),
  computed: {
    ...mapGetters(ORDER_NS, [
      'order',
    ]),
    orderIsValid() {
      let result = false

      if ( typeof this.order === 'object' && !Array.isArray(this.order) && this.order !== null && this.order !== {} ) {
        result = true
      }
      return result
    }
  },
  methods: {
    ...mapActions(ORDER_NS, ['selectOrderToDisplay']),
    onInputChanged(input) {
      try {
        let order = JSON.parse(input)
        if ( typeof order === 'object' && !Array.isArray(order) && order !== null ) {
          this.selectOrderToDisplay(order)
        } else {
          this.selectOrderToDisplay({})
        }
      } catch {
        this.selectOrderToDisplay({})
      }
    }
  }
}
</script>
