<template>
  <v-expansion-panel-header class="px-4 py-2 themePanelHeader">
    <v-row no-gutters align="center">
      <v-col cols="6">
        <div class="subtitle-2 text-truncate">
          <copyable :text="cancellationChangeRequestId"/>
        </div>
        <div v-show="requesterType" class="caption themeText2--text">
          <span v-show="returnType" class="font-weight-bold">{{returnType}}</span>
          <span>{{requestType}}</span>
          <span> requested by </span>
          <span class="font-weight-bold">{{requesterType}}</span>
        </div>
        <div class="caption">
          <span class="themeText2--text">{{numberOfItems}} item{{pluralItems}}</span>
        </div>
      </v-col>
      <v-col cols="6" class="text-right">
        <div class="font-weight-bold themeText1--text">{{cancellationTotalAmount}} {{currencyCode}}</div>
        <div class="caption themeText2--text">{{cancellationUpdateDateTime}}</div>
        <div v-show="returnMethod" class="caption themeText2--text font-weight-bold">{{returnMethod}}</div>
      </v-col>
    </v-row>
  </v-expansion-panel-header>
</template>
<script>
import {getCurrencyDecimal} from "@/helpers/currency"
import Copyable from '@/components/common/Copyable.vue'

export default {
  name: "OrderCancellationHeader",
  props: {
    type: String,
    currencyCode: String,
    cancellation: Object,
  },
  components: {
    Copyable,
  },
  computed: {
    cancellationChangeRequestId() {
      return this.cancellation ? this.cancellation.change_request_id : ""
    },
    numberOfItems() {
      return (this.cancellation && this.cancellation.line_items) ? this.cancellation.line_items.length : 0
    },
    pluralItems() {
      return ( this.numberOfItems > 1 ) ? "s" : ""
    },
    cancellationTotalAmount() {
      const currencyCode = this.currencyCode
      const decimal = getCurrencyDecimal(currencyCode)
      return this.cancellation && this.cancellation.total_amount >= 0 ? this.cancellation.total_amount.toFixed(decimal) : (0).toFixed(decimal)
    },
    cancellationUpdateDateTime() {
      return this.cancellation ? this.cancellation.update_date_time : ""
    },
    requestType() {
      let type = ""

      if ( this.type == "return"  ) {
        if ( this.returnType ) {
          type = " return"
        } else {
          type = "Return"
        }
      } else {
        type = "Cancellation"
      }

      return type
    },
    requesterType() {
      return this.cancellation ? this.cancellation.requester_type_code : ""
    },
    returnType() {
      return ( this.type == "return" && this.cancellation ) ? this.cancellation.type_code : ""
    },
    returnMethod() {
      return ( this.type == "return" && this.cancellation && this.cancellation.method ) ? this.cancellation.method.type_code : ""
    },
  }
}
</script>
<style lang="scss" scoped>
</style>
