<template>
  <v-expansion-panel class="text-body-2">
    <v-expansion-panel-header class="themePanelHeader themeText1--text">
      <v-row no-gutters>
        <v-col cols="6">
          <div class="caption themeText1--text">
            <span>{{ fulfillerType }} - </span>
            <span>{{ fulfillerReference }}</span>
            <span v-show="fulfillerPartnerReference"> ( {{ fulfillerPartnerReference }} )</span>
          </div>
          <div class="font-weight-bold text-truncate">
            <copyable :text="fulfillmentReference"/>
          </div>
          <div class="caption themeText2--text">
            <span>
              <v-icon small color="themeText2">mdi-package-variant-closed</v-icon>
              <span> x {{numberOfProduct}}</span>
            </span>
            <span v-show="numberOfShippingFees">
              <span> / </span>
              <v-icon small color="themeText2">mdi-truck-fast</v-icon>
              <span> x {{numberOfShippingFees}}</span>
            </span>
            <span v-show="numberOfDelivery">
              <span> / </span>
              <v-icon small color="themeText2">mdi-arrow-right-bold-outline</v-icon>
              <span> x {{numberOfDelivery}}</span>
            </span>
            <span v-show="numberOfCancellation">
              <span> / </span>
              <v-icon small color="themeText2">mdi-cancel</v-icon>
              <span> x {{numberOfCancellation}}</span>
            </span>
            <span v-show="numberOfReturn">
              <span> / </span>
              <v-icon small color="themeText2">mdi-arrow-left-bold-outline</v-icon>
              <span> x {{numberOfReturn}}</span>
            </span>
          </div>
        </v-col>
        <v-col cols="6" class="text-right">
          <div class="font-weight-bold">{{fulfillmentTotalAmount}} {{order.currency_code}}</div>
          <div class="caption themeText2--text">{{fulfillment.update_date_time}}</div>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content color="themePanelContent" class="themeText1--text">
      <div v-show="numberOfItems">
        <order-line-item v-for="item in fulfillment.line_items" :key="item.reference" :item="item" :cancellation="false" :currencyCode="order.currency_code" />
        <v-divider class="themeDivider"></v-divider>
        <order-fulfillment-total :currencyCode="order.currency_code" :fulfillment="fulfillment" />
      </div>
      <div v-show="numberOfDelivery" class="pt-2">
        <div class="font-weight-bold pb-2">
          <v-icon color="themeText1">mdi-arrow-right-bold-outline</v-icon>
          <span> Deliveries</span>
        </div>
        <order-deliveries :fulfillment="fulfillment" :currencyCode="order.currency_code" />
      </div>
      <div v-show="numberOfCancellation" class="pt-2">
        <div class="font-weight-bold pb-2">
          <v-icon color="themeText1">mdi-cancel</v-icon>
          <span> Cancellations</span>
        </div>
        <order-cancellations type="cancellation" :currencyCode="order.currency_code" :cancellations="fulfillment.cancellations" />
      </div>
      <div v-show="numberOfReturn" class="pt-2">
        <div class="font-weight-bold pb-2">
          <v-icon color="themeText1">mdi-arrow-left-bold-outline</v-icon>
          <span> Returns</span>
        </div>
        <order-cancellations type="return" :currencyCode="order.currency_code" :cancellations="fulfillment.returns" />
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import {getCurrencyDecimal} from "@/helpers/currency"
import Copyable from '@/components/common/Copyable.vue'
import OrderLineItem from '@/components/order/OrderLineItem.vue'
import OrderFulfillmentTotal from '@/components/order/OrderFulfillmentTotal.vue'
import OrderDeliveries from '@/components/order/OrderDeliveries.vue'
import OrderCancellations from '@/components/order/OrderCancellations.vue'

export default {
  name: "OrderFulfillment",
  props: {
    fulfillment: Object,
    order: Object,
  },
  components: {
    Copyable,
    OrderLineItem,
    OrderFulfillmentTotal,
    OrderDeliveries,
    OrderCancellations,
  },
  computed: {
    fulfillmentReference() {
      return this.fulfillment ? this.fulfillment.reference : ""
    },
    fulfillmentLineItems() {
      return this.fulfillment && this.fulfillment.line_items ? this.fulfillment.line_items : []
    },
    fulfillmentTotalAmount() {
      const currencyCode = this.order.currency_code
      const decimal = getCurrencyDecimal(currencyCode)
      return this.fulfillment && this.fulfillment.total_amount ? this.fulfillment.total_amount.toFixed(decimal) : (0).toFixed(decimal)
    },
    numberOfItems() {
      return this.fulfillmentLineItems ? this.fulfillmentLineItems.length : 0
    },
    numberOfProduct() {
      let result = 0
      let lineItems = this.fulfillmentLineItems

      for (var i = 0; i < lineItems.length; i++) {
        if ( lineItems[i].type_code == "PRODUCT" ) {
          result = result + lineItems[i].quantity
        }
      }

      return result
    },
    numberOfShippingFees() {
      let result = 0
      let lineItems = this.fulfillmentLineItems

      for (var i = 0; i < lineItems.length; i++) {
        if ( lineItems[i].type_code == "SHIPPING_FEES" ) {
          result = result + lineItems[i].quantity
        }
      }

      return result
    },
    numberOfDelivery() {
      return this.fulfillment && this.fulfillment.deliveries ? this.fulfillment.deliveries.length : 0
    },
    numberOfReturn() {
      return this.fulfillment && this.fulfillment.returns ? this.fulfillment.returns.length : 0
    },
    numberOfCancellation() {
      return this.fulfillment && this.fulfillment.cancellations ? this.fulfillment.cancellations.length : 0
    },
    fulfillerType() {
      return this.fulfillment && this.fulfillment.fulfiller ? this.fulfillment.fulfiller.type_code : ""
    },
    fulfillerReference() {
      return this.fulfillment && this.fulfillment.fulfiller ? this.fulfillment.fulfiller.reference : ""
    },
    fulfillerPartnerReference() {
      return this.fulfillment && this.fulfillment.fulfiller ? this.fulfillment.fulfiller.partner_reference : ""
    },
  }
}
</script>
<style scoped>
</style>
