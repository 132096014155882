<template>
  <div v-show="hasAdjustments">
    <v-list class="themePanelHeader pa-0" v-show="!isOrderLevel">
      <v-list-group no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-row no-gutters>
              <v-col>
                <v-icon small color="primary">mdi-sale</v-icon>
                <span class="mx-2 themeText1--text">Adjustments</span>
              </v-col>
              <v-col class="text-right themeText1--text">
                <span>{{totalAdjustedAmount}} {{currencyCode}}</span>
              </v-col>
            </v-row>
            <div class="">
            </div>
          </v-list-item-content>
        </template>
        <order-adjustment-item v-for="(adjustment,index) in adjustments" :key="index" :adjustment="adjustment" :currencyCode="currencyCode" />
      </v-list-group>
    </v-list>
    <div v-show="isOrderLevel">
      <div class="text-h6 py-3 themeText3--text mt-4">
        <v-icon color="themeText3">mdi-sale</v-icon> Adjustments
      </div>
      <v-expansion-panels multiple>
        <order-adjustment v-for="(adjustment,index) in adjustments" :key="index" :adjustment="adjustment" :currencyCode="currencyCode" />
      </v-expansion-panels>
    </div>
  </div>
</template>
<script>
import {getCurrencyDecimal} from "@/helpers/currency"
import Copyable from '@/components/common/Copyable.vue'
import OrderAdjustment from '@/components/order/OrderAdjustment.vue'
import OrderAdjustmentItem from '@/components/order/OrderAdjustmentItem.vue'

export default {
  name: "OrderAdjustments",
  props: {
    adjustments: Array,
    currencyCode: String,
    level: String,
  },
  components: {
    Copyable,
    OrderAdjustment,
    OrderAdjustmentItem,
  },
  computed: {
    hasAdjustments() {
      return (this.adjustments && this.adjustments.length > 0) ? true : false
    },
    isOrderLevel() {
      return this.level == 'ORDER' ? true : false
    },
    totalAdjustedAmount() {
      const currencyCode = this.currencyCode
      const decimal = getCurrencyDecimal(currencyCode)
      let adjustedAmount = 0

      if ( this.hasAdjustments ) {
        for (let adjustment of this.adjustments) {
          adjustedAmount += (adjustment.amount?adjustment.amount:0)
        }
      }
      return adjustedAmount ? adjustedAmount.toFixed(decimal) : (0).toFixed(decimal)
    }
  },
}
</script>
<style lang="scss" scoped>
</style>
