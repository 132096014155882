<template>
  <v-container class="text-left">
    <requests-header />
    <v-row no-gutters class="mb-1">
      <v-col>
        <requests-menu-date-time />
      </v-col>
      <v-col class="text-right">
        <requests-menu-type-code />
        <requests-menu-requester-type-code />
        <requests-menu-status-code />
      </v-col>
    </v-row>
    <v-card class="body-2 themeCard">
      <template v-for="(request,index) in requests">
        <requests-line @click="" :key="request.id" :request="request" />
        <v-divider v-if="!isLastRequest(index,requests)"></v-divider>
      </template>
    </v-card>
    <div v-show="loadingFlag" class="text-center themeText2--text">
      <v-progress-circular size="100" width="10" indeterminate color="themeText2"/>
      <div class="text-h6">Loading change requests...</div>
    </div>

    <div v-show="!errorMessage && !loadingFlag && !hasResults" class="text-center themeText2--text">
      <v-icon size="128" color="themeText2">mdi-emoticon-confused-outline</v-icon>
      <div class="text-h6">Whoops ! We couldn't find anything</div>
      <div>Double check your search or try something else.</div>
    </div>

    <div v-show="errorMessage" class="text-center themeText2--text">
      <v-icon size="128" color="themeText2">mdi-emoticon-dead-outline</v-icon>
      <div class="text-h6">Whoops ! We have a problem...</div>
      <div>{{errorMessage}}</div>
    </div>

    <div class="caption" v-show="hasResults">
      <v-row no-gutters class="py-2">
        <v-col cols="7" class="themeText2--text">
          <span>{{requestNumbers}}</span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="themeText2--text text-right">
          <v-btn v-show="hasSeveralPages" color="themeText2" class="text-none" small text @click="loadMoreRequests()"><v-icon small class="pr-2">mdi-arrow-down</v-icon>Load more requests</v-btn>
        </v-col>
      </v-row>
    </div>

  </v-container>
</template>

<script>
import RequestsHeader from '@/components/requests/RequestsHeader.vue'
import RequestsMenuDateTime from '@/components/requests/RequestsMenuDateTime.vue'
import RequestsMenuTypeCode from '@/components/requests/RequestsMenuTypeCode.vue'
import RequestsMenuRequesterTypeCode from '@/components/requests/RequestsMenuRequesterTypeCode.vue'
import RequestsMenuStatusCode from '@/components/requests/RequestsMenuStatusCode.vue'
import RequestsLine from '@/components/requests/RequestsLine.vue'

import {mapActions, mapGetters} from "vuex"
import {REQUEST_NS} from "@/store/modules/request/constants"
import {ENV_NS} from "@/store/modules/env/constants"
import {MERCHANT_NS} from "@/store/modules/merchant/constants"

export default {
  name: 'RequestsList',
  components: {
    RequestsHeader,
    RequestsLine,
    RequestsMenuDateTime,
    RequestsMenuTypeCode,
    RequestsMenuRequesterTypeCode,
    RequestsMenuStatusCode,
  },
  computed: {
    ...mapGetters(REQUEST_NS, [
      'requests',
      'totalNumber',
      'showingNumber',
      'errorMessage',
      'loadingFlag',
      'searchQueryParams',
    ]),
    ...mapGetters(ENV_NS, [
      'env',
    ]),
    ...mapGetters(MERCHANT_NS, [
      'merchant',
    ]),
    hasSeveralPages() {
      let result = false

      if ( this.requests && this.requests.length > 0 && this.showingNumber < this.totalNumber && this.totalNumber > 20 ) {
        result = true
      }

      return result
    },
    hasResults() {
      let result = false
      if ( this.requests && this.requests.length > 0 ) {
        result = true
      }
      return result
    },
    requestNumbers() {
      let message = ""
      message += "Showing "
      message += this.showingNumber
      message += " of "
      message += this.totalNumber
      message += " request"

      if (this.totalNumber > 1) {
        message += "s"
      }

      return message
    }
  },
  methods: {
    ...mapActions(REQUEST_NS, [
      'fetchMoreRequests',
    ]),
    loadMoreRequests() {
      const merchantId = this.merchant.id
      let query = this.$route.query
      let page = Math.floor(this.requests.length / 20)
      let params = this.searchQueryParams
      params.env = this.env
      params.page = page

      this.fetchMoreRequests({merchantId, params})
    },
    isLastRequest(index,requests) {
      return (index == requests.length - 1)
    }
  }
}
</script>
