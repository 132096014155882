<template>
  <div>
    <div class="text-h6 py-3 themeText3--text">
      <v-icon color="themeText3">mdi-credit-card-outline</v-icon>
      <span> Refund</span>
    </div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header class="themePanelHeader">
          <v-row no-gutters align="center" class="text-body-2">
            <v-col cols="6">
              <div class="caption">
                <div class="themeText1--text">{{refundReasonCode}}</div>
                <div class="themeText2--text">{{refundAmount}} {{refundCurrency}}</div>
              </div>
            </v-col>
            <v-col cols="6" class="text-right">
              <request-refund-status :code="refundStatusCode" />
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="themePanelContent text-body-2">
          <v-container>
            <div>
              <span class="themeText2--text">Linked request : </span>
              <copyable :text="request.linked_request_id" />
            </div>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import Copyable from '@/components/common/Copyable.vue'
import RequestRefundStatus from '@/components/request/RequestRefundStatus.vue'

export default {
  name: "RequestRefund",
  props: {
    request: Object,
  },
  components: {
    Copyable,
    RequestRefundStatus
  },
  computed: {
    refundReasonCode(){
      return (this.request && this.request.refund) ? this.request.refund.reason_code : ''
    },
    refundAmount(){
      return (this.request && this.request.refund) ? this.request.refund.amount : ''
    },
    refundCurrency() {
      return (this.request && this.request.refund) ? this.request.refund.currency_code : ''
    },
    refundStatusCode(){
      return (this.request && this.request.refund) ? this.request.refund.status_code : ''
    }
  },
  methods: {
  }
}
</script>
<style scoped>
</style>
