<template>
  <div v-show="hasNotes">
    <div class="text-h6 py-3 themeText3--text mt-4"><v-icon color="themeText3">mdi-comment-processing-outline</v-icon> Notes</div>
    <v-expansion-panels multiple>
      <order-note v-for="note in order.notes" :key="note.id" :note="note" />
    </v-expansion-panels>
  </div>
</template>
<script>
import OrderNote from '@/components/order/OrderNote.vue'

export default {
  name: "OrderNotes",
  props: {
    order: Object,
  },
  components: {
    OrderNote,
  },
  computed: {
    hasNotes() {
      return (this.order && this.order.notes && this.order.notes.length > 0) ? true : false
    }
  },
}
</script>
<style scoped>
</style>
