<template>
  <v-card v-show="rejected" outlined class="themePanelHeader themeText2--text">
    <v-card-text class="caption">
      <div class="font-weight-bold themeText2--text">
        <v-icon small color="themeText2" class="mr-1">mdi-alert-circle-outline</v-icon>
        <span>Rejected information</span>
      </div>
      <div>
        <span class="themeText2--text">Reason: </span>
        <span class="themeText1--text">{{rejectedReasonCode}}</span>
      </div>
      <div>
        <span class="themeText2--text">Message: </span>
        <span class="themeText1--text">{{rejectedMessage}}</span>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "RequestRejected",
  props: {
    rejected: Object,
  },
  computed: {
    rejectedReasonCode() {
      return this.rejected ? this.rejected.reason_code : ''
    },
    rejectedMessage() {
      return this.rejected ? this.rejected.message : ''
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
