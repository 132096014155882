import {getRequests,getRequestById} from "@/helpers/apiV1";

const actions = {
    fetchRequests({getters, commit},{merchantId, params}) {
      commit('toggleLoadingFlag')
      commit('updateTotalNumber',0)
      commit('updateShowingNumber',0)
      commit('updateRequests',[])
      commit('updateErrorMessage',"")
      getRequests(merchantId,params)
      .then((response) => {
        commit('updateRequests',response.data)
        commit('updateShowingNumber',response.data.length)
        if ( response.headers['content-range'] ) {
          commit('updateTotalNumber',response.headers['content-range'].split("/").pop())
        } else {
          commit('updateTotalNumber',response.data.length)
        }
        commit('toggleLoadingFlag')
      })
      .catch((error) => {
          console.log(JSON.stringify(error))
          commit('toggleLoadingFlag')
          commit('updateErrorMessage',error)
      })
    },
    fetchMoreRequests({getters, commit},{merchantId, params}) {
      commit('toggleLoadingFlag')
      commit('updateErrorMessage',"")
      getRequests(merchantId,params)
      .then((response) => {
        commit('addRequests',response.data)
        commit('increaseShowingNumber',response.data.length)
        commit('toggleLoadingFlag')
      })
      .catch((error) => {
          console.log(JSON.stringify(error))
          commit('toggleLoadingFlag')
          commit('updateErrorMessage',error)
      })
    },
    fetchRequestById({getters, commit},{merchantId, requestId}) {
      commit('toggleLoadingFlag')
      commit('updateRequest',{})
      commit('updateErrorMessage',"")
      getRequestById(merchantId,requestId)
      .then((response) => {
        commit('updateRequest',response.data)
        commit('toggleLoadingFlag')
      })
      .catch((error) => {
          console.log(JSON.stringify(error))
          commit('toggleLoadingFlag')
          commit('updateErrorMessage',error)
      })
    },
    selectRequestToDisplay({getters, commit},request) {
      commit('updateRequest',request)
    },
    updateSearchQueryParams({commit},queryParams) {
      commit('updateSearchQueryParams',queryParams)
      commit('updateSearchQueryParamsUrl')
    },
    addSearchQueryParam({commit}, {key,value}) {
      commit('addSearchQueryParam',{key,value})
      commit('updateSearchQueryParamsUrl')
    },
    removeSearchQueryParam({commit}, key) {
      commit('removeSearchQueryParam',key)
      commit('updateSearchQueryParamsUrl')
    },
    updateRequestsURL({state},{vue}) {
      let url = vue.$route.path

      if ( state.searchQueryParamsURL ) {
        url = url + '?' + state.searchQueryParamsURL
      }

      history.pushState({},null,url)
    },
}

export default actions;
