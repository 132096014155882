<template>
  <v-app-bar dense flat class="themeAppBar">
    <router-link :to="{ name: 'Home'}">
      <v-img :src="appLogo" contain height="48" width="48" position="left" />
    </router-link>
    <v-chip v-show="env" @click="gotoEnv()" dark small class="font-weight-bold ml-2" :color="envColor"><v-icon small left>mdi-earth</v-icon>{{env}}</v-chip>
    <v-chip v-show="merchant.id" @click="gotoMerchant()" dark small class="font-weight-bold ml-2" :color="envColor"><v-icon small left>mdi-shopping-outline</v-icon>{{merchant.id}}</v-chip>
    <v-spacer></v-spacer>
    <v-chip v-show="env" label outlined small class="font-weight-bold" color="themeText2"><v-icon small left>mdi-account</v-icon>{{user}}</v-chip>
    <div class="pl-2">
      <app-theme-switch />
    </div>
  </v-app-bar>
</template>
<script>
import {mapActions, mapGetters} from "vuex"
import {ENV_NS} from "@/store/modules/env/constants"
import {MERCHANT_NS} from "@/store/modules/merchant/constants"
import {USER_NS} from "@/store/modules/user/constants"
import {THEME_NS} from "@/store/modules/theme/constants"
import AppThemeSwitch from '@/components/app/AppThemeSwitch.vue'

export default {
  name: "AppBar",
  data: () => ({

  }),
  components: {
    AppThemeSwitch,
  },
  computed: {
    ...mapGetters(ENV_NS, [
      'env'
    ]),
    ...mapGetters(MERCHANT_NS, [
      'merchant'
    ]),
    ...mapGetters(USER_NS, [
      'userName'
    ]),
    ...mapGetters(THEME_NS, [
      'themeLogo'
    ]),
    envColor() {
      return (this.env=='prod') ? 'themeChip1' : 'themeChip2'
    },
    appLogo() {
      return require('@/assets/' + this.themeLogo + '.svg')
    },
    user() {
      return this.userName(this.env)
    },
  },
  methods: {
    gotoEnv() {
      this.$router.push({ name: 'Merchants', params: { env: this.env } })
    },
    gotoMerchant() {
      this.$router.push({ name: 'Merchant', params: { env: this.env, merchantId: this.merchant.id } })
    }
  }
}
</script>
<style scoped>
</style>
