<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="themePanelHeader themeText1--text">
      <v-row no-gutters align="center" class="text-body-2">
        <v-col cols="6">
          <div class="font-weight-bold text-truncate">
            <copyable :text="adjustmentReference" />
          </div>
        </v-col>
        <v-col cols="6" class="text-right">
          <div>{{adjustmentAmount}} {{currencyCode}}</div>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="themePanelContent text-body-2 themeText1--text">
      <v-container>
        <div class="caption">{{adjustmentTypeCode}}</div>
        <div class="caption themeText2--text">{{adjustmentReasonCode}}</div>
        <div class="caption themeText2--text">{{adjustmentMessage}}</div>
      </v-container>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import {getCurrencyDecimal} from "@/helpers/currency"
import Copyable from '@/components/common/Copyable.vue'

export default {
  name: "OrderAdjustment",
  props: {
    adjustment: Object,
    currencyCode: String,
  },
  components: {
    Copyable,
  },
  computed: {
    adjustmentReference() {
      return this.adjustment ? this.adjustment.reference : ""
    },
    adjustmentAmount() {
      const currencyCode = this.currencyCode
      const decimal = getCurrencyDecimal(currencyCode)
      return this.adjustment && this.adjustment.amount >=0 ? this.adjustment.amount.toFixed(decimal) : (0).toFixed(decimal)
    },
    adjustmentTypeCode() {
      return this.adjustment ? this.adjustment.type_code : ""
    },
    adjustmentReasonCode() {
      return this.adjustment ? this.adjustment.reason_code : ""
    },
    adjustmentMessage() {
      return this.adjustment ? this.adjustment.message : ""
    }
  },
}
</script>
<style lang="scss" scoped>
</style>
