import {THEME} from "@/store/modules/theme/constants"
import defaultLight from '@/themes/defaultLight'
import defaultDark from '@/themes/defaultDark'

const state = {
  theme : '',
}

const getters = {
  theme(state) {
    return state.theme
  },
  themeLogo(state) {
    return state.theme == 'dark' ? 'logo-dark' : 'logo-light'
  },
  themeIsDark(state) {
    return state.theme == 'dark'
  },
}

const mutations = {
  updateTheme(state, theme) {
    state.theme = theme
    localStorage.setItem(THEME,theme)
  }
}

const actions = {
  loadTheme({commit,state}) {
    let theme = localStorage.getItem(THEME)

    switch (theme) {
      case 'light':
      case 'dark':
      commit('updateTheme', theme)
      break;
      default:
      commit('updateTheme', 'light')
      break;
    }
  },
  switchTheme({commit,state}) {
    let theme = ''

    if ( state.theme && state.theme == 'light' ) {
      theme = 'dark'
    } else {
      theme = 'light'
    }

    commit('updateTheme', theme)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
