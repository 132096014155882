<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon small @click="toggleTheme()" v-bind="attrs" v-on="on">
        <v-icon small color="themeText2">{{themeIcon}}</v-icon>
      </v-btn>
    </template>
    <v-icon left color="white">mdi-theme-light-dark</v-icon>
    <!-- <v-icon left color="white">mdi-invert-colors</v-icon> -->
    <!-- <v-icon left color="white">mdi-circle-half-full</v-icon> -->
    <span> Switch theme</span>
  </v-tooltip>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { THEME_NS } from "@/store/modules/theme/constants";
import defaultLight from "@/themes/defaultLight";
import defaultDark from "@/themes/defaultDark";

export default {
  name: "AppThemeSwitch",
  computed: {
    ...mapGetters(THEME_NS, ["theme","themeIsDark"]),
    themeIcon() {
      // return this.themeIsDark ? 'mdi-water' : 'mdi-water-outline'
      // return this.themeIsDark ? 'mdi-circle' : 'mdi-circle-outline'
      // return this.themeIsDark ? 'mdi-plus-circle' : 'mdi-minus-circle-outline'
      return this.themeIsDark ? 'mdi-power-sleep' : 'mdi-white-balance-sunny'
    },
  },
  methods: {
    ...mapActions(THEME_NS, ["switchTheme"]),
    toggleTheme() {
      let theme = {};
      this.switchTheme();

      if (this.theme == "dark") {
        theme = defaultDark;
      } else {
        theme = defaultLight;
      }

      this.$vuetify.theme.themes.light = theme;
      document.body.style.backgroundColor = theme.themeBackground;
    },
  },
};
</script>
<style scoped>
</style>
