<template>
  <v-toolbar dense flat color="transparent">
    <v-btn color="themeText2" class="text-none" small text to="/"><v-icon small class="pr-2">mdi-arrow-left</v-icon>Home</v-btn>
    <v-spacer></v-spacer>
    <v-btn color="themeText2" class="text-none" small text @click="copyUrlToClipboard()"><v-icon small class="pr-2">{{icon}}</v-icon>Url</v-btn>
  </v-toolbar>
</template>
<script>

export default {
  name: "MerchantsToolbar",
  data: () => ({
    icon: "mdi-clipboard-text-outline",
    defaultIcon: "mdi-clipboard-text-outline",
    copiedIcon: "mdi-clipboard-check-outline",
  }),
  methods: {
    copyUrlToClipboard() {
      this.icon = this.copiedIcon
      navigator.clipboard.writeText(window.location.href)
      setTimeout(() => {  this.icon = this.defaultIcon }, 250)
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
