<template>
</template>

<script>

import {getFedToken} from "@/helpers/apiV1"
import {parameters} from "@/helpers/parameters"

import {mapGetters} from "vuex"
import {ENV_NS} from "@/store/modules/env/constants"
import {USER_NS} from "@/store/modules/user/constants"

export default {
  name: "FedPage",
  computed: {
    ...mapGetters(ENV_NS,['env']),
    ...mapGetters(USER_NS,['isTokenValid'])
  },
  mounted() {
    const query = this.$route.query
    const code = (query ? query.code : "")
    if (!this.isTokenValid(this.env)) {
      getFedToken(code)
      .then(() => {
        const originUrlBackup = localStorage.getItem('origin_url_backup');
        if (parameters.allowedOrigins.some(origin => originUrlBackup.includes(origin))) {
          location.replace(originUrlBackup);
        } else {
          location.replace('https://oneom-dashboard.decathlon.net');
        }
      })
      .catch((error)=> {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>

</style>
