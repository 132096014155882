<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.themeBackground }">
    <router-view></router-view>
  </v-app>
</template>

<script>
import {mapGetters,mapActions} from "vuex"
import {THEME_NS} from "@/store/modules/theme/constants"
import defaultLight from '@/themes/defaultLight'
import defaultDark from '@/themes/defaultDark'

export default {
  name: 'App',
  data: () => ({
    //
  }),
  created() {
    let theme = {}
    this.loadTheme()

    if ( this.theme == 'dark' ) {
      theme = defaultDark
    } else {
      theme = defaultLight
    }

    this.$vuetify.theme.themes.light = theme
    document.body.style.backgroundColor = theme.themeBackground
  },
  computed: {
    ...mapGetters(THEME_NS, [
      'theme'
    ]),
  },
  methods: {
    ...mapActions(THEME_NS, [
      'loadTheme'
    ]),
  }
};
</script>
