<template>
  <div>
    <app-bar/>
    <merchants-toolbar />
    <v-container fluid>
      <v-row class="text-center">
        <v-col cols="12">
          <merchants-list />
        </v-col>
      </v-row>
      <div class="pt-2 pb-8">
        <app-footer />
      </div>
    </v-container>
  </div>
</template>

<script>
import AppBar from '@/components/app/AppBar.vue'
import AppFooter from '@/components/app/AppFooter.vue'
import MerchantsToolbar from '@/components/merchants/MerchantsToolbar.vue'
import MerchantsList from '@/components/merchants/MerchantsList.vue'

import {mapActions} from "vuex"
import {MERCHANT_NS} from "@/store/modules/merchant/constants"

export default {
  name: 'MerchantsPage',
  components: {
    AppBar,
    AppFooter,
    MerchantsToolbar,
    MerchantsList
  },
  created() {
    let params = {}
    params.env = this.$route.params.env

    this.fetchMerchants({params})
    this.selectMerchantById()
  },
  methods: {
    ...mapActions(MERCHANT_NS, ['fetchMerchants','selectMerchantById'])
  }
}
</script>
