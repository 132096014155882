<template>
<v-chip v-show="conditionCode" x-small label :color="conditionColor" text-color="white">
  <v-icon left small>{{conditionIcon}}</v-icon>
  <span class="font-weight-bold">{{conditionCode}}</span>
</v-chip>
</template>
<script>
export default {
  name: "OrderLineItemCondition",
  props: {
    code: String,
  },
  computed: {
    conditionCode() {
      let statusCode = this.code
      if (statusCode) {
        statusCode = statusCode.split('_').join(' ')
      }
      return statusCode
    },
    conditionColor() {
      let conditionCode = this.code
      let conditionColor = ""

      switch (conditionCode) {
        case "NEW":
        conditionColor = "themeChip1"
        break

        case "REFURBISHED_LIKE_NEW":
        case "REFURBISHED_VERY_GOOD":
        case "REFURBISHED_GOOD":
        case "REFURBISHED_ACCEPTABLE":
        conditionColor = "themeChip5"
        break

        case "USED_LIKE_NEW":
        case "USED_VERY_GOOD":
        case "USED_GOOD":
        case "USED_ACCEPTABLE":
        conditionColor = "themeChip5"
        break

        default:
        conditionColor = ""
        break
      }

      return conditionColor
    },
    conditionIcon() {
      let conditionCode = this.code
      let conditionIcon = ""

      switch (conditionCode) {
        case "NEW":
        conditionIcon = "mdi-check-decagram"
        break

        case "REFURBISHED_LIKE_NEW":
        case "REFURBISHED_VERY_GOOD":
        case "REFURBISHED_GOOD":
        case "REFURBISHED_ACCEPTABLE":
        conditionIcon = "mdi-autorenew"
        break

        case "USED_LIKE_NEW":
        case "USED_VERY_GOOD":
        case "USED_GOOD":
        case "USED_ACCEPTABLE":
        conditionIcon = "mdi-autorenew"
        break

        default:
        conditionIcon = "mdi-help-circle-outline"
        break
      }

      return conditionIcon
    }
  },
}
</script>
<style lang="scss" scoped>
</style>
