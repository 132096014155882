const getters = {
  merchant(state) {
    return state.merchant
  },
  merchants(state) {
    return state.merchants
  }
}

export default getters;
