<template>
  <v-container class="text-left">
    <orders-header />
    <v-row no-gutters class="mb-1">
      <v-col>
        <orders-menu-creation-date-time />
        <orders-menu-update-date-time />
      </v-col>
      <v-col class="text-right">
        <orders-menu-status-code />
      </v-col>
    </v-row>
    <v-card class="body-2 themeCard">
      <template v-for="(order,index) in orders">
        <orders-line @click="" :key="order.id" :order="order" />
        <v-divider class="themeDivider" v-if="!isLastOrder(index,orders)"></v-divider>
      </template>
    </v-card>

    <div v-show="loadingFlag" class="text-center themeText2--text">
      <v-progress-circular size="100" width="10" indeterminate color="themeText2"/>
      <div class="text-h6">Loading orders...</div>
    </div>

    <div v-show="!errorMessage && !loadingFlag && !hasResults" class="text-center themeText2--text">
      <v-icon size="128" color="themeText2">mdi-emoticon-confused-outline</v-icon>
      <div class="text-h6">Whoops ! We couldn't find anything</div>
      <div>Double check your search or try something else.</div>
    </div>

    <div v-show="errorMessage" class="text-center themeText2--text">
      <v-icon size="128" color="themeText2">mdi-emoticon-dead-outline</v-icon>
      <div class="text-h6">Whoops ! We have a problem...</div>
      <div>{{errorMessage}}</div>
    </div>

    <div class="caption" v-show="hasResults">
      <v-row no-gutters class="py-2">
        <v-col cols="7" class="themeText2--text">
          <span>{{orderNumbers}}</span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="themeText2--text text-right">
          <v-btn v-show="hasSeveralPages" color="themeText2" class="text-none" small text @click="loadMoreOrders()"><v-icon small class="pr-2">mdi-arrow-down</v-icon>Load more orders</v-btn>
        </v-col>
      </v-row>
    </div>

  </v-container>
</template>

<script>
import OrdersHeader from '@/components/orders/OrdersHeader.vue'
import OrdersMenuCreationDateTime from '@/components/orders/OrdersMenuCreationDateTime.vue'
import OrdersMenuUpdateDateTime from '@/components/orders/OrdersMenuUpdateDateTime.vue'
import OrdersMenuStatusCode from '@/components/orders/OrdersMenuStatusCode.vue'
import OrdersLine from '@/components/orders/OrdersLine.vue'

import {mapActions, mapGetters} from "vuex"
import {ORDER_NS} from "@/store/modules/order/constants"
import {ENV_NS} from "@/store/modules/env/constants"
import {MERCHANT_NS} from "@/store/modules/merchant/constants"

export default {
  name: 'Orders',
  components: {
    OrdersHeader,
    OrdersLine,
    OrdersMenuCreationDateTime,
    OrdersMenuUpdateDateTime,
    OrdersMenuStatusCode,
  },
  computed: {
    ...mapGetters(ORDER_NS, [
      'orders',
      'totalNumber',
      'showingNumber',
      'errorMessage',
      'loadingFlag',
      'searchQueryParams',
    ]),
    ...mapGetters(ENV_NS, [
      'env',
    ]),
    ...mapGetters(MERCHANT_NS, [
      'merchant',
    ]),
    hasSeveralPages() {
      let result = false

      if ( this.orders && this.orders.length > 0 && this.showingNumber < this.totalNumber && this.totalNumber > 20 ) {
        result = true
      }

      return result
    },
    hasResults() {
      let result = false
      if ( this.orders && this.orders.length > 0 ) {
        result = true
      }
      return result
    },
    orderNumbers() {
      let message = ""
      message += "Showing "
      message += this.showingNumber
      message += " of "
      message += this.totalNumber
      message += " order"

      if (this.totalNumber > 1) {
        message += "s"
      }

      return message
    }
  },
  methods: {
    ...mapActions(ORDER_NS, [
      'fetchMoreOrders',
    ]),
    loadMoreOrders() {
      const merchantId = this.merchant.id
      let query = this.$route.query
      let page = Math.floor(this.orders.length / 20)
      let params = this.searchQueryParams
      params.env = this.env
      params.page = page

      this.fetchMoreOrders({merchantId, params})
    },
    isLastOrder(index,orders) {
      return (index == orders.length - 1)
    }
  }
}
</script>
