<template>
  <v-menu v-model="menu" :close-on-content-click="false" offset-y bottom left transition="scale-transition" origin="top right">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="themeText2" class="text-none" small text v-bind="attrs" v-on="on">
        <v-icon small class="pr-2" :color="iconColor">{{icon}}</v-icon>Status
      </v-btn>
    </template>
    <v-card class="themeCard">
      <div class="pa-2 py-4 themePanelHeader themeText2--text text-center body-2">Select the order status to focus on</div>
      <v-list class="themeCard" :dark="themeIsDark">
        <v-list-item>
          <v-list-item-action>
            <v-switch inset dense v-model="created" @change="toggleStatus()" color="primary"></v-switch>
          </v-list-item-action>
          <v-list-item-title @click="toggleStatus('CREATED')">
            <order-status code="CREATED"/>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-switch inset dense v-model="validated" @change="toggleStatus()" color="primary"></v-switch>
          </v-list-item-action>
          <v-list-item-title @click="toggleStatus('VALIDATED')">
            <order-status code="VALIDATED"/>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-switch inset dense v-model="validationError" @change="toggleStatus()" color="primary"></v-switch>
          </v-list-item-action>
          <v-list-item-title @click="toggleStatus('VALIDATION_ERROR')">
            <order-status code="VALIDATION_ERROR"/>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-switch inset dense v-model="completed" @change="toggleStatus()" color="primary"></v-switch>
          </v-list-item-action>
          <v-list-item-title @click="toggleStatus('COMPLETED')">
            <order-status code="COMPLETED"/>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-switch inset dense v-model="cancelled" @change="toggleStatus()" color="primary"></v-switch>
          </v-list-item-action>
          <v-list-item-title @click="toggleStatus('CANCELLED')">
            <order-status code="CANCELLED"/>
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <div class="themePanelHeader pa-2">
        <v-btn color="themeText2" text block class="text-none" @click="removeAllStatusFilters()">Remove all status filters</v-btn>
      </div>
    </v-card>
  </v-menu>
</template>
<script>
import OrderStatus from '@/components/order/OrderStatus.vue'
import {mapGetters,mapActions} from "vuex"
import {MERCHANT_NS} from "@/store/modules/merchant/constants"
import {ORDER_NS} from "@/store/modules/order/constants"
import {THEME_NS} from "@/store/modules/theme/constants"

export default {
  name: "OrdersMenuStatusCode",
  components: {
    OrderStatus,
  },
  data: () => ({
    menu: false,
    statusCodeQuery: "",
    created: false,
    validated: false,
    validationError: false,
    completed: false,
    cancelled: false
  }),
  created() {
    if (this.searchQueryParams && this.searchQueryParams['status_code']) {
      this.statusCodeQuery = this.searchQueryParams['status_code']
      let statusCodes = this.statusCodeQuery.split(',')

      for (let status of statusCodes) {
        switch (status) {
          case "CREATED":
          this.created = !this.created
          break;
          case "VALIDATED":
          this.validated = !this.validated
          break;
          case "VALIDATION_ERROR":
          this.validationError = !this.validationError
          break;
          case "COMPLETED":
          this.completed = !this.completed
          break;
          case "CANCELLED":
          this.cancelled = !this.cancelled
          break;
          default:
          break;
        }
      }
    }
  },
  computed: {
    ...mapGetters(THEME_NS, ['themeIsDark']),
    ...mapGetters(MERCHANT_NS, [
      'merchant'
    ]),
    ...mapGetters(ORDER_NS, [
      'searchQueryParams'
    ]),
    icon() {
      return (this.statusCodeQuery == "") ? 'mdi-label-outline' : 'mdi-label'
    },
    iconColor() {
      return (this.statusCodeQuery == "") ? 'themeText2' : 'primary'
    },
  },
  methods: {
    ...mapActions(ORDER_NS, ['fetchOrders','addSearchQueryParam','removeSearchQueryParam','updateOrdersURL']),
    removeAllStatusFilters() {
      this.created = false
      this.validated = false
      this.validationError = false
      this.completed = false
      this.cancelled = false
      this.updateStatusCodeQuery()
      this.updateSearchResults()
    },
    toggleStatus(status) {
      switch (status) {
        case "CREATED":
        this.created = !this.created
        break;
        case "VALIDATED":
        this.validated = !this.validated
        break;
        case "VALIDATION_ERROR":
        this.validationError = !this.validationError
        break;
        case "COMPLETED":
        this.completed = !this.completed
        break;
        case "CANCELLED":
        this.cancelled = !this.cancelled
        break;
        default:
        break;
      }
      this.updateStatusCodeQuery()
      this.updateSearchResults()
    },
    updateStatusCodeQuery() {
      this.statusCodeQuery = ""

      if (this.created) {
        this.statusCodeQuery += "CREATED"
        if (this.validated || this.validationError || this.completed || this.cancelled) {
          this.statusCodeQuery += ","
        }
      }

      if (this.validated) {
        this.statusCodeQuery += "VALIDATED"
        if (this.validationError || this.completed || this.cancelled) {
          this.statusCodeQuery += ","
        }
      }

      if (this.validationError) {
        this.statusCodeQuery += "VALIDATION_ERROR"
        if (this.completed || this.cancelled) {
          this.statusCodeQuery += ","
        }
      }

      if (this.completed) {
        this.statusCodeQuery += "COMPLETED"
        if (this.cancelled) {
          this.statusCodeQuery += ","
        }
      }

      if (this.cancelled) {
        this.statusCodeQuery += "CANCELLED"
      }
    },
    updateSearchResults() {
      if ( this.statusCodeQuery ) {
        this.addSearchQueryParam({'key':'status_code','value':this.statusCodeQuery})
      } else {
        this.removeSearchQueryParam('status_code')
      }

      this.updateOrdersURL({'vue':this})
      this.fetchOrders({'merchantId':this.merchant.id, 'params':this.searchQueryParams})
    }
  },
}
</script>
