<template>
  <div>
    <app-bar/>
    <merchant-toolbar />
    <v-container fluid>
      <v-row class="text-center">
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              <v-hover v-slot="{ hover }">
                <v-card :elevation="hover ? 4 : 2" @click="gotoOrders()" class="fill-height themeCard">
                  <v-card-text>
                    <div>
                      <v-icon size="48" color="themeText2">mdi-text-box-outline</v-icon>
                    </div>
                    <div class="title font-weight-thin text-center themeText1--text">Orders</div>
                    <div class="caption themeText1--text">Get information about orders.</div>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
            <v-col cols="6">
              <v-hover v-slot="{ hover }">
                <v-card :elevation="hover ? 4 : 2" @click="gotoChangeRequests()" class="fill-height themeCard">
                  <v-card-text>
                    <div>
                      <v-icon size="48" color="themeText2">mdi-square-edit-outline</v-icon>
                    </div>
                    <div class="title font-weight-thin text-center themeText1--text">Change requests</div>
                    <div class="caption themeText1--text">Get information about requests.</div>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="pt-2 pb-8">
        <app-footer />
      </div>
    </v-container>
  </div>
</template>

<script>
import AppBar from '@/components/app/AppBar.vue'
import AppFooter from '@/components/app/AppFooter.vue'
import MerchantToolbar from '@/components/merchant/MerchantToolbar.vue'
import {mapActions,mapGetters} from "vuex"
import {MERCHANT_NS} from "@/store/modules/merchant/constants"

export default {
  name: 'MerchantPage',
  components: {
    AppBar,
    AppFooter,
    MerchantToolbar,
  },
  created() {
    const merchantId = this.$route.params.merchantId
    let params = {}
    params.env = this.$route.params.env

    if ( !this.merchant.id && this.merchant.id != merchantId ) {
      this.fetchMerchants({params})
      this.selectMerchantById(merchantId)
    }
  },
  computed: {
    ...mapGetters(MERCHANT_NS, [
      'merchant'
    ]),
  },
  methods: {
    ...mapActions(MERCHANT_NS, ['fetchMerchants','selectMerchantById']),
    gotoOrders() {
      this.$router.push({ name: 'Orders', params: { merchantId: this.merchant.id } })
    },
    gotoChangeRequests() {
      this.$router.push({ name: 'ChangeRequests', params: { merchantId: this.merchant.id } })
    },
  }
}
</script>
