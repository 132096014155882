<template>
  <div v-show="hasPayments">
    <div class="text-h6 py-3 themeText3--text mt-4"><v-icon color="themeText3">mdi-credit-card-outline</v-icon> Payments</div>
    <v-expansion-panels multiple>
      <order-payment v-for="payment in order.payments" :key="payment.reference" :payment="payment" :currencyCode="order.currency_code" />
    </v-expansion-panels>
  </div>
</template>
<script>
import OrderPayment from '@/components/order/OrderPayment.vue'

export default {
  name: "OrderPayments",
  props: {
    order: Object,
  },
  components: {
    OrderPayment,
  },
  computed: {
    hasPayments() {
      return (this.order && this.order.payments && this.order.payments.length > 0)
    }
  }
}
</script>
<style scoped>
</style>
