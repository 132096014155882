<template>
  <div>
    <v-row no-gutters align="center">
      <v-col>
        <div class="text-h6 py-3 themeText3--text">
          <v-icon color="themeText3">mdi-text-box-outline</v-icon>
          <span> Order</span>
        </div>
      </v-col>
      <v-col>
        <div class="text-right">
          <v-btn color="themeText2" class="text-none" small text exact @click="goToOrderDetails()">
            <span>Order Details</span>
            <v-icon small class="pl-2">mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-card class="themeCard">
      <v-list-item class="body-2 py-3">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col>
                <div class="font-weight-bold">
                  <copyable :text="orderReference" />
                </div>
                <div>
                  <copyable :text="orderId" />
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-list-item>
    </v-card>
  </div>
</template>
<script>
import Copyable from '@/components/common/Copyable.vue'
import {mapActions,mapGetters} from "vuex"
import {ENV_NS} from "@/store/modules/env/constants"
import {MERCHANT_NS} from "@/store/modules/merchant/constants"
import {ORDER_NS} from "@/store/modules/order/constants"

export default {
  name: "RequestOrder",
  props: {
    order: Object,
  },
  components: {
    Copyable,
  },
  computed: {
    ...mapGetters(ENV_NS, ['env']),
    ...mapGetters(MERCHANT_NS, ['merchant']),
    orderId() {
      return (this.order) ? this.order.id : ""
    },
    orderReference() {
      return (this.order) ? this.order.reference : ""
    },
  },
  methods: {
    ...mapActions(ORDER_NS, ['fetchOrderById','selectOrderToDisplay']),
    goToOrderDetails() {
      const env = this.env
      const merchantId = this.merchant.id
      const orderReference = this.orderReference

      let route = this.$router.resolve({
        'name':'Order',
        'params':{
          'env':env,
          'merchantId':merchantId,
          'id':orderReference
        }
      })

      window.open(route.href, '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
