import Vue from 'vue'
import Vuex from 'vuex'

import themeModule from "@/store/modules/theme"
import envModule from "@/store/modules/env"
import userModule from "@/store/modules/user"
import merchantModule from "@/store/modules/merchant"
import orderModule from "@/store/modules/order"
import requestModule from "@/store/modules/request"

import {THEME_NS} from "@/store/modules/theme/constants"
import {ENV_NS} from "@/store/modules/env/constants"
import {USER_NS} from "@/store/modules/user/constants"
import {MERCHANT_NS} from "@/store/modules/merchant/constants"
import {ORDER_NS} from "@/store/modules/order/constants"
import {REQUEST_NS} from "@/store/modules/request/constants"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    [THEME_NS]: themeModule,
    [ENV_NS]: envModule,
    [USER_NS]: userModule,
    [MERCHANT_NS]: merchantModule,
    [ORDER_NS]: orderModule,
    [REQUEST_NS]: requestModule,
  }
})
