<template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="themeText2" class="text-none" v-bind="attrs" v-on="on" small text>
          <v-icon small class="pr-2">mdi-chevron-down</v-icon>
          More
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="downloadRequest()">
          <v-icon small class="pr-2">mdi-arrow-down-bold</v-icon>
          <v-list-item-title class="body-2">
            <span>Download JSON</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import {mapGetters} from "vuex"
import {REQUEST_NS} from "@/store/modules/request/constants"

export default {
  name: "RequestToolbarMenu",
  computed: {
    ...mapGetters(REQUEST_NS, [
      'request'
    ]),
  },
  methods: {
    downloadRequest(){
      let text = JSON.stringify(this.request, null, 2)
      let filename = 'request-'+this.request.id+'.json'
      let element = document.createElement('a')
      element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
