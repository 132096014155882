<template>
  <v-row no-gutters align="end">
    <v-col cols="6" md="4">
      <div class="text-h6 themeText3--text">
        <v-icon color="themeText3">mdi-square-edit-outline</v-icon>
        <span> Change Request</span>
      </div>
      <div class="font-weight-bold text-no-wrap text-truncate">
        <copyable :text="request.id"/>
      </div>
      <div class="caption text-no-wrap text-truncate">
        <request-type :code="request.type_code"/>
        <span v-show="returnType" class="ml-2 themeText2--text">{{returnType}}</span>
      </div>
      <div class="white">
      </div>
      <div class="caption themeText2--text text-no-wrap text-truncate">
        <span>Requested by <strong>{{request.requester_type_code}}</strong> ({{request.requester_reference}})</span>
      </div>
      <div class="caption themeText2--text">
        <span>User <strong>{{request.user_reference}}</strong></span>
      </div>
      <div class="caption themeText2--text">
        <span>Revision #<strong>{{request.revision}}</strong></span>
      </div>
    </v-col>
    <v-col cols="6"  md="4" class="text-right pr-md-2">
      <request-status :code="request.status_code"/>
      <div class="caption themeText1--text">{{request.creation_date_time}}</div>
      <div class="caption themeText2--text">{{request.update_date_time}}</div>
    </v-col>
  </v-row>
</template>
<script>
import Copyable from '@/components/common/Copyable.vue'
import RequestType from '@/components/request/RequestType.vue'
import RequestStatus from '@/components/request/RequestStatus.vue'

export default {
  name: "RequestHeader",
  props: {
    request: Object,
  },
  components: {
    Copyable,
    RequestType,
    RequestStatus,
  },
  computed: {
    orderReference() {
      return (this.request && this.request.order) ? this.request.order.reference : ''
    },
    returnType() {
      return (this.request && this.request.return) ? this.request.return.type_code : ''
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
