import { getCurrencyDecimal } from "@/helpers/currency"

let overviewToCSV = (orders) => {
  let content = ""

  content = content + "Merchant Id,"
  content = content + "Currency,"
  content = content + "Start Date,"
  content = content + "End Date,"
  content = content + "Total of Days,"
  content = content + "Total of Orders,"
  content = content + "Total of Orders STANDARD,"
  content = content + "Total of Orders RESHIPMENT,"
  content = content + "Total of Orders CANCELLED,"
  content = content + "Total of Deliveries,"
  content = content + "Total of Deliveries CANCELLED,"
  content = content + "Total of Deliveries SPLIT,"
  content = content + "Total of STORE_PICKUP,"
  content = content + "Total of STORE_DELIVERY,"
  content = content + "Total of USER_DELIVERY,"
  content = content + "Total of EXTERNAL_PICKUP,"
  content = content + "Total of DIGITAL_DELIVERY,"
  content = content + "Total of BOOKING,"
  content = content + "Total of Cancellations,"
  content = content + "Total of Returns,"
  content = content + "Total of Adjustments (post-checkout),"
  content = content + "Total of Payments,"
  content = content + "Total of Documents,"
  content = content + "Total of Notes,"
  content = content + "Total of Revisions,"
  content = content + "Percent of Orders STANDARD,"
  content = content + "Percent of Orders RESHIPMENT,"
  content = content + "Percent of Orders CANCELLED,"
  content = content + "Percent of Deliveries CANCELLED,"
  content = content + "Percent of Deliveries SPLIT,"
  content = content + "Percent of STORE_PICKUP,"
  content = content + "Percent of STORE_DELIVERY,"
  content = content + "Percent of USER_DELIVERY,"
  content = content + "Percent of EXTERNAL_PICKUP,"
  content = content + "Percent of DIGITAL_DELIVERY,"
  content = content + "Percent of BOOKING,"
  content = content + "Average of Orders / Day,"
  content = content + "Average of Ordered Amount / Order,"
  content = content + "Average of Ordered Qty / Order,"
  content = content + "Average of Deliveries / Order,"
  content = content + "Average of Deliveries CANCELLED,"
  content = content + "Average of Deliveries SPLIT,"
  content = content + "Average of Cancellations / Order,"
  content = content + "Average of Returns / Order,"
  content = content + "Average of Adjustments / Order,"
  content = content + "Average of Payments / Order,"
  content = content + "Average of Documents / Order,"
  content = content + "Average of Notes / Order,"
  content = content + "Average of Revisions / Order,"
  content = content + "Total Amount,"
  content = content + "Total Products Amount,"
  content = content + "Total Products Qty,"
  content = content + "Total Shipping Fees Amount,"
  content = content + "Total Ordered Amount,"
  content = content + "Total Ordered Qty,"
  content = content + "Total Cancelled Amount,"
  content = content + "Total Cancelled Qty,"
  content = content + "Total Returned Amount,"
  content = content + "Total Returned Qty,"
  content = content + "Total Adjusted Amount (post-checkout),"
  content = content + "Total Payment Amount,"
  content = content + "Total Paid Amount,"
  content = content + "Total Void Amount,"
  content = content + "Total Refund Amount\n"

  let isFirst = true
  let merchantId = ""
  let currencyCode = ""
  let decimal = 0
  let startDate = new Date()
  let endDate = new Date()
  let originalStartDate = ""
  let originalEndDate = ""
  let totalOfDays = 0

  let totalOfOrders = 0
  let totalOfOrdersSTANDARD = 0
  let totalOfOrdersRESHIPMENT = 0
  let totalOfOrdersCANCELLED = 0
  let totalOfDeliveries = 0
  let totalOfDeliveriesCANCELLED = 0
  let totalOfDeliveriesSPLIT = 0
  let totalOfSTORE_PICKUP = 0
  let totalOfSTORE_DELIVERY = 0
  let totalOfUSER_DELIVERY = 0
  let totalOfEXTERNAL_PICKUP = 0
  let totalOfDIGITAL_DELIVERY = 0
  let totalOfBOOKING = 0
  let totalOfCancellations = 0
  let totalOfReturns = 0
  let totalOfAdjustments = 0
  let totalOfPayments = 0
  let totalOfDocuments = 0
  let totalOfNotes = 0
  let totalOfRevisions = 0

  let percentOfOrdersSTANDARD = 0
  let percentOfOrdersRESHIPMENT = 0
  let percentOfOrdersCANCELLED = 0
  let percentOfDeliveriesCANCELLED = 0
  let percentOfDeliveriesSPLIT = 0
  let percentOfSTORE_PICKUP = 0
  let percentOfSTORE_DELIVERY = 0
  let percentOfUSER_DELIVERY = 0
  let percentOfEXTERNAL_PICKUP = 0
  let percentOfDIGITAL_DELIVERY = 0
  let percentOfBOOKING = 0

  let averageOfOrdersPerDay = 0
  let averageOfOrderedAmountPerOrder = 0
  let averageOfOrderedQtyPerOrder = 0
  let averageOfDeliveriesPerOrder = 0
  let averageOfDeliveriesCANCELLEDPerOrder = 0
  let averageOfDeliveriesSPLITPerOrder = 0
  let averageOfCancellationsPerOrder = 0
  let averageOfReturnsPerOrder = 0
  let averageOfAdjustmentsPerOrder = 0
  let averageOfPaymentsPerOrder = 0
  let averageOfDocumentsPerOrder = 0
  let averageOfNotesPerOrder = 0
  let averageOfRevisionsPerOrder = 0

  let totalAmount = 0
  let totalProductsAmount = 0
  let totalProductsQty = 0
  let totalShippingFeesAmount = 0
  let totalOrderedAmount = 0
  let totalOrderedQty = 0
  let totalCancelledAmount = 0
  let totalCancelledQty = 0
  let totalReturnedAmount = 0
  let totalReturnedQty = 0
  let totalAdjustedAmount = 0
  let totalPaymentAmount = 0
  let totalPaidAmount = 0
  let totalVoidAmount = 0
  let totalRefundAmount = 0

  for (const order of orders) {
    let adjustmentReferences = []

    if (isFirst) {
      merchantId = order.source.merchant_id
      currencyCode = order.currency_code
      decimal = getCurrencyDecimal(currencyCode)

      startDate = new Date(order.creation_date_time)
      endDate = new Date(order.creation_date_time)
      
      originalStartDate = order.creation_date_time
      originalEndDate = order.creation_date_time
      
      isFirst = false
    }
    
    if ( new Date(order.creation_date_time) < startDate ) {
      startDate = new Date(order.creation_date_time)
      originalStartDate = order.creation_date_time
    }
    
    if ( new Date(order.creation_date_time) > endDate ) {
      endDate = new Date(order.creation_date_time)
      originalEndDate = order.creation_date_time
    }

    totalOfOrders = totalOfOrders + 1

    if ( order.type_code == "STANDARD" ) {
      totalOfOrdersSTANDARD = totalOfOrdersSTANDARD + 1
    } 
    
    if ( order.type_code == "RESHIPMENT") {
      totalOfOrdersRESHIPMENT = totalOfOrdersRESHIPMENT + 1
    }

    totalOfRevisions = totalOfRevisions + order.revision

    if (order.status_code == "CANCELLED") {
      totalOfOrdersCANCELLED = totalOfOrdersCANCELLED + 1
    }

    for (const fulfillment of order.fulfillments) {
      totalAmount = totalAmount + fulfillment.total_amount

      for (const item of fulfillment.line_items) {
        switch (item.type_code) {
          case "PRODUCT":
            totalProductsAmount = totalProductsAmount + item.amount
            totalProductsQty = totalProductsQty + item.quantity
            break;
          case "SHIPPING_FEES":
            totalShippingFeesAmount = totalShippingFeesAmount + item.amount
            break;
          default:
            break;
        }

        for (const adjustment of item.adjustments) {
          if ( adjustment.change_request_id ) {
            totalAdjustedAmount = totalAdjustedAmount + adjustment.amount

            if ( adjustmentReferences.includes(adjustment.change_request_id) == false ) {
              totalOfAdjustments = totalOfAdjustments + 1
              adjustmentReferences.push(adjustment.change_request_id)
            }
          }
        }
      }

      for (const delivery of fulfillment.deliveries) {
        totalOfDeliveries = totalOfDeliveries + 1

        if (delivery.status_code == "CANCELLED") {
          totalOfDeliveriesCANCELLED = totalOfDeliveriesCANCELLED + 1
        }

        if (delivery.status_code == "SPLIT") {
          totalOfDeliveriesSPLIT = totalOfDeliveriesSPLIT + 1
        }

        switch (delivery.type_code) {
          case "STORE_PICKUP":
            totalOfSTORE_PICKUP = totalOfSTORE_PICKUP + 1
          break;

          case "STORE_DELIVERY":
            totalOfSTORE_DELIVERY = totalOfSTORE_DELIVERY + 1
          break;

          case "USER_DELIVERY":
            totalOfUSER_DELIVERY = totalOfUSER_DELIVERY + 1
          break;

          case "EXTERNAL_PICKUP":
            totalOfEXTERNAL_PICKUP = totalOfEXTERNAL_PICKUP + 1
          break;

          case "DIGITAL_DELIVERY":
            totalOfDIGITAL_DELIVERY = totalOfDIGITAL_DELIVERY + 1
          break;

          case "BOOKING":
            totalOfBOOKING = totalOfBOOKING + 1
          break;

          default:
          break;
        }

        for (const document of delivery.documents) {
          totalOfDocuments = totalOfDocuments + 1
        }
      }

      for (const cancellation of fulfillment.cancellations) {
        totalOfCancellations = totalOfCancellations + 1
        totalCancelledAmount = totalCancelledAmount + cancellation.total_amount
        
        for (const item of cancellation.line_items) {
          if (item.type_code == "PRODUCT") {
            totalCancelledQty = totalCancelledQty + item.quantity
          }

          for (const adjustment of item.adjustments) {
            if ( adjustment.change_request_id ) {
              totalAdjustedAmount = totalAdjustedAmount + adjustment.amount
  
              if ( adjustmentReferences.includes(adjustment.change_request_id) == false ) {
                totalOfAdjustments = totalOfAdjustments + 1
                adjustmentReferences.push(adjustment.change_request_id)
              }
            }
          }
        }
      }
      
      for (const returned of fulfillment.returns) {
        totalOfReturns = totalOfReturns + 1
        totalReturnedAmount = totalReturnedAmount + returned.total_amount

        for (const item of returned.line_items) {
          if (item.type_code == "PRODUCT") {
            totalReturnedQty = totalReturnedQty + item.quantity
          }
        }
      }

    }

    for (const payment of order.payments) {
      totalOfPayments = totalOfPayments + 1
      totalPaymentAmount = totalPaymentAmount + payment.amount

      for (const transaction of payment.transactions) {
        if (transaction.status_code == "CAPTURE_SUCCESS") {
          totalPaidAmount = totalPaidAmount + transaction.amount
        }
      }

      for (const cancellation of payment.cancellations) {
        totalVoidAmount = totalVoidAmount + cancellation.amount
      }
      
      for (const refund of payment.refunds) {
        totalRefundAmount = totalRefundAmount + refund.amount
      }
    }
    
    for (const document of order.documents) {
      totalOfDocuments = totalOfDocuments + 1
    }
    
    for (const note of order.notes) {
      totalOfNotes = totalOfNotes + 1
    }

  }
  
  totalOfDays = Math.round((endDate.getTime() - startDate.getTime()) / (1000*60*60*24))

  totalOrderedAmount = totalAmount + totalCancelledAmount + totalAdjustedAmount
  totalOrderedQty = totalProductsQty + totalCancelledQty

  percentOfOrdersSTANDARD = 100 * totalOfOrdersSTANDARD / totalOfOrders
  percentOfOrdersRESHIPMENT = 100 * totalOfOrdersRESHIPMENT / totalOfOrders
  percentOfOrdersCANCELLED = 100 * totalOfOrdersCANCELLED / totalOfOrders
  percentOfDeliveriesCANCELLED = 100 * totalOfDeliveriesCANCELLED / totalOfDeliveries
  percentOfDeliveriesSPLIT = 100 * totalOfDeliveriesSPLIT / totalOfDeliveries
  percentOfSTORE_PICKUP = 100 * totalOfSTORE_PICKUP / totalOfDeliveries
  percentOfSTORE_DELIVERY = 100 * totalOfSTORE_DELIVERY / totalOfDeliveries
  percentOfUSER_DELIVERY = 100 * totalOfUSER_DELIVERY / totalOfDeliveries
  percentOfEXTERNAL_PICKUP = 100 * totalOfEXTERNAL_PICKUP / totalOfDeliveries
  percentOfDIGITAL_DELIVERY = 100 * totalOfDIGITAL_DELIVERY / totalOfDeliveries
  percentOfBOOKING = 100 * totalOfBOOKING / totalOfDeliveries

  averageOfOrdersPerDay = totalOfDays > 0 ? (totalOfOrders / totalOfDays).toFixed(0) : "NA"
  averageOfOrderedAmountPerOrder = totalOrderedAmount / totalOfOrders
  averageOfOrderedQtyPerOrder = totalOrderedQty / totalOfOrders
  averageOfDeliveriesPerOrder = totalOfDeliveries / totalOfOrders
  averageOfDeliveriesCANCELLEDPerOrder = totalOfDeliveriesCANCELLED / totalOfOrders
  averageOfDeliveriesSPLITPerOrder = totalOfDeliveriesSPLIT / totalOfOrders
  averageOfPaymentsPerOrder = totalOfPayments / totalOfOrders
  averageOfCancellationsPerOrder = totalOfCancellations / totalOfOrders
  averageOfReturnsPerOrder = totalOfReturns / totalOfOrders
  averageOfAdjustmentsPerOrder = totalOfAdjustments / totalOfOrders
  averageOfDocumentsPerOrder = totalOfDocuments / totalOfOrders
  averageOfNotesPerOrder = totalOfNotes / totalOfOrders
  averageOfRevisionsPerOrder = totalOfRevisions / totalOfOrders

  content = content + merchantId + ","
  content = content + currencyCode + ","
  content = content + originalStartDate.substring(0, 10) + ","
  content = content + originalEndDate.substring(0, 10) + ","
  content = content + totalOfDays + ","

  content = content + totalOfOrders + ","
  content = content + totalOfOrdersSTANDARD + ","
  content = content + totalOfOrdersRESHIPMENT + ","
  content = content + totalOfOrdersCANCELLED + ","
  content = content + totalOfDeliveries + ","
  content = content + totalOfDeliveriesCANCELLED + ","
  content = content + totalOfDeliveriesSPLIT + ","
  content = content + totalOfSTORE_PICKUP + ","
  content = content + totalOfSTORE_DELIVERY + ","
  content = content + totalOfUSER_DELIVERY + ","
  content = content + totalOfEXTERNAL_PICKUP + ","
  content = content + totalOfDIGITAL_DELIVERY + ","
  content = content + totalOfBOOKING + ","
  content = content + totalOfCancellations + ","
  content = content + totalOfReturns + ","
  content = content + totalOfAdjustments + ","
  content = content + totalOfPayments + ","
  content = content + totalOfDocuments + ","
  content = content + totalOfNotes + ","
  content = content + totalOfRevisions + ","

  content = content + percentOfOrdersSTANDARD.toFixed(2) + ","
  content = content + percentOfOrdersRESHIPMENT.toFixed(2) + ","
  content = content + percentOfOrdersCANCELLED.toFixed(2) + ","
  content = content + percentOfDeliveriesCANCELLED.toFixed(2) + ","
  content = content + percentOfDeliveriesSPLIT.toFixed(2) + ","
  content = content + percentOfSTORE_PICKUP.toFixed(2) + ","
  content = content + percentOfSTORE_DELIVERY.toFixed(2) + ","
  content = content + percentOfUSER_DELIVERY.toFixed(2) + ","
  content = content + percentOfEXTERNAL_PICKUP.toFixed(2) + ","
  content = content + percentOfDIGITAL_DELIVERY.toFixed(2) + ","
  content = content + percentOfBOOKING.toFixed(2) + ","

  content = content + averageOfOrdersPerDay + ","
  content = content + averageOfOrderedAmountPerOrder.toFixed(decimal) + ","
  content = content + averageOfOrderedQtyPerOrder.toFixed(2) + ","
  content = content + averageOfDeliveriesPerOrder.toFixed(2) + ","
  content = content + averageOfDeliveriesCANCELLEDPerOrder.toFixed(2) + ","
  content = content + averageOfDeliveriesSPLITPerOrder.toFixed(2) + ","
  content = content + averageOfCancellationsPerOrder.toFixed(2) + ","
  content = content + averageOfReturnsPerOrder.toFixed(2) + ","
  content = content + averageOfAdjustmentsPerOrder.toFixed(2) + ","
  content = content + averageOfPaymentsPerOrder.toFixed(2) + ","
  content = content + averageOfDocumentsPerOrder.toFixed(2) + ","
  content = content + averageOfNotesPerOrder.toFixed(2) + ","
  content = content + averageOfRevisionsPerOrder.toFixed(2) + ","

  content = content + totalAmount.toFixed(decimal) + ","
  content = content + totalProductsAmount.toFixed(decimal) + ","
  content = content + totalProductsQty + ","
  content = content + totalShippingFeesAmount.toFixed(decimal) + ","
  content = content + totalOrderedAmount.toFixed(decimal) + ","
  content = content + totalOrderedQty + ","
  content = content + totalCancelledAmount.toFixed(decimal) + ","
  content = content + totalCancelledQty + ","
  content = content + totalReturnedAmount.toFixed(decimal) + ","
  content = content + totalReturnedQty + ","
  content = content + totalAdjustedAmount.toFixed(decimal) + ","
  content = content + totalPaymentAmount.toFixed(decimal) + ","
  content = content + totalPaidAmount.toFixed(decimal) + ","
  content = content + totalVoidAmount.toFixed(decimal) + ","
  content = content + totalRefundAmount.toFixed(decimal) + "\n"

  return content
}

export {
  overviewToCSV,
}
