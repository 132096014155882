<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="themePanelHeader">
      <v-row no-gutters align="center" class="text-body-2 themeText1--text">
        <v-col cols="6">
          <div class="caption themeText1--text">
            <span>{{ serviceProviderType }} - </span>
            <span>{{ serviceProviderReference }}</span>
            <span v-show="serviceProviderPartnerReference"> ( {{ serviceProviderPartnerReference }} )</span>
          </div>
          <div class="font-weight-bold text-truncate">
            <copyable :text="paymentReference" />
          </div>
          <div class="caption">
            <span>{{paymentMethodReference}}</span>
            <span class="themeText2--text"> {{paymentCaptureTypeCode}}</span>
          </div>
        </v-col>
        <v-col cols="6" class="text-right">
          <div class="font-weight-bold">{{paymentAmount}} {{currencyCode}}</div>
          <div class="caption themeText2--text">{{paymentUpdateDateTime}}</div>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pt-3 themePanelContent themeText1--text">
      <div v-show="hasPaymentTransactions" >
        <div class="text-body-2 mb-3 font-weight-bold">
          <v-icon color="themeText3">mdi-credit-card-plus-outline</v-icon>
          <span class="themeText3--text"> Payments</span>
        </div>
        <order-transaction v-for="transaction in payment.transactions" :key="transaction.reference" :transaction="transaction" :currencyCode="currencyCode" />
      </div>

      <div v-show="hasCancellations" >
        <div class="text-body-2 mt-3 font-weight-bold">
          <v-icon color="themeText3">mdi-credit-card-remove-outline</v-icon>
          <span class="themeText3--text"> Cancellations</span>
        </div>
        <div v-for="cancellation in payment.cancellations" :key="cancellation.change_request_id">
          <div class="caption themeText2--text text-truncate font-weight-bold mt-3">
            <copyable :text="cancellation.change_request_id" />
          </div>
          <div class="caption themeText2--text text-truncate">
            <span>{{cancellation.reason_code}} </span>
            <span>( <copyable :text="cancellation.linked_request_id" /> )</span>
          </div>
          <order-transaction :transaction="cancellation.transaction" :currencyCode="currencyCode" />
        </div>
      </div>

      <div v-show="hasRefunds" >
        <div class="text-body-2 mt-3 font-weight-bold">
          <v-icon color="themeText3">mdi-credit-card-minus-outline</v-icon>
          <span class="themeText3--text"> Refunds</span>
        </div>
        <div v-for="refund in payment.refunds" :key="refund.change_request_id">
          <div class="caption themeText2--text text-truncate font-weight-bold mt-3">
            <copyable :text="refund.change_request_id" />
          </div>
          <div class="caption themeText2--text text-truncate">
            <span>{{refund.reason_code}} </span>
            <span>( <copyable :text="refund.linked_request_id" /> )</span>
          </div>
          <order-transaction :transaction="refund.transaction" :currencyCode="currencyCode" />
        </div>
      </div>

      <div class="text-body-2">
        <order-additional-attributes :attributes="payment.additional_attributes" />
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import {getCurrencyDecimal} from "@/helpers/currency"
import Copyable from '@/components/common/Copyable.vue'
import OrderAdditionalAttributes from '@/components/order/OrderAdditionalAttributes.vue'
import OrderTransaction from '@/components/order/OrderTransaction.vue'

export default {
  name: "OrderPayment",
  props: {
    payment: Object,
    currencyCode: String,
  },
  components: {
    Copyable,
    OrderAdditionalAttributes,
    OrderTransaction,
  },
  computed: {
    paymentReference() {
      return this.payment ? this.payment.reference : ""
    },
    paymentMethodReference() {
      return this.payment ? this.payment.method_reference : ""
    },
    paymentCaptureTypeCode() {
      return this.payment && this.payment.capture ? this.payment.capture.type_code : ""
    },
    paymentAmount() {
      const currencyCode = this.currencyCode
      const decimal = getCurrencyDecimal(currencyCode) 
      return this.payment && this.payment.amount >= 0 ? this.payment.amount.toFixed(decimal) : (0).toFixed(decimal)
    },
    paymentUpdateDateTime() {
      return this.payment ? this.payment.update_date_time : ""
    },
    hasPaymentTransactions() {
      return (this.payment && this.payment.transactions && this.payment.transactions.length > 0)
    },
    hasCancellations() {
      return (this.payment && this.payment.cancellations && this.payment.cancellations.length > 0)
    },
    hasRefunds() {
      return (this.payment && this.payment.refunds && this.payment.refunds.length > 0)
    },
    serviceProviderType() {
      return this.payment && this.payment.service_provider ? this.payment.service_provider.type_code : ""
    },
    serviceProviderReference() {
      return this.payment && this.payment.service_provider ? this.payment.service_provider.reference : ""
    },
    serviceProviderPartnerReference() {
      return this.payment && this.payment.service_provider ? this.payment.service_provider.partner_reference : ""
    }
  },
}
</script>
<style scoped>

</style>
