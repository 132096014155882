<template>
  <v-list-item @click="displayRequest()" :key="request.id">
    <v-row no-gutters class="py-2">
      <v-col cols="6">
        <div class="subtitle-2 text-no-wrap text-truncate">
          <copyable :text="request.order.reference"/>
        </div>
        <div class="caption text-no-wrap text-truncate">
          <request-type :code="request.type_code"/>
          <span v-show="returnType" class="ml-2 themeText2--text">{{returnType}}</span>
        </div>
        <div class="caption themeText2--text text-no-wrap text-truncate">
          ID : <copyable :text="request.id"/>
        </div>
        <div class="caption themeText2--text text-no-wrap text-truncate">
          Reference : <copyable :text="request.reference"/>
        </div>
      </v-col>
      <v-col cols="6" class="text-right">
        <request-status :code="request.status_code"/>
        <div class="caption themeText2--text">{{creationDate}}</div>
        <request-requester-type :code="request.requester_type_code"/>
        <div class="caption themeText2--text text-no-wrap text-truncate">{{request.requester_reference}}</div>
      </v-col>
    </v-row>
  </v-list-item>
</template>
<script>
import {mapActions} from "vuex"
import {REQUEST_NS} from "@/store/modules/request/constants"
import Copyable from '@/components/common/Copyable.vue'
import RequestType from '@/components/request/RequestType.vue'
import RequestRequesterType from '@/components/request/RequestRequesterType.vue'
import RequestStatus from '@/components/request/RequestStatus.vue'

export default {
  name: "ChangeRequestsLine",
  props: {
    request: Object,
  },
  components: {
    Copyable,
    RequestType,
    RequestStatus,
    RequestRequesterType,
  },
  computed: {
    creationDate() {
      return this.request.creation_date_time.replace(/.\d+Z$/g, "+00:00")
    },
    returnType() {
      return (this.request && this.request.return) ? this.request.return.type_code : ''
    }
  },
  methods: {
    ...mapActions(REQUEST_NS, [
      'selectRequestToDisplay'
    ]),
    displayRequest() {
      this.selectRequestToDisplay(this.request)
      this.$router.push({ name: 'ChangeRequest', params: { requestId: this.request.id } })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
