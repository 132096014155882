import {getOrders,getOrderById,getOrderByReference} from "@/helpers/apiV1"

const actions = {
    fetchOrders({getters, commit},{merchantId, params}) {
      commit('toggleLoadingFlag')
      commit('updateTotalNumber',0)
      commit('updateShowingNumber',0)
      commit('updateOrders',[])
      commit('updateErrorMessage',"")

      getOrders(merchantId,params)
      .then((response) => {
        commit('updateOrders',response.data)
        commit('updateShowingNumber',response.data.length)
        if ( response.headers['content-range'] ) {
          commit('updateTotalNumber',response.headers['content-range'].split("/").pop())
        } else {
          commit('updateTotalNumber',response.data.length)
        }
        commit('toggleLoadingFlag')
      })
      .catch((error) => {
          console.log(JSON.stringify(error))
          commit('toggleLoadingFlag')
          commit('updateErrorMessage',error)
      })
    },
    fetchMoreOrders({getters, commit},{merchantId, params}) {
      commit('toggleLoadingFlag')
      commit('updateErrorMessage',"")
      getOrders(merchantId,params)
      .then((response) => {
        commit('addOrders',response.data)
        commit('increaseShowingNumber',response.data.length)
        commit('toggleLoadingFlag')
      })
      .catch((error) => {
          console.log(JSON.stringify(error))
          commit('toggleLoadingFlag')
          commit('updateErrorMessage',error)
      })
    },
    fetchOrderById({getters, commit},{merchantId, orderId}) {
      commit('toggleLoadingFlag')
      commit('updateOrder',{})
      commit('updateErrorMessage',"")
      getOrderById(merchantId,orderId)
      .then((response) => {
        commit('updateOrder',response.data)
        commit('toggleLoadingFlag')
      })
      .catch((error) => {
          console.log(JSON.stringify(error))
          commit('toggleLoadingFlag')
          commit('updateErrorMessage',error)
      })
    },
    fetchOrderByReference({getters, commit},{merchantId, orderReference}) {
      commit('toggleLoadingFlag')
      commit('updateOrder',{})
      commit('updateErrorMessage',"")
      getOrderByReference(merchantId,orderReference)
      .then((response) => {
        commit('updateOrder',response.data)
        commit('toggleLoadingFlag')
      })
      .catch((error) => {
          console.log(JSON.stringify(error))
          commit('toggleLoadingFlag')
          commit('updateErrorMessage',error)
      })
    },
    selectOrderToDisplay({getters, commit},order) {
      commit('updateOrder',order)
    },
    updateSearchQueryParams({commit},queryParams) {
      commit('updateSearchQueryParams',queryParams)
      commit('updateSearchQueryParamsUrl')
    },
    addSearchQueryParam({commit}, {key,value}) {
      commit('addSearchQueryParam',{key,value})
      commit('updateSearchQueryParamsUrl')
    },
    removeSearchQueryParam({commit}, key) {
      commit('removeSearchQueryParam',key)
      commit('updateSearchQueryParamsUrl')
    },
    updateOrdersURL({state},{vue}) {
      let url = vue.$route.path

      if ( state.searchQueryParamsURL ) {
        url = url + '?' + state.searchQueryParamsURL
      }

      history.pushState({},null,url)
    },
}

export default actions;
