<template>
  <v-container fluid class="background">
    <v-row>
      <v-spacer></v-spacer>
      <div id="themeSwitcher" class="pr-4">
        <app-theme-switch />
      </div>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12">
        <app-logo />
      </v-col>
      <v-col cols="12" class="pt-0">
        <env-list />
      </v-col>
      <v-col cols="12">
        <app-footer />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppLogo from '@/components/app/AppLogo.vue'
import AppFooter from '@/components/app/AppFooter.vue'
import AppThemeSwitch from '@/components/app/AppThemeSwitch.vue'
import EnvList from '@/components/env/EnvList.vue'

export default {
  name: 'HomePage',
  components: {
    AppLogo,
    AppFooter,
    AppThemeSwitch,
    EnvList,
  },
}
</script>
<style scoped>
#themeSwitcher {
  padding-top: 10px;
}
</style>
