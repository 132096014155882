<template>
  <div class="mt-2">

    <v-row class="body-2 font-weight-bold">
      <v-col class="pb-0">
        <div class="">Total</div>
      </v-col>
      <v-col class="pb-0 text-right">
        <div v-show="hasAdjustments" class="themeText2--text text-decoration-line-through">{{totalAmountBeforeFulfillmentAdjustments}} {{currencyCode}}</div>
        <div class="">{{fulfillmentTotalAmount}} {{currencyCode}}</div>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="py-0">
        <order-adjustments :adjustments="fulfillment.adjustments" :currencyCode="currencyCode" />
      </v-col>
    </v-row>

    <v-row class="body-2">
      <v-col class="py-0">
        <div class="">Tax</div>
      </v-col>
      <v-col class="py-0 text-right">
        <div class="">{{fulfillmentTotalTaxAmount}} {{currencyCode}}</div>
      </v-col>
    </v-row>
    <v-row class="body-2">
      <v-col class="pt-0">
        <div class="">Net</div>
      </v-col>
      <v-col class="pt-0 text-right">
        <div class="">{{fulfillmentTotalNetAmount}} {{currencyCode}}</div>
      </v-col>
    </v-row>

  </div>
</template>
<script>
import {getCurrencyDecimal} from "@/helpers/currency"
import OrderAdjustments from '@/components/order/OrderAdjustments.vue'

export default {
  name: "OrderFulfillmentTotal",
  props: {
    currencyCode: String,
    fulfillment: Object,
  },
  components: {
    OrderAdjustments,
  },
  computed: {
    hasAdjustments() {
      return (this.fulfillment && this.fulfillment.adjustments && this.fulfillment.adjustments.length > 0)
    },
    totalAmountBeforeFulfillmentAdjustments() {
      const currencyCode = this.currencyCode
      const decimal = getCurrencyDecimal(currencyCode)
      let amount = 0
      let adjustments = this.hasAdjustments ? this.fulfillment.adjustments : []

      for (let adjustment of adjustments) {
        amount += Number(adjustment.amount?adjustment.amount:0)
      }

      amount += this.fulfillment.total_amount

      return amount ? amount.toFixed(decimal) : (0).toFixed(decimal)
    },
    fulfillmentTotalAmount() {
      const currencyCode = this.currencyCode
      const decimal = getCurrencyDecimal(currencyCode)
      return this.fulfillment && this.fulfillment.total_amount >= 0 ? this.fulfillment.total_amount.toFixed(decimal) : (0).toFixed(decimal)
    },
    fulfillmentTotalNetAmount() {
      const currencyCode = this.currencyCode
      const decimal = getCurrencyDecimal(currencyCode)
      return this.fulfillment && this.fulfillment.total_net_amount >= 0 ? this.fulfillment.total_net_amount.toFixed(decimal) : (0).toFixed(decimal)
    },
    fulfillmentTotalTaxAmount() {
      const currencyCode = this.currencyCode
      const decimal = getCurrencyDecimal(currencyCode)
      return this.fulfillment && this.fulfillment.total_tax_amount >= 0 ? this.fulfillment.total_tax_amount.toFixed(decimal) : (0).toFixed(decimal)
    },
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
</style>
