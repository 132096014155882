<template>
  <v-menu v-model="menu" :close-on-content-click="false" offset-y bottom right transition="scale-transition" origin="top left">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="themeText2" class="text-none" small text v-bind="attrs" v-on="on">
        <v-icon small class="pr-2" :color="iconColor">{{icon}}</v-icon>{{label}}
      </v-btn>
    </template>
    <v-card class="themeCard">
      <div class="pa-2 py-4 themePanelHeader themeText2--text text-center body-2">Select the date range to focus on</div>
      <div class="pt-2 caption text-center">
        <span class="themeText2--text">Timezone : </span>
        <span class="themeText1--text">{{timezone}}</span>
      </div>
      <v-date-picker v-model="dateTimePicker" @change="applyDateTimeFilters()" first-day-of-week="1" show-adjacent-months no-title show-current color="primary" range :dark="themeIsDark"></v-date-picker>
      <div class="themePanelHeader pa-2">
        <v-btn color="themeText2" text block class="text-none" @click="disableAllDateTimeFilters()">Remove all date time filters</v-btn>
      </div>
    </v-card>
  </v-menu>
</template>
<script>
import moment from 'moment-timezone'

import {mapGetters,mapActions} from "vuex"
import {MERCHANT_NS} from "@/store/modules/merchant/constants"
import {ORDER_NS} from "@/store/modules/order/constants"
import {THEME_NS} from "@/store/modules/theme/constants"

export default {
  name: "OrdersMenuUpdateDateTime",
  data: () => ({
    menu: false,
    dateTimeQuery: "",
    updateDateTimeStart: "",
    updateDateTimeEnd: "",
    dateTimePicker:[],
  }),
  created() {
    if (this.searchQueryParams) {
      if ( this.searchQueryParams['update_date_time_start'] ) {
        this.updateDateTimeStart = this.searchQueryParams['update_date_time_start']
        this.dateTimePicker.push(this.updateDateTimeStart.substr(0,10))
      }

      if ( this.searchQueryParams['update_date_time_end'] ) {
        this.updateDateTimeEnd += this.searchQueryParams['update_date_time_end']
        this.dateTimePicker.push(this.updateDateTimeEnd.substr(0,10))
      }

      this.updateDateTimeQuery()
    }
  },
  computed: {
    ...mapGetters(THEME_NS, ['themeIsDark']),
    ...mapGetters(MERCHANT_NS, [
      'merchant'
    ]),
    ...mapGetters(ORDER_NS, [
      'searchQueryParams'
    ]),
    label() {
      let label = "Update date time"

      if ( this.dateTimePicker.length == 2 ) {
        label = "Updated " + this.dateTimePicker[0] + " → " + this.dateTimePicker[1]
      }

      return label
    },
    icon() {
      return (this.dateTimeQuery == "") ? 'mdi-clock-outline' : 'mdi-clock'
    },
    iconColor() {
      return (this.dateTimeQuery == "") ? 'themeText2' : 'primary'
    },
    timezone() {
      let timezone = "Etc/UTC"

      if ( this.merchant.timezone && moment.tz.zone(this.merchant.timezone) != null ) {
        timezone = this.merchant.timezone
      }

      return timezone
    }
  },
  methods: {
    ...mapActions(ORDER_NS, ['fetchOrders','addSearchQueryParam','removeSearchQueryParam','updateOrdersURL']),
    applyDateTimeFilters() {
      this.dateTimePicker.sort()

      let updateDateTimeStart = moment.tz(this.dateTimePicker[0] + " 00:00:00", this.timezone)
      let updateDateTimeEnd = moment.tz(this.dateTimePicker[1] + " 23:59:59", this.timezone)

      this.updateDateTimeStart = updateDateTimeStart.format()
      this.updateDateTimeEnd = updateDateTimeEnd.format()
      this.updateDateTimeQuery()
      this.updateSearchResults()
    },
    disableAllDateTimeFilters() {
      this.dateTimePicker = []
      this.updateDateTimeStart = ""
      this.updateDateTimeEnd = ""
      this.updateDateTimeQuery()
      this.updateSearchResults()
    },
    updateDateTimeQuery() {
      this.dateTimeQuery = ""

      if ( this.updateDateTimeStart ) {
        this.dateTimeQuery += this.updateDateTimeStart
      }

      if ( this.updateDateTimeStart && this.updateDateTimeEnd ) {
        this.dateTimeQuery += '&'
      }

      if ( this.updateDateTimeEnd ) {
        this.dateTimeQuery += this.updateDateTimeEnd
      }
    },
    updateSearchResults() {
      if ( this.updateDateTimeStart ) {
        this.addSearchQueryParam({'key':'update_date_time_start','value':this.updateDateTimeStart})
      } else {
        this.removeSearchQueryParam('update_date_time_start')
      }

      if ( this.updateDateTimeEnd ) {
        this.addSearchQueryParam({'key':'update_date_time_end','value':this.updateDateTimeEnd})
      } else {
        this.removeSearchQueryParam('update_date_time_end')
      }

      this.updateOrdersURL({'vue':this})
      this.fetchOrders({'merchantId':this.merchant.id, 'params':this.searchQueryParams})
    }
  },
}
</script>
