<template>
  <v-expansion-panels multiple class="elevation-0">
    <v-expansion-panel v-for="(cancellation,i) in cancellations" :key="i">
      <order-cancellation-header :type="type" :currencyCode="currencyCode" :cancellation="cancellation" />
      <order-cancellation-content :currencyCode="currencyCode" :cancellation="cancellation" />
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import OrderCancellationHeader from '@/components/order/OrderCancellationHeader.vue'
import OrderCancellationContent from '@/components/order/OrderCancellationContent.vue'

export default {
  name: "OrderCancellations",
  props: {
    cancellations: Array,
    currencyCode: String,
    type: String
  },
  components: {
    OrderCancellationHeader,
    OrderCancellationContent,
  },
}
</script>
<style scoped>
</style>
