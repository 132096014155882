<template>
  <v-toolbar dense flat color="transparent">
    <v-btn color="themeText2" class="text-none" small text @click="goBackToRequests()"><v-icon small class="pr-2">mdi-arrow-left</v-icon>Change Requests</v-btn>
    <v-spacer></v-spacer>
    <v-btn color="themeText2" class="text-none" small text @click="copyUrlToClipboard()"><v-icon small class="pr-2">{{icon}}</v-icon>Url</v-btn>
    <request-dialog-json />
    <request-toolbar-menu />
  </v-toolbar>
</template>
<script>
import RequestDialogJson from '@/components/request/RequestDialogJson.vue'
import RequestToolbarMenu from '@/components/request/RequestToolbarMenu.vue'
import {mapGetters} from "vuex"
import {REQUEST_NS} from "@/store/modules/request/constants"

export default {
  name: "RequestToolbar",
  components: {
    RequestDialogJson,
    RequestToolbarMenu
  },
  data: () => ({
    icon: "mdi-clipboard-text-outline",
    defaultIcon: "mdi-clipboard-text-outline",
    copiedIcon: "mdi-clipboard-check-outline",
  }),
  computed: {
    ...mapGetters(REQUEST_NS, [
      'searchQueryParams'
    ]),
  },
  methods: {
    goBackToRequests() {
      this.$router.push({'name':'ChangeRequests','query':this.searchQueryParams})
    },
    copyUrlToClipboard() {
      this.icon = this.copiedIcon
      navigator.clipboard.writeText(window.location.href)
      setTimeout(() => {  this.icon = this.defaultIcon }, 250)
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
