<template>
  <div class="px-4">
    <order-header :order="order"/>
    <v-row class="mt-0">
      <v-col cols="12" md="8" >
        <order-fulfillments :order="order"/>
        <order-adjustments level="ORDER" :adjustments="order.adjustments" :currencyCode="order.currency_code" />
        <order-payments :order="order"/>
        <order-documents level="ORDER" :documents="order.documents"/>
        <order-notes :order="order"/>
        <order-additional-attributes class="pt-8 text-body-2" :attributes="order.additional_attributes"/>
      </v-col>
      <v-col cols="12" md="4" >
        <order-source :source="order.source" />
        <order-customer :customer="order.customer" />
        <order-timeline :events="order.events" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import OrderHeader from '@/components/order/OrderHeader.vue'
import OrderFulfillments from '@/components/order/OrderFulfillments.vue'
import OrderAdjustments from '@/components/order/OrderAdjustments.vue'
import OrderPayments from '@/components/order/OrderPayments.vue'
import OrderNotes from '@/components/order/OrderNotes.vue'
import OrderDocuments from '@/components/order/OrderDocuments.vue'
import OrderAdditionalAttributes from '@/components/order/OrderAdditionalAttributes.vue'
import OrderSource from '@/components/order/OrderSource.vue'
import OrderCustomer from '@/components/order/OrderCustomer.vue'
import OrderTimeline from '@/components/order/OrderTimeline.vue'

export default {
  name: "Order",
  props: {
    order: Object,
  },
  components: {
    OrderHeader,
    OrderFulfillments,
    OrderAdjustments,
    OrderPayments,
    OrderNotes,
    OrderDocuments,
    OrderAdditionalAttributes,
    OrderSource,
    OrderCustomer,
    OrderTimeline,
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style scoped>
</style>
