<template>
  <div v-show="hasEvents">
    <div class="text-h6 py-3 themeText3--text"><v-icon color="themeText3">mdi-calendar-clock</v-icon> Events</div>
    <v-timeline dense align-top :dark="themeIsDark">
      <order-timeline-event v-for="(event,i) in sortedEvents" :key="i" :event="event"/>
    </v-timeline>
  </div>
</template>
<script>
import {mapGetters} from "vuex"
import {THEME_NS} from "@/store/modules/theme/constants"
import OrderTimelineEvent from '@/components/order/OrderTimelineEvent.vue'

export default {
  name: "OrderTimeline",
  props: {
    events: Array,
  },
  components: {
    OrderTimelineEvent,
  },
  computed: {
    ...mapGetters(THEME_NS, ['themeIsDark']),
    hasEvents() {
      return (this.events && this.events.length > 0)
    },
    sortedEvents() {
      let sortedEvents = []

      if ( this.events ) {
        sortedEvents = this.events.sort(function(e1,e2){
          let result = 0

          if ( e1.date_time < e2.date_time ) {
            // Keep order if date 1 < date 2
            result = -1
          } else if ( e1.date_time > e2.date_time ) {
            // Invert order if date 1 > date 2
            result = 1
          } else {
            // Manage order if date 1 = date 2
            if ( e1.type_code == "CANCELLATION_ADDED" && e2.type_code == "DELIVERY_CONTENT_UPDATED" ) {
              result = -1
            } else if ( e1.type_code == "CANCELLATION_ADDED" && e2.type_code == "DELIVERY_STATUS_UPDATED" ) {
              result = -1
            } else if ( e1.type_code == "CANCELLATION_ADDED" && e2.type_code == "ORDER_STATUS_UPDATED" ) {
              result = -1
            } else if ( e1.type_code == "DELIVERY_STATUS_UPDATED" && e2.type_code == "ORDER_STATUS_UPDATED" ) {
              result = -1
            } else {
              result = 1
            }
          }

          return result
        })
      }

      // Add revision to events
      return this.addRevisionToEvents(sortedEvents)
    },
  },
  methods: {
    addRevisionToEvents(events) {
      let results = []
      let currentDateTime = ""
      let revision = -1

      for (var i = 0; i < events.length; i++) {
        let event = { ...events[i] }

        if ( event.date_time != currentDateTime ) {
          currentDateTime = event.date_time
          revision = revision + 1
        }

        event.revision = revision
        results.push(event)
      }

      return results
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
