<template>
  <v-chip small label :color="statusColor" text-color="white">
    <v-icon left small>{{statusIcon}}</v-icon>
    <span class="font-weight-bold">{{statusCode}}</span>
  </v-chip>
</template>
<script>
export default {
  name: "RequestStatus",
  props: {
    code: String,
  },
  computed: {
    statusCode() {
      let statusCode = this.code
      if (statusCode) {
        statusCode = statusCode.split('_').join(' ')
      }
      return statusCode
    },
    statusColor() {
      let statusCode = this.code
      let statusColor = ""

      switch (statusCode) {
        case "CREATED":
        case "CREATION_ERROR":
        case "VALIDATED":
        case "COMPLETED":
        case "CANCELLED":
          statusColor = "themeStatusRequest"
        break
        case "VALIDATION_ERROR":
          statusColor = "themeStatusRejected"
        break
        default:
        statusColor = "themeStatusRequest"
        break
      }

      return statusColor
    },
    statusIcon() {
      let statusCode = this.code
      let statusIcon = ""

      switch (statusCode) {
        case "CREATED":
        statusIcon = "mdi-checkbox-blank-circle-outline"
        break
        case "VALIDATED":
        statusIcon = "mdi-check-circle-outline"
        break
        case "CREATION_ERROR":
        case "VALIDATION_ERROR":
        statusIcon = "mdi-alert-circle-outline"
        break
        case "PROCESSING_ERROR":
        statusIcon = "mdi-alert-circle"
        break
        case "COMPLETED":
        statusIcon = "mdi-checkbox-blank-circle"
        break
        case "CANCELLED":
        statusIcon = "mdi-cancel"
        break

        default:
        statusIcon = "mdi-help-circle-outline"
        break
      }

      return statusIcon
    }
  },
}
</script>
<style lang="scss" scoped>
</style>
