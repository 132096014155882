<template>
  <v-list-item @click="displayMerchant()" :key="merchant.id" class="themeText1--text">
    <v-row no-gutters>
      <v-col cols="6">
        <div class="subtitle-2 text-no-wrap text-truncate">
          <v-icon small color="themeText2" class="mr-1">mdi-shopping-outline</v-icon>
          <span>{{merchant.id}}</span>
        </div>
      </v-col>
      <v-col cols="6" class="text-right">
        <div class="caption themeText2--text">{{merchant.country_code}}</div>
      </v-col>
    </v-row>
  </v-list-item>
</template>
<script>
import {mapActions} from "vuex"
import {MERCHANT_NS} from "@/store/modules/merchant/constants"

export default {
  name: "MerchantLine",
  props: {
    merchant: Object,
  },
  methods: {
    ...mapActions(MERCHANT_NS, [
      'selectMerchantToDisplay'
    ]),
    displayMerchant() {
      this.selectMerchantToDisplay(this.merchant)
      this.$router.push({ name: 'Merchant', params: { merchantId: this.merchant.id } })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
