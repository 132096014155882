import {httpClient,refreshToken} from "@/helpers/httpClient"
import {parameters} from "@/helpers/parameters"
import {merchants} from "@/helpers/merchants"

let getFedToken = async (code) => {
  return await refreshToken(code)
  .then((response)=> {
    return response
  })
  .catch((error)=> {
    console.log(error)
    throw error
  })
}

let getMerchants = (params) => {
  let response = []

  for (var i = 0; i < merchants.length; i++) {
    let merchant = merchants[i]

    if (params && params.env && merchant.envs.includes(params.env)) {
      if ( params.id && merchant.id.includes(params.id) ) {
        response.push(merchant)
      } else if ( params.country_code && merchant.country_code.includes(params.country_code) ) {
        response.push(merchant)
      } else if ( !params.id && !params.country_code ) {
        response.push(merchant)
      }
    }
  }

  return response
}

let getOrders = async (merchantId,params) => {
  return await httpClient.get(`${parameters.apiUrl}/orders`,{
    headers:{
      'x-merchant-id':merchantId
    },
    params: params
  })
  .then((response)=> {
    return response
  })
  .catch((error)=> {
    console.log(error)
    throw error
  })
}

let getOrderById = async (merchantId,orderId) => {
  return await httpClient.get(`${parameters.apiUrl}/orders/${orderId}`,{
    headers:{
      'x-merchant-id':merchantId
    }
  })
  .then((response)=> {
    return response
  })
  .catch((error)=> {
    console.log(error)
    throw error
  })
}

let getOrderByReference = async (merchantId,orderReference) => {
  return await httpClient.get(`${parameters.apiUrl}/orders_by_reference/${orderReference}`,{
    headers:{
      'x-merchant-id':merchantId
    }
  })
  .then((response)=> {
    return response
  })
  .catch((error)=> {
    console.log(error)
    throw error
  })
}

let getRequests = async (merchantId,params) => {
  return await httpClient.get(`${parameters.apiUrl}/change_requests`,{
    headers:{
      'x-merchant-id':merchantId
    },
    params: params
  })
  .then((response)=> {
    // console.log("getChangeRequests() : response.headers="+JSON.stringify(response.headers))
    return response
  })
  .catch((error)=> {
    console.log(error)
    throw error
  })
}

let getRequestById = async (merchantId,changeRequestId) => {
  return await httpClient.get(`${parameters.apiUrl}/change_requests/${changeRequestId}`,{
    headers:{
      'x-merchant-id':merchantId
    }
  })
  .then((response)=> {
    return response
  })
  .catch((error)=> {
    console.log(error)
    throw error
  })
}

export {
  getFedToken,
  getMerchants,
  getOrders,
  getOrderById,
  getOrderByReference,
  getRequests,
  getRequestById
}
