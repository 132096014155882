<template>
  <div>
    <div class="text-h6 py-3 themeText3--text">
      <v-icon color="themeText3">mdi-arrow-left-bold-outline</v-icon>
      <span> Return</span>
    </div>
    <v-card class="themeCard">
      <v-card-text class="themeText1--text">
        <div class="text-body-2 mt-3 font-weight-bold">
          <v-icon color="themeText1">mdi-package-variant-closed</v-icon>
          <span> Items</span>
        </div>
        <request-line-item v-for="(item,index) in items" :key="index" :item="item" />
        <div class="text-body-2" v-show="hasAdditionalAttributes">
          <request-additional-attributes :attributes="additionalAttributes" />
        </div>
      </v-card-text>
    </v-card>
</div>
</template>
<script>
import Copyable from '@/components/common/Copyable.vue'
import RequestLineItem from '@/components/request/RequestLineItem.vue'
import RequestAdditionalAttributes from '@/components/request/RequestAdditionalAttributes.vue'

export default {
  name: "RequestReturn",
  props: {
    returnRequest: Object,
  },
  components: {
    Copyable,
    RequestLineItem,
    RequestAdditionalAttributes,
  },
  computed: {
    items() {
      return (this.returnRequest && this.returnRequest.line_items) ? this.returnRequest.line_items : []
    },
    hasAdditionalAttributes() {
      return (this.returnRequest && this.returnRequest.additional_attributes) ? true : false
    },
    additionalAttributes() {
      let attributes = {}

      if ( this.returnRequest && this.returnRequest.additional_attributes ) {
        attributes = this.returnRequest.additional_attributes
      }

      return attributes
    }
  },
}
</script>
<style scoped>
</style>
