<template>
  <div>
    <v-row>
      <v-col cols="12" sm="10" offset-sm="1" md="8" offset-md="2" class="pb-6">
        <div class="title font-weight-thin text-center themeText1--text">Change Requests</div>
        <v-text-field dense solo clearable hide-details
        v-model="searchInput"
        prepend-inner-icon="mdi-magnify"
        :append-icon="searchIcon"
        @click:append="toggleSearchMode()"
        @keyup.enter="search()"
        :dark="themeIsDark"
        color="primary"
        background-color="themeSearchBar"
        name="name" :placeholder="searchPlaceholder" id="id"/>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex"
import {REQUEST_NS} from "@/store/modules/request/constants"
import {THEME_NS} from "@/store/modules/theme/constants"

export default {
  name: "ChangeRequestsHeader",
  components: {
  },
  data: () => ({
    searchById: false,
    searchInput: ""
  }),
  created() {
    if (this.searchQueryParams) {
      if ( this.searchQueryParams['id'] ) {
        this.searchById = true
        this.searchInput = this.searchQueryParams['id']
      } else if ( this.searchQueryParams['order_reference'] ) {
        this.searchById = false
        this.searchInput += this.searchQueryParams['order_reference']
      }
    }
  },
  computed: {
    ...mapGetters(REQUEST_NS, [
      'searchQueryParams'
    ]),
    ...mapGetters(THEME_NS, ['themeIsDark']),
    searchIcon() {
      return ( this.searchById ) ? "mdi-toggle-switch-off-outline" : "mdi-toggle-switch-outline"
    },
    searchPlaceholder() {
      return ( this.searchById ) ? "Search requests by id" : "Search requests by order reference"
    },
  },
  methods: {
    ...mapActions(REQUEST_NS, [
      'fetchRequests',
      'addSearchQueryParam',
      'removeSearchQueryParam',
      'updateRequestsURL',
    ]),
    toggleSearchMode() {
      this.searchById = !this.searchById
    },
    search() {
      let merchantId = this.$route.params.merchantId
      let params = {}

      if ( this.searchInput ) {
        if ( this.searchById ) {
          this.removeSearchQueryParam('order_reference')
          this.addSearchQueryParam({'key':'id','value':this.searchInput})
        } else {
          this.removeSearchQueryParam('id')
          this.addSearchQueryParam({'key':'order_reference','value':this.searchInput})
        }
      } else {
        this.removeSearchQueryParam('id')
        this.removeSearchQueryParam('order_reference')
      }

      this.updateRequestsURL({'vue':this})
      this.fetchRequests({merchantId, 'params':this.searchQueryParams})
    }
  },
}
</script>
<style lang="scss" scoped>
</style>
