import { render, staticRenderFns } from "./OrderTimeline.vue?vue&type=template&id=3355f25c&scoped=true&"
import script from "./OrderTimeline.vue?vue&type=script&lang=js&"
export * from "./OrderTimeline.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3355f25c",
  null
  
)

export default component.exports