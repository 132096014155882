<template>
  <v-dialog v-model="dialog" transition="dialog-bottom-transition" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="themeText2" class="text-none" small text v-bind="attrs" v-on="on"><v-icon small class="pr-2">mdi-file-document-edit-outline</v-icon>Edit</v-btn>
    </template>
    <v-card>
      <v-toolbar flat dense color="themePanelHeader">
        <span class="text-h6 themeText1--text">{{title}}</span>
        <v-spacer></v-spacer>
        <v-btn text icon @click="dialog = false"><v-icon small color="themeText1">mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text class="themePanelContent">
        <v-textarea solo flat
         autofocus
         auto-grow
         no-resize
         hide-details
         v-model="input"
         class="code"
         :dark="themeIsDark"
         background-color="themePanelContent"
         v-on:change="onInputChanged()" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import {mapGetters} from "vuex"
import {THEME_NS} from "@/store/modules/theme/constants"

export default {
  name: "EditDialogJson",
  data: () => ({
    dialog: false,
    input: "",
  }),
  props: {
    title: String,
    value: String,
  },
  created() {
    this.value ? this.input = this.value : this.input = ""
  },
  computed: {
    ...mapGetters(THEME_NS, ['themeIsDark']),
  },
  methods: {
    onInputChanged() {
      try {
        let obj = JSON.parse(this.input)
        this.input = JSON.stringify(obj, null, 2)

        this.$emit("change",this.input)
      } catch {}
    }
  }
}
</script>
<style scoped>
.code {
  font-family: monospace;
  font-size: 14px;
}
</style>
