<template>
  <div>
    <div class="text-h6 py-3 themeText3--text mt-4">
      <v-icon color="themeText3">mdi-comment-processing-outline</v-icon>
      <span> Notes</span>
    </div>
    <v-expansion-panels multiple>
      <v-expansion-panel v-for="note in request.notes" :key="note.id">
        <v-expansion-panel-header class="themePanelHeader">
          <v-row no-gutters align="center" class="text-body-2">
            <v-col cols="6">
              <div class="font-weight-bold text-truncate">
                <copyable :text="note.id" />
              </div>
              <div class="caption themeText1--text">
                <div>{{truncateContent(note.content)}}</div>
              </div>
            </v-col>
            <v-col cols="6" class="text-right">
              <div class="caption">
                <span class="themeText1--text">{{note.author.type_code}}</span>
                <span class="themeText2--text"> {{note.author.reference}}</span>
              </div>
              <div class="caption themeText2--text">{{note.creation_date_time}}</div>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="themePanelContent text-body-2">
          <v-container>
            <div class="themeText1--text">{{note.content}}</div>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import Copyable from '@/components/common/Copyable.vue'

export default {
  name: "RequestNotes",
  props: {
    request: Object,
  },
  components: {
    Copyable,
  },
  methods: {
    truncateContent(content) {
      return (content.length > 50) ? content.substring(0, 50) + '...' : content
    }
  }
}
</script>
<style scoped>
</style>
