<template>
  <div class="my-2">
    <div class="caption text-truncate">
      <v-icon :color="itemTypeColor" class="mr-2">{{itemTypeIcon}}</v-icon>
      <copyable :text="itemReference" />
    </div>
    <v-row no-gutters>
      <v-col cols="6">
        <div class="text-truncate">{{itemName}}</div>
      </v-col>
      <v-col cols="6">
        <div class="text-right">x {{itemQty}}</div>
      </v-col>
    </v-row>
    <div class="caption themeText2--text text-truncate">SKU : {{itemSku}}</div>
    <div v-show="hasUniqueIdentifiers">
      <div v-for="(itemUniqueIdentifier,index) in itemUniqueIdentifiers" :key="index">
        <span class="themeText2--text">{{itemUniqueIdentifier.type_code}} </span>
        <copyable :text="itemUniqueIdentifier.value" />
      </div>
    </div>
    <v-sheet v-show="cancellation" color="themePanelHeader" rounded class="pa-2">
      <div class="caption themeText2--text text-truncate">
        <span>Cancellation </span>
        <span>( <copyable :text="item.change_request_id" /> )</span>
      </div>
      <div class="caption themeText2--text text-truncate">{{item.reason_code}}</div>
      <div class="caption themeText2--text text-truncate">{{item.message}}</div>
    </v-sheet>
  </div>
</template>
<script>
import Copyable from '@/components/common/Copyable.vue'

export default {
  name: "OrderDeliveryLineItem",
  props: {
    cancellation: Boolean,
    fulfillment: Object,
    item: Object,
  },
  components: {
    Copyable,
  },
  computed: {
    itemDetails() {
      let itemDetails = {}
      let cancellationsDetails = {}

      if ( this.cancellation == false && this.fulfillment && this.fulfillment.line_items ) {
        itemDetails = this.fulfillment.line_items.find(element => element.reference == this.item.reference)
      } else if ( this.cancellation == true && this.fulfillment && this.fulfillment.cancellations ) {
        cancellationsDetails = this.fulfillment.cancellations.find(element => element.change_request_id == this.item.change_request_id )

        if ( cancellationsDetails && cancellationsDetails.line_items ) {
          itemDetails = cancellationsDetails.line_items.find(element => element.reference == this.item.line_item.reference)
        }
      }

      return itemDetails
    },
    itemTypeIcon() {
      let itemTypeCode = ""
      let icon = ""

      if (this.itemDetails) {
        itemTypeCode = this.itemDetails.type_code
      }

      switch (itemTypeCode) {
        case "PRODUCT":
        icon = "mdi-package-variant-closed"
        break
        case "SHIPPING_FEES":
        icon = "mdi-truck-fast"
        break
        default:
        icon = "mdi-help-circle-outline"
        break
      }

      return icon
    },
    itemTypeColor() {
      return (this.cancellation) ? 'themeText2' : 'primary'
    },
    itemReference() {
      return (this.itemDetails) ? this.itemDetails.reference : ''
    },
    itemName() {
      return (this.itemDetails) ? this.itemDetails.name : ''
    },
    itemSku() {
      return (this.itemDetails) ? this.itemDetails.sku : ''
    },
    itemQty() {
      let quantity = ''

      if ( this.cancellation == false && this.item  ) {
        quantity = this.item.quantity
      } else if ( this.cancellation == true && this.item.line_item ) {
        quantity = this.item.line_item.quantity
      }

      return quantity
    },
    hasUniqueIdentifiers() {
      return (this.item && this.item.unique_identifiers && this.item.unique_identifiers.length > 0)
    },
    itemUniqueIdentifiers() {
      let uniqueIdentifiers = []

      if (this.hasUniqueIdentifiers) {
        uniqueIdentifiers = this.item.unique_identifiers
      }

      return uniqueIdentifiers
    },
  }
}
</script>
<style lang="scss" scoped>
</style>
