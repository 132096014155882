<template lang="html">
  <div>
    <v-container fluid>
      <v-row>
      <v-col>
        <app-logo />
      </v-col>
      </v-row>
      <v-row align="center" justify="center" class="text-center grey--text">
        <v-col>
          <div class="display-1 font-weight-light ma-5 grey--text">Oops !</div>
          <div class="ma-5">
            <v-icon size="50" color="yellow lighten-2">mdi-pac-man</v-icon>
            <v-icon size="50" color="yellow lighten-2">mdi-circle-small</v-icon>
            <v-icon size="50" color="yellow lighten-2">mdi-circle-small</v-icon>
            <v-icon size="50" color="grey lighten-2">mdi-file-alert-outline</v-icon>
            <v-icon size="50" color="yellow lighten-2">mdi-circle-small</v-icon>
            <v-icon size="50" color="yellow lighten-2">mdi-circle-small</v-icon>
            <v-icon size="50" color="red lighten-3">mdi-ghost</v-icon>
          </div>
          <div>Something seems to be broken or we can’t find what you're looking for.</div>
          <div class="ma-5">
            <span>Go back to your </span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span class="clickable blue--text font-weight-bold" @click="goBackToPreviousPage()" v-bind="attrs" v-on="on">previous page</span>
              </template>
              <span><v-icon left color="white">mdi-arrow-left-circle</v-icon>Go back to your previous page</span>
            </v-tooltip>
            <span> or to the </span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span class="clickable blue--text font-weight-bold" @click="goBackToHomePage()" v-bind="attrs" v-on="on">homepage</span>
              </template>
              <span><v-icon left color="white">mdi-home-circle</v-icon>Go back to the dashboard homepage</span>
            </v-tooltip>
            <span> or </span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span class="clickable blue--text font-weight-bold" @click="contactUs()" v-bind="attrs" v-on="on">contact us</span>
              </template>
              <span><v-icon left color="white">mdi-email-edit</v-icon>Send an email to the OneOM team</span>
            </v-tooltip>
            <span> for support.</span>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AppLogo from '@/components/app/AppLogo.vue'

export default {
  components: {
    AppLogo,
  },
  methods: {
    goBackToPreviousPage() {
      history.go(-2)
    },
    goBackToHomePage() {
      this.$router.push({ name: 'Home', params: {} })
    },
    contactUs() {
      window.location.href = "mailto:oneom@decathlon.net"
    }
  }
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>
