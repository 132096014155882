<template>
  <v-expansion-panel-content class="themePanelContent">
    <div v-show="hasLineItems">
      <order-line-item v-for="item in cancellationLineItems" :key="item.reference" :item="item" :cancellation="true" :currencyCode="currencyCode" />
    </div>
    <div class="text-body-2">
      <order-additional-attributes :attributes="additionalAttributes" />
    </div>
  </v-expansion-panel-content>
</template>
<script>
import Copyable from '@/components/common/Copyable.vue'
import OrderLineItem from '@/components/order/OrderLineItem.vue'
import OrderAdditionalAttributes from '@/components/order/OrderAdditionalAttributes.vue'

export default {
  name: "OrderCancellationContent",
  props: {
    currencyCode: String,
    cancellation: Object,
  },
  components: {
    Copyable,
    OrderLineItem,
    OrderAdditionalAttributes,
  },
  computed: {
    cancellationLineItems() {
      return this.cancellation ? this.cancellation.line_items : []
    },
    hasLineItems() {
      return (this.cancellation && this.cancellation.line_items && this.cancellation.line_items.length > 0) ? true : false
    },
    additionalAttributes() {
      return this.cancellation ? this.cancellation.additional_attributes : {}
    },
  }
}
</script>
<style scoped>
</style>
