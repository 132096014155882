const getters = {
  orders(state) {
    return state.orders
  },
  totalNumber(state) {
    return state.totalNumber
  },
  showingNumber(state) {
    return state.showingNumber
  },
  order(state) {
    return state.order
  },
  searchQueryParams(state){
    return state.searchQueryParams
  },
  searchQueryParamsURL(state){
    return state.searchQueryParamsURL
  },
  orderJson(state) {
    return JSON.stringify(state.order, null, 4)
  },
  errorMessage(state) {
    return state.errorMessage
  },
  loadingFlag(state) {
    return state.loadingFlag
  },
};

export default getters;
