<template>
  <v-container class="pt-0">
    <v-card class="body-2 themeCard">
      <template v-for="(env,index) in envs">
        <v-list-item @click="selectEnvToDisplay(env)" :key="index" class="subtitle-2">
          <div class="subtitle-2 text-no-wrap text-truncate">
            <v-icon small color="themeText2" class="mr-1">mdi-earth</v-icon>
            <span class="themeText1--text">{{env}}</span>
          </div>
        </v-list-item>
        <v-divider v-if="!isLastEnv(index,envs)"></v-divider>
      </template>
    </v-card>
  </v-container>
</template>
<script>
import {mapActions, mapGetters} from "vuex"
import {ENV_NS} from "@/store/modules/env/constants"

export default {
  name: "EnvList",
  computed: {
    ...mapGetters(ENV_NS, [
      'env',
      'envs',
    ])
  },
  methods: {
    ...mapActions(ENV_NS, [
      'selectEnv'
    ]),
    selectEnvToDisplay(env) {
      this.selectEnv(env)
      this.$router.push({ name: 'Merchants', params: { env: this.env } })
    },
    isLastEnv(index,envs) {
      return (index == envs.length - 1)
    }
  }
}
</script>
<style scoped>
</style>
