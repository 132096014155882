<template>
<div v-show="!isEmpty">
  <div class="themeText2--text font-weight-bold">Additional attributes</div>
  <div v-for="(value,key) in attributes" :key="key" class="text-truncate">
    <span class="themeText2--text">{{key}} </span>
    <copyable :text="value" />
  </div>
</div>
</template>
<script>
import Copyable from '@/components/common/Copyable.vue'

export default {
  name: "OrderAdditionalAttributes",
  props: {
    attributes: Object,
  },
  components: {
    Copyable,
  },
  computed: {
    isEmpty() {
      return (this.attributes === undefined || this.attributes && Object.keys(this.attributes).length === 0)
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
