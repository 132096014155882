import colors from 'vuetify/lib/util/colors'

export default {
  primary: colors.blue.base,
  secondary: colors.red.base,
  themeAppBar: colors.grey.lighten4,
  themeBackground: colors.grey.lighten5,
  themeCard: colors.shades.white,
  themeChip1: colors.blue.base,
  themeChip2: colors.grey.lighten1,
  themeChip3: colors.grey.lighten4,
  themeChip4: colors.grey.lighten4,
  themeChip5: colors.green.base,
  themeDivider: colors.grey.lighten3,
  themePanelHeader: colors.grey.lighten5,
  themePanelContent: colors.shades.white,
  themePanelContent2: colors.grey.lighten4,
  themeSearchBar: colors.shades.white,
  themeStatusOrder: colors.blue.lighten1,
  themeStatusDelivery: colors.amber.lighten1,
  themeStatusTransaction: colors.green.lighten1,
  themeStatusRequest: colors.blue.lighten1,
  themeStatusApproved: colors.green.lighten1,
  themeStatusRejected: colors.red.lighten1,
  themeTypeOrder: colors.grey.lighten4,
  themeText1: colors.shades.black,
  themeText2: colors.grey,
  themeText3: colors.grey.darken1,
}
