<template>
  <v-timeline-item
  :icon="iconFromEventTypeCode" fill-dot icon-color="white"
  :color="eventColor">
  <v-card flat class="themeCard">
    <v-card-text>
      <div class="themeText3--text font-weight-bold">{{eventTypeCode}}</div>
      <div class="caption themeText2--text">{{event.date_time}}</div>
      <div v-show="isOrderStatusUpdatedEvent" class="caption">
        <order-status :code="event.payload ? event.payload.order_status_code : ''" />
      </div>
      <div v-show="isDeliveryStatusUpdatedEvent" class="caption">
        <order-delivery-status :code="event.payload ? event.payload.delivery_status_code : ''" />
      </div>
      <div v-show="isDeliveryUpdatedEvent" class="caption">
        <div class="caption text-truncate">
          <span class="themeText2--text">Fulfillment : </span>
          <copyable :text="event.payload ? event.payload.fulfillment_reference : ''"/>
        </div>
        <div class="caption text-truncate">
          <span class="themeText2--text">Delivery : </span>
          <copyable :text="event.payload ? event.payload.delivery_reference : ''"/>
        </div>
        <div v-show="isDeliverySplitEvent" class="caption">
          <span class="themeText2--text">Split deliveries : </span>
          <div v-for="(splitDeliveryReference,i) in splitDeliveryReferences" :key="i">
            <v-icon small color="themeText2">mdi-arrow-bottom-right-thick</v-icon>
            <copyable :text="splitDeliveryReference"/>
          </div>
        </div>
        <div v-show="isDeliveryEstimatedDateEvent" class="caption">
          <div class="themeText2--text">Estimated delivery (before)</div>
          <div class="themeText1--text">≥ {{beforeEstimatedDateTimeStart}}</div>
          <div class="themeText1--text">≤ {{beforeEstimatedDateTimeEnd}}</div>
          <div class="themeText2--text">Estimated delivery (after)</div>
          <div class="themeText1--text">≥ {{afterEstimatedDateTimeStart}}</div>
          <div class="themeText1--text">≤ {{afterEstimatedDateTimeEnd}}</div>
        </div>
      </div>
      <div v-show="isTransactionStatusUpdatedEvent" class="caption">
        <order-transaction-status :code="event.payload ? event.payload.transaction_status_code : ''" />
      </div>
      <div v-show="isTransactionUpdatedEvent" class="caption">
        <div class="caption text-truncate">
          <span class="themeText2--text">Transaction : </span>
          <copyable :text="event.payload ? event.payload.transaction_reference : ''"/>
        </div>
      </div>
      <div v-show="isNoteAddedEvent" class="caption">
        <span class="themeText2--text">Note id : </span>
        <copyable :text="event.payload ? event.payload.note_id : ''"/>
      </div>
      <div v-show="isCancellationAddedEvent" class="caption">
        <div class="caption text-truncate">
          <span class="themeText2--text">Fulfillment : </span>
          <copyable :text="event.payload ? event.payload.fulfillment_reference : ''"/>
        </div>
        <div class="caption text-truncate">
          <span class="themeText2--text">Change request : </span>
          <copyable :text="event.payload ? event.payload.change_request_id : ''"/>
        </div>
      </div>
      <div v-show="isReturnAddedEvent" class="caption">
        <div class="caption text-truncate">
          <span class="themeText2--text">Fulfillment : </span>
          <copyable :text="event.payload ? event.payload.fulfillment_reference : ''"/>
        </div>
        <div class="caption text-truncate">
          <span class="themeText2--text">Change request : </span>
          <copyable :text="event.payload ? event.payload.change_request_id : ''"/>
        </div>
      </div>
      <div v-show="isAdditionalAttributesUpdatedEvent" class="caption">
        <div class="caption text-truncate">
          <span class="themeText2--text">Path : </span>
          <copyable :text="event.payload ? event.payload.json_path :''"/>
        </div>
      </div>
      <div v-show="isDocumentAddedEvent" class="caption">
        <div>
          <order-document-type :type="documentTypeCode"/>
        </div>
        <div class="caption text-truncate">
          <span class="themeText2--text">Source : </span>
          <copyable :text="event.payload ? event.payload.document_source : ''"/>
        </div>
        <div class="caption text-truncate">
          <span class="themeText2--text">Reference : </span>
          <copyable :text="event.payload ? event.payload.document_reference : ''"/>
        </div>
        <div class="caption text-truncate">
          <span class="themeText2--text">Path : </span>
          <copyable :text="event.payload ? event.payload.json_path :''"/>
        </div>
      </div>
      <div v-show="isAdjustmentAddedEvent" class="caption">
        <div class="caption text-truncate">
          <span class="themeText2--text">Fulfillment : </span>
          <copyable :text="event.payload ? event.payload.fulfillment_reference : ''"/>
        </div>
        <div class="caption text-truncate">
          <span class="themeText2--text">Adjustment : </span>
          <copyable :text="event.payload ? event.payload.adjustment_reference : ''"/>
        </div>
      </div>
    </v-card-text>
  </v-card>
  <div class="caption themeText2--text text-right">
    <span>Revision #<strong>{{event.revision}}</strong></span>
  </div>
</v-timeline-item>
</template>
<script>
import Copyable from '@/components/common/Copyable.vue'
import OrderStatus from '@/components/order/OrderStatus.vue'
import OrderDeliveryStatus from '@/components/order/OrderDeliveryStatus.vue'
import OrderDocumentType from '@/components/order/OrderDocumentType.vue'
import OrderTransactionStatus from '@/components/order/OrderTransactionStatus.vue'

export default {
  name: "OrderTimelineEvent",
  props: {
    event: Object,
  },
  components: {
    Copyable,
    OrderStatus,
    OrderDeliveryStatus,
    OrderDocumentType,
    OrderTransactionStatus,
  },
  computed: {
    eventTypeCode() {
      let eventTypeCode = (this.event) ? this.event.type_code : ''
      if (eventTypeCode) {
        eventTypeCode = eventTypeCode.split('_').join(' ')
      }
      return eventTypeCode
    },
    splitDeliveryReferences() {
      let references = []

      if (this.isDeliverySplitEvent && this.event.payload.split_delivery_references) {
        references = this.event.payload.split_delivery_references
      }

      return references
    },
    isOrderStatusUpdatedEvent() {
      return (this.event && this.event.type_code == "ORDER_STATUS_UPDATED") ? true : false
    },
    isDeliveryStatusUpdatedEvent() {
      return (this.event && this.event.type_code == "DELIVERY_STATUS_UPDATED") ? true : false
    },
    isDeliveryUpdatedEvent() {
      return (this.event && this.event.type_code == "DELIVERY_STATUS_UPDATED" || this.event.type_code=="DELIVERY_CONTENT_UPDATED" || this.event.type_code=="DELIVERY_ESTIMATED_DATE_UPDATED") ? true : false
    },
    isDeliverySplitEvent() {
      return (this.isDeliveryUpdatedEvent && this.event.payload.delivery_status_code == "SPLIT") ? true : false
    },
    isDeliveryEstimatedDateEvent() {
      return (this.event && this.event.type_code == "DELIVERY_ESTIMATED_DATE_UPDATED") ? true : false
    },
    isTransactionStatusUpdatedEvent() {
      return (this.event && this.event.type_code == "TRANSACTION_STATUS_UPDATED") ? true : false
    },
    isTransactionUpdatedEvent() {
      return (this.event && this.event.type_code == "TRANSACTION_STATUS_UPDATED" || this.event.type_code=="TRANSACTION_CONTENT_UPDATED") ? true : false
    },
    isNoteAddedEvent() {
      return (this.event && this.event.type_code == "NOTE_ADDED") ? true : false
    },
    isCancellationAddedEvent() {
      return (this.event && this.event.type_code == "CANCELLATION_ADDED") ? true : false
    },
    isReturnAddedEvent() {
      return (this.event && this.event.type_code == "RETURN_ADDED") ? true : false
    },
    isDocumentAddedEvent() {
      return (this.event && this.event.type_code == "DOCUMENT_ADDED") ? true : false
    },
    isAdjustmentAddedEvent() {
      return (this.event && this.event.type_code == "ADJUSTMENT_ADDED") ? true : false
    },
    isAdditionalAttributesUpdatedEvent() {
      return (this.event && this.event.type_code == "ADDITIONAL_ATTRIBUTES_UPDATED") ? true : false
    },
    documentTypeCode() {
      return (this.event && this.event.payload ? this.event.payload.document_type_code : "")
    },
    beforeEstimatedDateTimeStart() {
      return (this.event && this.event.payload && this.event.payload.before ? this.event.payload.before.estimated_date_time_start : "")
    },
    beforeEstimatedDateTimeEnd() {
      return (this.event && this.event.payload && this.event.payload.before ? this.event.payload.before.estimated_date_time_end : "")
    },
    afterEstimatedDateTimeStart() {
      return (this.event && this.event.payload && this.event.payload.after ? this.event.payload.after.estimated_date_time_start : "")
    },
    afterEstimatedDateTimeEnd() {
      return (this.event && this.event.payload && this.event.payload.after ? this.event.payload.after.estimated_date_time_end : "")
    },
    iconFromEventTypeCode() {
      let icon = "mdi-calendar-clock"
      let code = (this.event) ? this.event.type_code : ''

      switch (code) {
        case "ORDER_STATUS_UPDATED":
        icon = "mdi-text-box-outline"
        break;
        case "ADDITIONAL_ATTRIBUTES_UPDATED":
        icon = "mdi-card-text-outline"
        break;
        case "DELIVERY_STATUS_UPDATED":
        case "DELIVERY_CONTENT_UPDATED":
        case "DELIVERY_ESTIMATED_DATE_UPDATED":
        case "CANCELLATION_ADDED":
        case "RETURN_ADDED":
        icon = "mdi-dolly"
        break;
        case "TRANSACTION_STATUS_UPDATED":
        case "TRANSACTION_CONTENT_UPDATED":
        icon = "mdi-credit-card-outline"
        break;
        case "DOCUMENT_ADDED":
        icon = "mdi-file-document-outline"
        break;
        case "NOTE_ADDED":
        icon = "mdi-comment-processing-outline"
        break;
        case "ADJUSTMENT_ADDED":
        icon = "mdi-sale"
        break;
        default:
        break;
      }

      return icon
    },
    eventColor() {
      let eventColor = ""
      let code = (this.event) ? this.event.type_code : ''

      switch (code) {
        case "ORDER_STATUS_UPDATED":
        case "NOTE_ADDED":
        case "ADDITIONAL_ATTRIBUTES_UPDATED":
        case "DOCUMENT_ADDED":
        case "ADJUSTMENT_ADDED":
        eventColor = "themeStatusOrder"
        break;
        case "DELIVERY_CONTENT_UPDATED":
        case "DELIVERY_STATUS_UPDATED":
        case "DELIVERY_ESTIMATED_DATE_UPDATED":
        case "CANCELLATION_ADDED":
        case "RETURN_ADDED":
        eventColor = "themeStatusDelivery"
        break;
        case "TRANSACTION_CONTENT_UPDATED":
        case "TRANSACTION_STATUS_UPDATED":
        eventColor = "themeStatusTransaction"
        break;
        default:
        break;
      }

      return eventColor
    },
  }
}
</script>
<style lang="scss" scoped>
</style>
