<template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="themeText2" class="text-none" v-bind="attrs" v-on="on" small text>
          <v-icon small class="pr-2">mdi-chevron-down</v-icon>
          More
        </v-btn>
      </template>
      <v-list class="themeCard">
        <v-list-item @click="goToChangeRequests()">
          <v-icon small class="pr-2" color="themeText3">mdi-square-edit-outline</v-icon>
          <v-list-item-title class="body-2 themeText1--text">
            <span>Change requests</span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="downloadOrder()">
          <v-icon small class="pr-2" color="themeText3">mdi-arrow-down-bold</v-icon>
          <v-list-item-title class="body-2 themeText1--text">
            <span>Download JSON</span>
          </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item v-for="(action,index) in orderActions" :key="index" @click="executeAction(action)">
          <v-icon small class="pr-2" color="themeText3">{{action.icon}}</v-icon>
          <v-list-item-title class="body-2 themeText1--text">
            <span>{{action.name}}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import {mapGetters} from "vuex"
import {ENV_NS} from "@/store/modules/env/constants"
import {MERCHANT_NS} from "@/store/modules/merchant/constants"
import {ORDER_NS} from "@/store/modules/order/constants"

export default {
  name: "OrderToolbarMenu",
  computed: {
    ...mapGetters(MERCHANT_NS, [
      'merchant'
    ]),
    ...mapGetters(ORDER_NS, [
      'order'
    ]),
    ...mapGetters(ENV_NS, [
      'env'
    ]),
    orderActions() {
      let actions = []

      if ( this.merchant && this.merchant.options && this.merchant.options.order ) {
        if ( this.merchant.options.order.actions ) {
          actions = this.merchant.options.order.actions
        }
      }

      return actions
    },
  },
  methods: {
    executeAction(action) {

      if ( action.url ) {
        let url = ""
        url = action.url[this.env]
        url = url.replace('{{order.reference}}',this.order.reference)
        url = url.replace('{{order.source.merchant_id}}',this.order.source.merchant_id)
        url = url.replace('{{order.creation_date_time}}',this.order.creation_date_time)
        this.goToURL(url)
      }
    },
    goToURL(url){
      window.open(encodeURI(url))
    },
    goToChangeRequests() {
      let route = this.$router.resolve({
        'name':'ChangeRequests',
        'params':{
          'env':this.env,
          'merchantId':this.merchant.id
        },
        'query':{
          'order_reference': this.order.reference
        }
      })

      window.open(route.href, '_blank')
    },
    downloadOrder(){
      let text = JSON.stringify(this.order, null, 2)
      let filename = 'order-'+this.order.id+'.json'
      let element = document.createElement('a')
      element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
