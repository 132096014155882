const state = {
  orders: [],
  totalNumber: 0,
  showingNumber: 0,
  order: {},
  searchQueryParams: {},
  searchQueryParamsURL: "",
  errorMessage: "",
  loadingFlag: false
};

export default state;
