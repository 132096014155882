import { render, staticRenderFns } from "./OrderHeader.vue?vue&type=template&id=55a6d4ea&scoped=true&"
import script from "./OrderHeader.vue?vue&type=script&lang=js&"
export * from "./OrderHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55a6d4ea",
  null
  
)

export default component.exports