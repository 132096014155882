<template>
  <v-toolbar dense flat color="transparent">
    <v-btn color="themeText2" class="text-none" small text :to="{'name':'Merchant'}" :exact="true"><v-icon small class="pr-2">mdi-arrow-left</v-icon>Merchant</v-btn>
    <v-spacer></v-spacer>
    <v-btn color="themeText2" class="text-none" small text @click="copyUrlToClipboard()"><v-icon small class="pr-2">{{copyIcon}}</v-icon>Url</v-btn>
    <orders-export-data-dialog />
  </v-toolbar>
</template>
<script>

import {mapGetters} from "vuex"
import {ORDER_NS} from "@/store/modules/order/constants"
import OrdersExportDataDialog from '@/components/orders/OrdersExportDataDialog.vue'

export default {
  name: "OrdersToolbar",
  data: () => ({
    copyIcon: "mdi-clipboard-text-outline",
    copyDefaultIcon: "mdi-clipboard-text-outline",
    copiedIcon: "mdi-clipboard-check-outline",
    exportIcon: "mdi-comma",
    exportDefaultIcon: "mdi-comma",
    downloadIcon: "mdi-download",
  }),
  components: {
    OrdersExportDataDialog,
  },
  computed: {
    ...mapGetters(ORDER_NS, [
      'orders',
    ]),
  },
  methods: {
    copyUrlToClipboard() {
      this.copyIcon = this.copiedIcon
      navigator.clipboard.writeText(window.location.href)
      setTimeout(() => {  this.copyIcon = this.copyDefaultIcon }, 250)
    },
  }
}
</script>
<style lang="scss" scoped>
</style>
