<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="themePanelHeader">
      <v-row no-gutters align="center" class="text-body-2 themeText1--text">
        <v-col cols="6">
          <div class="font-weight-bold text-truncate">
            <copyable :text="noteId" />
          </div>
          <div class="caption">
            <div>{{noteTruncatedContent}}</div>
          </div>
        </v-col>
        <v-col cols="6" class="text-right">
          <div class="caption">
            <span>{{noteAuthorTypeCode}}</span>
            <span class="themeText2--text"> {{noteAuthorReference}}</span>
          </div>
          <div class="caption themeText2--text">{{noteCreationDateTime}}</div>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="themePanelContent themeText1--text text-body-2">
      <v-container>
        <div>{{noteContent}}</div>
      </v-container>
      <div v-show="hasAdditionalAttributes">
        <v-divider></v-divider>
        <div class="text-body-2 pa-4">
          <order-additional-attributes :attributes="noteAdditionalAttributes" />
        </div>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import Copyable from '@/components/common/Copyable.vue'
import OrderAdditionalAttributes from '@/components/order/OrderAdditionalAttributes.vue'

export default {
  name: "OrderNotes",
  props: {
    note: Object,
  },
  components: {
    Copyable,
    OrderAdditionalAttributes,
  },
  computed: {
    noteId() {
      return (this.note) ? this.note.id : ""
    },
    noteContent() {
      return (this.note) ? this.note.content : ""
    },
    noteTruncatedContent() {
      return (this.note && this.note.content && this.note.content.length > 50) ? this.note.content.substring(0, 50) + '...' : this.note.content
    },
    noteAuthorTypeCode() {
      return (this.note && this.note.author) ? this.note.author.type_code : ""
    },
    noteAuthorReference() {
      return (this.note && this.note.author) ? this.note.author.reference : ""
    },
    noteCreationDateTime() {
      return (this.note) ? this.note.creation_date_time : ""
    },
    hasAdditionalAttributes() {
      return (this.note && this.note.additional_attributes && Object.keys(this.note.additional_attributes).length > 0) ? true : false
    },
    noteAdditionalAttributes() {
      return this.note ? this.note.additional_attributes : {}
    },
  }
}
</script>
<style scoped>
</style>
