<template>
  <v-card outlined class="themePanelHeader mb-3">
    <v-card-text class="themeText1--text">
      <v-row no-gutters>
        <v-col cols="8">
          <div>
            <span class="themeText2--text">Fulfillment : </span>
            <copyable :text="item.fulfillment_reference" />
          </div>
          <div>
            <span class="themeText2--text">Delivery : </span>
            <copyable :text="item.delivery_reference" />
          </div>
          <div>
            <span class="themeText2--text">Item : </span>
            <copyable :text="item.reference" />
          </div>
          <div>
            <span class="themeText2--text">Quantity : </span>
            <span>x {{item.quantity}}</span>
          </div>
          <div class="mt-3">
            <span class="themeText2--text">Reason : </span>
            <span>{{item.reason_code}}</span>
          </div>
          <div>
            <span class="themeText2--text">Message : </span>
            <span>{{item.message}}</span>
          </div>
          <div class="mt-3">
            <span class="themeText2--text">Refund shipping fees : </span>
            <span>{{item.shipping_fees_refund_code}}</span>
          </div>
          <div>
            <span class="themeText2--text">Compensation type : </span>
            <span>{{item.compensation_type_code}}</span>
          </div>
          <div>
            <span class="themeText2--text">Linked order : </span>
            <span>{{item.linked_order_reference}}</span>
          </div>
        </v-col>
        <v-col cols="4" class="text-right">
          <request-return-status :code="item.status_code" />
        </v-col>
      </v-row>
    </v-card-text>
    <div v-show="hasRejected">
      <v-divider></v-divider>
      <request-rejected :rejected="item.rejected" />
    </div>
  </v-card>
</template>
<script>
import Copyable from '@/components/common/Copyable.vue'
import RequestReturnStatus from '@/components/request/RequestReturnStatus.vue'
import RequestRejected from '@/components/request/RequestRejected.vue'

export default {
  name: "RequestCancellations",
  props: {
    item: Object,
  },
  components: {
    Copyable,
    RequestReturnStatus,
    RequestRejected,
  },
  computed: {
    hasRejected() {
      return (this.item && this.item.rejected) ? true : false
    },
  },
}
</script>
<style scoped>
</style>
