<template>
  <v-expansion-panel-header color="themePanelHeader" class="px-4 py-2 themeText1--text">
    <v-row no-gutters align="center">
      <v-col>
        <div class="subtitle-2 text-truncate">
          <copyable :text="deliveryReference"/>
        </div>
        <div class="caption">
          <span class="font-weight-bold">{{deliveryTypeCode}} </span>
          <span class="themeText2--text">{{numberOfItems}} item{{pluralItems}}</span>
        </div>
        <div v-show="splitDeliveryReference" class="caption">
          <v-icon small color="themeText2">mdi-arrow-top-right-thick</v-icon>
          <span class="themeText2--text">{{splitDeliveryReference}}</span>
        </div>
        <div class="caption">
          <span>{{deliveryTotalAmount}} {{currencyCode}}</span>
        </div>
      </v-col>
      <v-col class="text-right">
        <order-delivery-status :code="deliveryStatusCode" />
        <div class="caption themeText2--text mt-1">{{deliveryUpdateDateTime}}</div>
      </v-col>
    </v-row>
  </v-expansion-panel-header>
</template>
<script>
import Copyable from '@/components/common/Copyable.vue'
import OrderDeliveryStatus from '@/components/order/OrderDeliveryStatus.vue'

export default {
  name: "OrderDeliveryHeader",
  props: {
    delivery: Object,
    currencyCode: String,
  },
  components: {
    Copyable,
    OrderDeliveryStatus,
  },
  computed: {
    deliveryReference() {
      return this.delivery ? this.delivery.reference : ""
    },
    deliveryTypeCode() {
      return this.delivery ? this.delivery.type_code : ""
    },
    deliveryStatusCode() {
      return this.delivery ? this.delivery.status_code : ""
    },
    splitDeliveryReference() {
      return this.delivery ? this.delivery.split_delivery_reference : ""
    },
    numberOfItems() {
      return (this.delivery && this.delivery.line_items) ? this.delivery.line_items.length : 0
    },
    pluralItems() {
      return ( this.numberOfItems > 1 ) ? "s" : ""
    },
    deliveryTotalAmount() {
      return this.delivery ? this.delivery.total_amount : ""
    },
    deliveryUpdateDateTime() {
      return this.delivery ? this.delivery.update_date_time : ""
    },
  }
}
</script>
<style lang="scss" scoped>
</style>
