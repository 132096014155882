<template>
  <v-chip small label :color="typeColor" text-color="themeText2">
    <span class="font-weight-bold">{{ typeCode }}</span>
  </v-chip>
</template>
<script>
export default {
  name: "OrderDocumentType",
  props: {
    type: String,
  },
  computed: {
    typeCode() {
      let typeCode = this.type;
      if (typeCode) {
        typeCode = typeCode.split("_").join(" ");
      }
      return typeCode;
    },
    typeColor() {
      let typeCode = this.type;
      let typeColor = "";

      switch (typeCode) {
        case "RECEIPT":
        case "INVOICE":
        case "CREDIT_NOTE":
          typeColor = "themeChip3"
        break
        default:
          typeColor = "themeChip3"
        break;
      }

      return typeColor;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
